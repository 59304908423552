import React from 'react';
import { connect } from 'react-redux';
import CONSTANTS from "globalConstants";
// Semantic UI
import { PeopleSelector } from "components/PeopleSelector";
import { WarningModal } from "components/WarningModal/WarningModal";
import { CASE_METADATA } from "redux/constants";
import SearchBox from "components/shared/SearchBox/SearchBox";
import ToggleSwitch from "components/shared/ToggleSwitch/ToggleSwitch";
import Dropdown from 'components/shared/Dropdown';
import { Button } from 'components/shared/Button';
import Checkbox from "components/shared/Checkbox";
import CaseSearchItem from "components/CaseSearchItem/CaseSearchItem";
import EmailInput from 'components/shared/EmailInput/EmailInput';
import { validateCaseSearch, copyObject, getPeopleApiDataByEmails, downloadFileFromUrl, triggerUsabillaSurvey, getImageNamePreAndPost, getPeopleApiDataByHrids, sortDataByKey } from "utils/helpers/helpers";
import LABELS from 'labels';
import { getFaceCardv1SlideData, faceCardv1SlidePeopleData } from 'redux/actions/faceCardGeneratorv1/faceCardGeneratorv1.actions';
import { selectors as caseConsumptionSelectors } from "redux/reducers/caseConsumption/caseConsumption_reducer";
import { selectors as caseMetadataSelectors } from "redux/reducers/caseMetadata/caseMetadata_reducer";
import { selectors as fcgSelectors } from "redux/reducers/faceCardGeneratorv1/faceCardGeneratorv1_reducer";
import { bindActionCreators } from "redux";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';

//////////// Importing Constants & Helper /////////

import FACE_CARD_CONFIG from "containers/FaceCardGeneratorv1/FaceCardv1.config";
import Image from "components/shared/Image";
import LoaderIcon from "assets/images/spinner/spinner-v3.gif";
import warningIcon from "assets/images/warning/warning.svg";
import closeFcg from "assets/images/fcg_close.svg";
import './FaceCardFormv1.scss';
import CONFIG from 'config';

const {
    METADATA: {
        PEOPLE_SELECTOR_PLACEHOLDER,
        FACECARD_PEOPLE_SELECTOR_EMAIL,
    },
    FACE_CARD_FORM: { NAME, TITLE, LOCAITON, WITH, SELECT_SIZE },
    FACE_CARD_SELECT_TEXTS: { BY_PEOPLE, BY_CASE_NO, BY_EMAIL_ID },
} = LABELS;
const {
    SEARCH_CASES,
    RESET_SEARCH_RESULTS,
} = CASE_METADATA;


class FaceCardFormv1 extends React.PureComponent {
    constructor(props) {
        super();

        this.state = {
            isNameChecked: true,
            isTitleChecked: false,
            isLocationChecked: false,
            peopleIds: [],
            peopleIdsWithData: [],
            fcgClose: true,
            selectedTemplateTitle: "",
            byType: BY_PEOPLE,
            isPreviewDisable: false,
            selectedCase: {},
            isCaseTeamEmpty: true,
            isResetModalOpen: false,
            isLoadingEmailPeople: false,
            prefillEmailIds: [],
            isResetButtonEnabled: false,
            isPreviewInProgress: false
        }
    }

    getHridArray = (obj) => {
        let temp = [];
        temp = obj?.map((obj) => obj?.hrid || obj?.id);
        return temp;
    }

    getEmailsArray = (obj) => {
        let temp = [];
        if (obj?.length)
            temp = obj?.map((obj) => obj?.workEmail?.toLowerCase());
        return temp;
    }

    componentDidUpdate(prevProps, prevState) {
        const { caseTeam } = this.props;
        const { byType, peopleIds } = this.state;
        const isLoading = this.props.getLoading || this.props.isLoadingCaseMembers || this.props.isLoadingEmailPeople;
        const shouldEnableReset = (
            (this.state.peopleIds.length > 0 ||
                Object.keys(this.state.selectedCase).length > 0 ||
                this.state.prefillEmailIds.length > 0) &&
            !this.state.isPreviewInProgress &&
            !isLoading
        );
        if (shouldEnableReset !== prevState.isResetButtonEnabled) {
            this.setState({ isResetButtonEnabled: shouldEnableReset });
        }
        if (caseTeam !== prevProps.caseTeam && byType === BY_CASE_NO) {
            const hrids = this.getHridArray(caseTeam);
            this.setState({ peopleIds: hrids });
        }
        if (peopleIds !== prevState.peopleIds) {
            if (byType === BY_CASE_NO && this.state.isCaseTeamEmpty === false) {
                if (peopleIds.length === 0) {
                    this.setState({ isCaseTeamEmpty: true })
                }
                else {
                    this.setState({ isCaseTeamEmpty: false })
                }
            }
            if (byType === BY_PEOPLE) {
                if (peopleIds.length === 0) {
                    this.setState({ isPreviewDisable: true })
                }
                else {
                    this.setState({ isPreviewDisable: false })
                }
            }
        }
    }

    handleChange = (hrids) => {
        const { handlePeopleSelectorUpdate, resetCallback } = this.props;
        const { byType, selectedCase, peopleIdsWithData } = this.state;
        try {
            let newPeopleIdsWithData = [];
            if (peopleIdsWithData.length) {
                newPeopleIdsWithData = sortDataByKey(peopleIdsWithData, hrids, 'id');
                newPeopleIdsWithData = newPeopleIdsWithData.filter((person) => hrids.includes(person.id));
            }

            this.setState({ peopleIds: hrids, peopleIdsWithData: newPeopleIdsWithData });
            if (!hrids.length && byType === BY_CASE_NO && Object.keys(selectedCase).length) {
                this.setState({ selectedCase: {} }, () => {
                    if (resetCallback)
                        resetCallback();
                });
            } else if (!hrids.length && resetCallback) {
                resetCallback();
            }
            handlePeopleSelectorUpdate(hrids);

        } catch (error) {
            console.log('Error in handle Change ', error)
        }
    }


    updatedFileSize = (fileSize) => {
        console.log('fileSize ', fileSize)
    }

    getDynamicPeopleSelectorClass = () => {
        try {
            const { peopleIds } = this.state;

            if (peopleIds?.length === 0) {
                return true;
            }
        } catch (error) {
            console.log('Error in getDynamicPeopleSelectorClass function ', error)
        }

        return false;
    }

    handleFaceCardBtnClick = (id) => {
        try {
            if (id === 'btn-preview') {
                this.handlePreview();
            } else if (id === 'btn-download') {
                this.handleDownload();
            }
        } catch (error) {
            console.log('Error in handleFaceCArdBtnClick ', error);
        }
    }
    handlePreview = async () => {
        const { handlePreviewShow } = this.props;
        const {
            peopleResultsActions: {
                getPeopleDataUsingIDs
            }
        } = this.props;
        const { byType, peopleIdsWithData } = this.state;
        handlePreviewShow(false);
        try {
            const { peopleIds } = this.state;
            const { getFaceCardv1SlideData, faceCardv1SlidePeopleData, isNameChecked, isTitleChecked, isLocationChecked, handleDownloadBtnStatus, handlePreviewClick, userDetails: { preferredFirstName: loginUserFirstName, preferredLastName: loginUserLastName } } = this.props;
            this.setState({ isPreviewDisable: true });
            handlePreviewClick(byType);
            handleDownloadBtnStatus(true);
            let finalData = peopleIdsWithData;
            if (peopleIds?.length > 0) {
                if (byType === BY_PEOPLE) {
                    finalData = await getPeopleApiDataByHrids(peopleIds, getPeopleDataUsingIDs, false, true);
                }
                let startImageUrl = '';
                let endImageUrl = '';
                const payloadPeopleData = finalData?.length > 0 && finalData?.map((personData) => {
                    const { id, preferredFirstName, preferredLastName, businessTitle, picture: imageUrl, hostOfficeLocation: { name } } = personData;
                    const { imageName, domain, policy } = imageUrl ? getImageNamePreAndPost(imageUrl) : '';

                    if (!startImageUrl || !endImageUrl) {
                        startImageUrl = domain || '';
                        endImageUrl = policy || '';
                    }
                    return {
                        hrid: id,
                        name: `${preferredFirstName} ${preferredLastName}`,
                        title: businessTitle,
                        image: imageName,
                        location: name

                    }
                })
                faceCardv1SlidePeopleData(payloadPeopleData);
                const payloadData = {
                    appName: "C&M",
                    templateType: "face-card",
                    requestedBy: loginUserFirstName + " " + loginUserLastName, // TODO make it dynamic
                    startImageUrl,
                    endImageUrl,
                    filters: {
                        hasName: isNameChecked,
                        hasTitle: isTitleChecked,
                        hasLocation: isLocationChecked,
                        size: "M", //TODO make it dynamic in future stories
                        grayscale: false, //TODO make it dynamic in future stories
                        shape: "circle", //TODO make it dynamic in future stories
                    },
                    employees: payloadPeopleData
                }
                getFaceCardv1SlideData(payloadData);
                this.setState({ isPreviewDisable: false });
                handleDownloadBtnStatus(false)
                handlePreviewShow(true)
            }
        } catch (error) {
            const { handleDownloadBtnStatus } = this.props
            this.setState({ isPreviewDisable: false, isDownloadDisable: false });
            handleDownloadBtnStatus(false)
            console.log('Error in handlePreview ', error)
        }
    }
    handleDownload = () => {
        const { preSignedUrl, triggerPreviewDownloadAnalytics } = this.props;
        const { byType } = this.state;
        triggerPreviewDownloadAnalytics(false, byType)
        if (preSignedUrl) {
            downloadFileFromUrl(preSignedUrl);
            this.triggerUsabilla();
        }
    }
    triggerUsabilla = () => {
        const {
            USABILLA_SURVEY_KEYS: {
                FACECARD_DOWNLOAD
            }
        } = CONSTANTS;
        triggerUsabillaSurvey(FACECARD_DOWNLOAD);
    }

    updateButtonsConfig = (buttons) => {
        try {
            const { getLoading, isDownloadDisable, isError } = this.props;
            const { peopleIds, isPreviewDisable, selectedCase, isCaseTeamEmpty, byType, isLoadingEmailPeople } = this.state;
            if (buttons?.length > 0) {
                if ((peopleIds.length === 0) || getLoading) {
                    buttons[0].disabled = true;
                }
                else {
                    buttons[0].disabled = false;
                }
                if (peopleIds.length) {
                    if (isPreviewDisable || getLoading) {
                        buttons[0].disabled = true;
                    }
                    else {
                        buttons[0].disabled = false;
                    }
                }
                if (isDownloadDisable || getLoading || isError) {
                    buttons[1].disabled = true;
                } else {
                    buttons[1].disabled = false;
                }
                if (byType === BY_CASE_NO) {
                    if (isCaseTeamEmpty || peopleIds.length === 0 || getLoading || isPreviewDisable) {
                        buttons[0].disabled = true;
                    }
                    else {
                        buttons[0].disabled = false;
                    }
                }
                if (byType === BY_EMAIL_ID) {
                    if (isLoadingEmailPeople || peopleIds.length === 0 || getLoading || isPreviewDisable) {
                        buttons[0].disabled = true;
                    }
                    else {
                        buttons[0].disabled = false;
                    }
                }
            }
        } catch (error) {
            console.log('Error in updateButtonsConfig function ', error);
        }
    }
    onTypeChange = (value) => {
        const that = this;
        const { resetCallback } = this.props;
        const clearEmailData = (value !== BY_EMAIL_ID) && (this.state.byType === BY_EMAIL_ID);
        this.setState({
            byType: value,
            selectedCase: {},
            isPreviewDisable: false,
            prefillEmailIds: clearEmailData ? [] : this.state.prefillEmailIds,
        }, () => {
            that.setState({
                peopleIds: [],
                peopleIdsWithData: [],
            });
            if (resetCallback)
                resetCallback();
        });
    }

    searchCaseHandler = (keyword) => {
        this.fetchRelatedCases(keyword);
    }
    clearSearchResults = () => {
        const { resetSearchResults } = this.props;
        resetSearchResults();
    }
    handleOnResultClick = (item) => {
        const { id, caseNumber, sanitizedCaseTitle } = item;
        const selectedItem = {
            caseGuid: id,
            caseNumber,
            caseTitle: sanitizedCaseTitle,
        };
        this.setState({
            selectedCase: selectedItem
        });
        const { callBackTypeChange } = this.props;
        if (callBackTypeChange)
            callBackTypeChange(id);
    }

    crossClickCallback = () => {
        const { resetCallback } = this.props;
        this.setState({
            selectedCase: {},
            peopleIds: [],
            peopleIdsWithData: [],
            isCaseTeamEmpty: true
        }, () => {
            if (resetCallback)
                resetCallback();
        });
    }
    callBackAfterPeopleApiFetch = (data) => {
        const that = this;
        that.setState({ peopleIds: that.getHridArray(data), peopleIdsWithData: data, isLoadingEmailPeople: false });
    }

    caseTeamWithNoAlumniCallback = (data) => {
        if (!data?.length) {
            this.setState({ isCaseTeamEmpty: true })
        }
        else {
            this.setState({ isCaseTeamEmpty: false })
        }
    }
    fetchRelatedCases = (keyword) => {
        const { searchRelatedCases } = this.props;
        const {
            CASES_SEARCH_PAGINATION: {
                PAGE_SIZE,
                PAGE_NUMBER,
            }
        } = CONFIG;
        const payload = {
            caseNumber: keyword,
            doExactMatch: false,
            pageNumber: PAGE_NUMBER,
            pageSize: PAGE_SIZE,
        };
        searchRelatedCases(payload);
    }
    handleSubmitEmail = async (emailIds) => {
        const {
            peopleResultsActions: {
                getPeopleDataUsingEmails,
            }
        } = this.props;
        const { peopleIds } = this.state;
        const existingPeopleIds = [...peopleIds];
        this.setState({ peopleIds: [], peopleIdsWithData: [], isLoadingEmailPeople: true, prefillEmailIds: [] });
        const sortedPeopleData = await getPeopleApiDataByEmails(emailIds, getPeopleDataUsingEmails);

        const hrids = this.getHridArray(sortedPeopleData);
        const emails = this.getEmailsArray(sortedPeopleData)

        let emailsNotPresent = emailIds.filter(x => !emails.includes(x.toLowerCase()));
        // console.log("hrids", emails, emailIds, emailsNotPresent);
        this.setState({ peopleIds: [...existingPeopleIds, ...hrids] }, () => {
            const that = this;
            setTimeout(() => {
                that.setState({ prefillEmailIds: emailsNotPresent });
                if (!emails.length) {
                    that.setState({ isLoadingEmailPeople: false });
                }
            }, 800);
        });
    };

    resetHandler = () => {
        const { handlePreviewShow, handleDownloadBtnStatus, handleErrorStateChange } = this.props;
        handlePreviewShow(false)
        handleDownloadBtnStatus(true);
        handleErrorStateChange(false);
        this.setState({
            selectedCase: {},
            peopleIds: [],
            peopleIdsWithData: [],
            prefillEmailIds: [],
            isResetModalOpen: false,
        });
    }
    closeResetModal = () => {
        this.setState({
            isResetModalOpen: false
        });
    }
    toggleFaceCardForm = (templateTitle) => {
        this.setState({
            fcgClose: false,
            selectedTemplateTitle: templateTitle,
        });
    };

    handleClose = () => {
        this.setState({
            fcgClose: true,
        });
    };

    render() {
        const { handleTitleChange, handleLocationChange, handleNameChange, isNameChecked, isTitleChecked, isLocationChecked, searchResults, isSearchingCases, totalSearchResults, getLoading, isLoadingCaseMembers } = this.props;
        const buttons = copyObject(FACE_CARD_CONFIG.footerWorkflow);
        const { selectedCase, peopleIds, byType, isLoadingEmailPeople, isResetModalOpen, prefillEmailIds, peopleIdsWithData } = this.state;
        //Update buttons config
        this.updateButtonsConfig(buttons);
        let typeFilters = [
            { key: BY_PEOPLE, text: BY_PEOPLE, value: BY_PEOPLE },
            { key: BY_CASE_NO, text: BY_CASE_NO, value: BY_CASE_NO }
        ];
        if (process.env.REACT_APP_IS_FACECARD_EMAIL_ENABLED?.trim() === "true") {
            typeFilters = [...typeFilters, { key: BY_EMAIL_ID, text: BY_EMAIL_ID, value: BY_EMAIL_ID }]
        }
        const {
            WORK_SPACE: {
                WARNING_MODAL_CANCEL,
            },
            FACE_CARD_GENERATOR: {
                FACE_CARD_WARNING_IMAGE,
                RESET_BTN_CONFIRM,
                RESET_BTN_TEXT,
                RESET_WARNING_MESSAGE,
                SEARCH_RELATED,
                SEARCH_PLACEHOLDER,
                REFINE_SEARCH,
                NO_RESULTS,
            }
        } = LABELS;
        const { CP_CONTRIBUTION_LIMITS: { SEARCH_CASE_CHAR_LIMIT, SEARCH_CASES_LIMIT } } = CONFIG;
        return (
            <>
                <div className="faceCardFormv1__header">
                    <div className="faceCardFormv1__title-wrapper">
                        <div className="faceCardFormv1__title">
                            <h2>{this.props.selectedTemplateTitle || "Default Title"}</h2>
                        </div>
                        <button className="faceCardFormv1__changeBtn">Change Template</button>
                    </div>
                    <button className="faceCardFormv1__closeBtn" onClick={this.props.handleClose}>
                        <span>Close</span>
                        <img src={closeFcg} alt="Close" />
                    </button>
                </div>
                <div className="faceCardFormv1">
                    <div className="faceCardFormv1__content">
                        <div className="faceCardFormv1__slideTitleCheckboxes">
                            <div className="faceCardFormv1__slideTitle">
                                <div className='faceCardFormv1__slideText'>{"Slide Title"}</div>
                                <input
                                    type="text"
                                    className="faceCardFormv1__inputTitle"
                                    placeholder="Enter Slide Title"
                                />
                            </div>
                            <div className="faceCardFormv1__checkboxTitlee">
                                <div className="faceCardFormv1__checkboxTitle">
                                    {"Other informations to Include "}
                                </div>
                                <div className="faceCardFormv1-checkboxSec">
                                    <div className="faceCardFormv1__leftsec">
                                        <div className="faceCardFormv1-checkboxSec--name">
                                            <Checkbox
                                                onChange={() => handleNameChange(!isNameChecked)}
                                                checked={isNameChecked}
                                                name="isName"
                                                label={NAME}
                                                className="custom__checkbox"
                                            />
                                        </div>
                                        <div className="faceCardFormv1-checkboxSec--title">
                                            <Checkbox
                                                onChange={() => handleTitleChange(!isTitleChecked)}
                                                checked={isTitleChecked}
                                                name="isTitle"
                                                label={TITLE}
                                                className="custom__checkbox"
                                            />
                                        </div>
                                        <div className="faceCardFormv1-checkboxSec--location">
                                            <Checkbox
                                                onChange={() =>
                                                    handleLocationChange(!isLocationChecked)
                                                }
                                                checked={isLocationChecked}
                                                name="isLocation"
                                                label={LOCAITON}
                                                className="custom__checkbox"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faceCardFormv1__blackWhiteToggle">
                                <span>{"Use Black and white"}</span>
                                <ToggleSwitch optionalProps={{ slider: true }} />
                            </div>
                        </div>
                    </div>
                    <div className="faceCardFormv1-header">
                        <div className='faceCardFormv1-searchSec'>
                            <div className="faceCardFormv1__resetbtn">
                                <Button
                                    className="green-transparent"
                                    onClick={() => this.setState({ isResetModalOpen: true })}
                                    disabled={
                                        !this.state.isResetButtonEnabled ||
                                        (this.state.byType === BY_CASE_NO && this.state.isCaseTeamEmpty) ||
                                        this.state.isLoadingEmailPeople
                                    }
                                >
                                    <span>{"Clear All"}</span>
                                </Button>
                                <WarningModal
                                    heading={RESET_WARNING_MESSAGE}
                                    button1={{
                                        text: WARNING_MODAL_CANCEL,
                                        clickHandler: this.closeResetModal,
                                    }}
                                    button2={{
                                        text: RESET_BTN_CONFIRM,
                                        clickHandler: this.resetHandler,
                                    }}
                                    iconName={"warning"}
                                    isOpen={this.state.isResetModalOpen}
                                    onRequestClose={this.closeResetModal}
                                />
                            </div>
                            <PeopleSelector
                                placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                                isInputFirst
                                name="authors"
                                preFillData={[peopleIds]}
                                isStateReset={!peopleIds.length}
                                isBigImage
                                excludeAlumni
                                callBackAfterPeopleApiFetch={this.callBackAfterPeopleApiFetch}
                                caseTeamWithNoAlumniCallback={this.caseTeamWithNoAlumniCallback}
                                hideInput={byType === BY_CASE_NO || byType === BY_EMAIL_ID}
                                onChange={(data) => {
                                    this.handleChange(data);
                                }}
                                suggestions={{
                                    useDragDrop: true
                                }}
                                customSelect={
                                    <>
                                        <div className={`faceCardFormv1__dropdown ${byType === BY_CASE_NO ? 'faceCardFormv1__dropdown--casetype' : ''} `}>
                                            <Dropdown
                                                fluid
                                                // defaultValue={PAGES}
                                                icon='search'
                                                selection
                                                value={byType}
                                                options={typeFilters}
                                                onChange={(e, { value }) => { this.onTypeChange(value); }}
                                            />
                                        </div>
                                        {byType === BY_CASE_NO &&
                                            // <input className="ffaceCardFormv1__caseinput" type='text' placeholder='Search by Case number' />
                                            <>
                                                <SearchBox
                                                    handleOnSearch={this.searchCaseHandler}
                                                    selectedCase={selectedCase}
                                                    crossClickCallback={this.crossClickCallback}
                                                    handleOutsideClick={this.clearSearchResults}
                                                    label={SEARCH_RELATED}
                                                    placeHolder={SEARCH_PLACEHOLDER}
                                                    showErrorInResults={true}
                                                    noResultsMessage={NO_RESULTS}
                                                    tooManyResultsMessage={REFINE_SEARCH}
                                                    maximumResults={SEARCH_CASES_LIMIT}
                                                    maxLength={SEARCH_CASE_CHAR_LIMIT}
                                                    searchResults={searchResults}
                                                    totalCount={totalSearchResults}
                                                    resultsHeading={"Case Suggestions"}
                                                    isSearching={isSearchingCases}
                                                    validateInput={validateCaseSearch}
                                                    handleOnResultClick={this.handleOnResultClick}
                                                    renderResult={item => {
                                                        const { sanitizedCaseTitle, caseNumber } = item;
                                                        return <CaseSearchItem caseNumber={caseNumber} caseTitle={sanitizedCaseTitle} />
                                                    }}
                                                    hideLabel
                                                />
                                                {isLoadingCaseMembers && <Image
                                                    className="loadericon faceCardFormv1__loaderPeoples"
                                                    src={LoaderIcon}
                                                    alt="In progress"
                                                />}
                                            </>
                                        }
                                        {byType === BY_EMAIL_ID &&
                                            <>
                                                <EmailInput
                                                    onSubmit={this.handleSubmitEmail}
                                                    placeholder={FACECARD_PEOPLE_SELECTOR_EMAIL}
                                                    prefillEmails={prefillEmailIds}
                                                    isLoading={isLoadingEmailPeople}
                                                />
                                                {isLoadingEmailPeople && <Image
                                                    className="loadericon faceCardFormv1__loaderPeoples"
                                                    src={LoaderIcon}
                                                    alt="In progress"
                                                />}
                                            </>
                                        }
                                    </>
                                }
                                getSuggestionValueCallback={() => { }}
                                className="faceCardFormv1__peopleselector"
                                isScrollable={true}
                            />
                        </div>
                        <div className='faceCardFormv1-btnSec'>
                            {getLoading && <Image
                                className="loadericon"
                                src={LoaderIcon}
                                alt="In progress"
                            />}
                            {buttons &&
                                buttons.map((btn, ind) => (
                                    <React.Fragment key={`btn-child-${ind}`}>
                                        {btn?.visible && (
                                            <Button className={`${ind == 1 ? "green" : "black"} ${btn.classname} ${btn.disabled ? 'btn-disbled' : ''}`} onClick={() => this.handleFaceCardBtnClick(btn?.id)} disabled={btn.disabled} >
                                                <span>{btn.text}</span>
                                            </Button>
                                        )}
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    getLoading: fcgSelectors.getLoading(state),
    preSignedUrl: fcgSelectors.getPresignedURL(state),
    isSearchingCases: caseMetadataSelectors.getSearching(state),
    searchResults: caseMetadataSelectors.getSearchResults(state),
    isLoadingCaseMembers: caseConsumptionSelectors.getCaseDetailsLoading(state),
    totalSearchResults: caseMetadataSelectors.getTotalSearchResults(state),
});

const mapDispatchToProps = (dispatch) => ({
    getFaceCardv1SlideData: (userDetails) => dispatch(getFaceCardv1SlideData(userDetails)),
    peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
    faceCardv1SlidePeopleData: (peopleData) => dispatch(faceCardv1SlidePeopleData(peopleData)),
    searchRelatedCases: (payload) => dispatch({ type: SEARCH_CASES, payload }),
    resetSearchResults: () => dispatch({ type: RESET_SEARCH_RESULTS })
});

export const FaceCardFormGeneratorv1 = connect(
    mapStateToProps,
    mapDispatchToProps
)(FaceCardFormv1);

export { FaceCardFormv1 };
