import React, { PureComponent } from "react";
import history from "redux/history";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, runSaga } from "redux/store";
import CONFIG from "config";
import { Route, Switch } from "react-router-dom";
import { Security, SecureRoute } from "@okta/okta-react";
import ImplicitCallbackWrapper from "components/ImplicitCallback/ImplicitCallback";
import authService from "redux/auth";
import { toRelativeUrl } from '@okta/okta-auth-js';
import { KpConsumption } from 'containers/KpConsumption/KpConsumption';
import { KnowledgePage } from "containers/KnowledgePage";
import { CasePage } from "containers/CasePage";
import { FaceCardGenerator } from "containers/FaceCardGenerator/FaceCardGenerator";
import { FaceCardGeneratorv1 } from "containers/FaceCardGeneratorv1/FaceCardGeneratorv1";
import ErrorPage from "containers/ErrorPage/ErrorPage";
import { Creation } from "containers/Creation";
import { MyNavigator } from "containers/MyNavigator";
import rootSaga from "redux/sagas";
import { KbConsumption } from 'containers/KnowledgeBundles/KbConsumption/KbConsumption';
import CaseConsumption from 'containers/CaseConsumption/CaseConsumption';
import { KpDownload } from 'containers/KpDownload';
import { KnowledgeBundles } from 'containers/KnowledgeBundles';
import { isIE } from 'utils/helpers/helpers';
import CONSTANTS from 'globalConstants';
import { getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { deleteCookie, getCookie } from "utils/cookies/cookies";

const {
  UI_URL: {
    HOME,
    METADATA_KB,
    METADATA_KP,
    KP_PREVIEW_DETAILS,
    KP_CONSUMPTION,
    CREATION,
    CP_METADATA,
    KB_PREVIEW,
    KB_CONSUMPTION,
    CP_CONSUMPTION_NEW,
    KP_DOWNLOAD,
    ERROR,
    FACE_CARD_GENERATOR_V1,
    FACE_CARD_GENERATOR
  },
  OKTA: {
    REDIRECT_URL
  },
} = CONFIG;

const { ERROR_IE } = CONSTANTS;

export class App extends PureComponent {
  componentDidMount() {
    runSaga(rootSaga);
    const { location: { pathname } } = window;
    if (isIE() && pathname !== ERROR(ERROR_IE)) {
      window.location.replace(ERROR(ERROR_IE));
    }
    if (getCookie(CONFIG.OKTA.STK)) {
      deleteCookie(CONFIG.OKTA.STK);
    }
  }
  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Security oktaAuth={authService} restoreOriginalUri={this.restoreOriginalUri}>
            <Switch>
              <SecureRoute
                exact
                path={HOME}
                component={MyNavigator}
              />
              <SecureRoute
                path={METADATA_KB()}
                component={KnowledgeBundles}
              />
              <SecureRoute
                path={METADATA_KP()}
                component={KnowledgePage}
              />
              <SecureRoute
                path={CREATION}
                component={Creation}
              />
              <SecureRoute
                path={[KP_PREVIEW_DETAILS(':kpId'), KP_CONSUMPTION(':kpId')]} //sample route :- kp/preview/{kpId}
                component={(props) => <KpConsumption key={window.location.pathname} {...props} />}
              />
              <SecureRoute
                path={CP_METADATA()}
                component={CasePage}
              />
              <SecureRoute
                path={[KB_PREVIEW(':kbId'), KB_CONSUMPTION(':kbId')]}
                component={KbConsumption}
              />
              <SecureRoute
                path={CP_CONSUMPTION_NEW(':cid')}
                component={CaseConsumption}
              />
              <SecureRoute
                path={KP_DOWNLOAD(':appName', ':type', ':kpId')}
                component={KpDownload}
              />
              <SecureRoute
                exact
                path={FACE_CARD_GENERATOR}
                component={FaceCardGenerator}
              />
              <SecureRoute
                exact
                path={FACE_CARD_GENERATOR_V1}
                component={FaceCardGeneratorv1}
              />
              <SecureRoute
                path={ERROR(':errorCode')}
                component={ErrorPage}
              />
              <Route
                path={REDIRECT_URL}
                component={ImplicitCallbackWrapper}
              />
            </Switch>
          </Security>
        </ConnectedRouter>
      </Provider>
    );
  }
}