import { FCG_NEW } from "redux/constants";
export const name = "faceCardGeneratorv1";

export const initialState = {
    loading: false,
    error: false,
    errorMessage: "",
    requestId: null,
    peopleData: [],
    peoplePayloadData: null,
    preSignedUrl: "",
    status: null

};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getRequestID: state => state[name].requestId,
    getPeopleData: state => state[name].peopleData,
    getPeoplePayloadData: state => state[name].peoplePayloadData,
    getPresignedURL: state => state[name].preSignedUrl,
};


export function reducer(state = initialState, action) {
    switch (action.type) {

        case FCG_NEW.FC_REQUEST_API_PENDING:
            return { ...state, loading: true };
        case FCG_NEW.FC_REQUEST_API_SUCCESS:
            return { ...state, requestId: action.requestId, loading: false };
        case FCG_NEW.FC_REQUEST_API_FAILURE:
            return { ...state, error: action.error, loading: false };


        case FCG_NEW.FC_POLLING_API_PENDING:
            return { ...state, loading: true };
        case FCG_NEW.FC_POLLING_API_SUCCESS:
            return { ...state, status: action.status, loading: false };
        case FCG_NEW.FC_POLLING_API_FAILURE:
            return { ...state, error: action.error, loading: false };



        case FCG_NEW.FC_SLIDE_PEOPLE_DATA:
            return {
                ...state,
                peopleData: action.payload
            };
        case FCG_NEW.GET_FC_SLIDE_DATA:
            return {
                ...state,
                peoplePayloadData: action.payload
            };

        default:
            return state;
    }
};