import React from "react";
import { connect } from "react-redux";
import { Layout } from "containers/Layout";
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import { FaceCardFormGenerator } from "./FaceCardForm/FaceCardForm";
import FaceCardSlideListing from "components/FaceCardSlideListing/FaceCardSlideListing";
import { selectors as caseConsumptionSelectors } from "redux/reducers/caseConsumption/caseConsumption_reducer";
import { selectors as fcgSelectors } from "redux/reducers/faceCardGenerator/faceCardGenerator_reducer";
import {
	PAGES,
	PRIMARY_CATEGORY,
	DTMRULE,
	TOOLNAME,
	TRIGGERS,
} from "utils/analytics/analytics_constants";
import ANALYTICS from "utils/analytics/analytics";
import { CASE_METADATA } from "redux/constants";
import { getmyCurrentLocation } from "utils/helpers/helpers";
import FaceCardDefaultView from "components/FaceCardDefaultView/FaceCardDefaultView";
import LABELS from "labels";
import './FaceCardGenerator.scss';
import { FullPageLoader } from "components/shared/FullPageLoader";
import { Helmet } from "react-helmet";

const {
	GET_CASE_METADATA,
} = CASE_METADATA;

class FaceCardGeneraterContainer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isDownloadDisable: true,
			isNameChecked: true,
			isTitleChecked: false,
			isLocationChecked: false,
			isNameShow: false,
			isTitleShow: false,
			isLocationShow: false,
			dividedSlides: [],
			peopleHrids: [],
			isPreviewShow: false,
			isResetButtonEnabled: false,
			isResetError: true,
		};
	}
	componentDidMount() {
		ANALYTICS.page.initialize({
			pageName: PAGES.FACE_CARD_GENERATOR,
			category: PRIMARY_CATEGORY.FACE_CARD_GENERATOR,
			trackPageLoad: true,
		});

	}
	callBackTypeChange = (selectedCase) => {
		const { getCaseDetails } = this.props;
		getCaseDetails(selectedCase);
	}
	componentDidUpdate(prevProps, prevState) {
		const { peopleData, peoplePayloadData } = this.props;
		const { isTitleChecked, isLocationChecked, isNameChecked, peopleHrids } = this.state;

		if (peopleData !== prevProps.peopleData) {
			this.setState({ dividedSlides: this.handleSlideDivision() });
		}

		const isTitleChanged = isTitleChecked !== prevState.isTitleChecked;
		const isLocationChanged = isLocationChecked !== prevState.isLocationChecked;
		const isNameChanged = isNameChecked !== prevState.isNameChecked;
		const arePeopleHridsChanged =
			JSON.stringify(peopleHrids) !== JSON.stringify(prevState.peopleHrids);

		if (isTitleChanged || isLocationChanged || isNameChanged || arePeopleHridsChanged) {
			const hasTitleChanged = peoplePayloadData?.filters.hasTitle !== isTitleChecked;
			const hasLocationChanged = peoplePayloadData?.filters.hasLocation !== isLocationChecked;
			const hasNameChanged = peoplePayloadData?.filters.hasName !== isNameChecked;

			const hasHridChanged =
				JSON.stringify(peoplePayloadData?.employees.map((employee) => employee.hrid)) !==
				JSON.stringify(peopleHrids);

			this.setState({
				isDownloadDisable: hasTitleChanged || hasLocationChanged || hasNameChanged || hasHridChanged,
			});
		}
	}
	resetCallback = () => {
		this.setState({
			isDownloadDisable: true,
			dividedSlides: [],
			isPreviewShow: false
		});
	}
	triggerPreviewDownloadAnalytics = (isPreview, byType) => {
		const { CONTRIBUTE_KNOWLEDGE } = TOOLNAME;
		const newAdobeData = {
			page: {
				category: {
					primaryCategory: PRIMARY_CATEGORY.FACE_CARD_GENERATOR,
				},
				pageInfo: {
					pageName: PAGES.FACE_CARD_GENERATOR,
					pageURL: getmyCurrentLocation(),
				},
			},
			search: {
				term: "NA",
				searchTrigger: byType,
				typeHeadOptionText: "NA",
				typeHeadOptionRank: "NA",
				filterTerm: "NA",
				filterLabel: "NA",
				filterLevel: "NA",
				toolName: "NA",
			},
			preFilterTerm: "NA",
			officePage: {
				mainCategory: "NA",
			},
			rc: {
				tabName: isPreview ? TRIGGERS.PREVIEW : TRIGGERS.DOWNLOADS, //eVar48
			},
		};
		ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.TAB_CLICKS, CONTRIBUTE_KNOWLEDGE);
	};
	handleDownloadBtnStatus = (value) => {
		this.setState({ isDownloadDisable: value });
	};
	handleNameChange = (value) => {
		this.setState({ isNameChecked: value });
	};
	handleTitleChange = (value) => {
		this.setState({ isTitleChecked: value });
	};

	handleLocationChange = (value) => {
		this.setState({ isLocationChecked: value });
	};
	handlePreviewClick = (byType) => {
		this.setState({
			isTitleShow: this.state.isTitleChecked,
			isLocationShow: this.state.isLocationChecked,
			isNameShow: this.state.isNameChecked
		});
		this.triggerPreviewDownloadAnalytics(true, byType);
	};
	handleSlideDivision = () => {
		const { peopleData } = this.props;
		const slides = [];
		for (let i = 0; i < peopleData.length; i += 10) {
			slides.push(peopleData.slice(i, i + 10));
		}
		return slides;
	};
	handlePeopleSelectorUpdate = (value) => {
		this.setState({
			peopleHrids: value,
		});
	};
	handlePreviewShow = (value) => {
		this.setState({ isPreviewShow: value });
	};

	handleErrorStateChange = (newErrorState) => {
		this.setState({ isResetError: newErrorState });
	}

	render() {
		const { userDetails, getLoading, peoplePayloadData, caseDetails, error } = this.props;
		const {
			FACE_CARD_DEFAULT_VIEW: {
				stepper
			},
			PAGETITLE: {
				FACE_CARD_GENERATOR
			}
		} = LABELS;
		// const {
		// 	FACE_CARD_ERROR_PAGE:{
		// 		MESSAGE_1,MESSAGE_2
		// 	}
		// } = LABELS;
		const {
			isPreviewShow,
			isDownloadDisable,
			isNameChecked,
			isTitleChecked,
			isLocationChecked,
			isTitleShow,
			isNameShow,
			isResetError,
			isLocationShow,
			dividedSlides,
		} = this.state;
		return (
			<>
				<Helmet>
					<title>{FACE_CARD_GENERATOR}</title>
				</Helmet>
				<Layout
					isHeaderShow={false}
					isFooterShow={false}
					userDetails={userDetails}
					customClass="facecardgenerator">

					<FaceCardFormGenerator
						handlePreviewShow={this.handlePreviewShow}
						resetCallback={this.resetCallback}
						handlePeopleSelectorUpdate={this.handlePeopleSelectorUpdate}
						triggerPreviewDownloadAnalytics={this.triggerPreviewDownloadAnalytics}
						handlePreviewClick={this.handlePreviewClick}
						handleNameChange={(data) => this.handleNameChange(data)}
						handleTitleChange={(data) => this.handleTitleChange(data)}
						isNameChecked={isNameChecked}
						isTitleChecked={isTitleChecked}
						isLocationChecked={isLocationChecked}
						callBackTypeChange={this.callBackTypeChange}
						handleLocationChange={this.handleLocationChange}
						isDownloadDisable={isDownloadDisable}
						// isDownloadDisable={this.state.isDownloadDisable}
						handleDownloadBtnStatus={(data) => this.handleDownloadBtnStatus(data)}
						isError={error}
						caseTeam={caseDetails?.caseTeam || []}
						handleErrorStateChange={this.handleErrorStateChange}
					/>
					<div className="facecardgenerator__resultsection">
						{isPreviewShow && !error && dividedSlides?.length > 0 && !getLoading ? (
							<FaceCardSlideListing
								startImageUrl={peoplePayloadData?.startImageUrl}
								endImageUrl={peoplePayloadData?.endImageUrl}
								dividedSlides={dividedSlides}
								isTitleShow={isTitleShow}
								isLocationShow={isLocationShow}
								isNameShow={isNameShow}
							/>
						) : <FaceCardDefaultView
							stepper={stepper}
							isError={error && isResetError}
						/>
						}
						<FullPageLoader isVisible={getLoading} />
					</div>
				</Layout>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: globalSelectors.getUserDetails(state),
		peopleData: fcgSelectors.getPeopleData(state),
		getLoading: fcgSelectors.getLoading(state),
		error: fcgSelectors.getError(state),
		caseDetails: caseConsumptionSelectors.getCaseDetails(state),
		peoplePayloadData: fcgSelectors.getPeoplePayloadData(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCaseDetails: (cid) => dispatch({ type: GET_CASE_METADATA, payload: { cid, isConsumption: true } })
});

export const FaceCardGenerator = connect(
	mapStateToProps,
	mapDispatchToProps
)(FaceCardGeneraterContainer);

export { FaceCardGeneraterContainer };
