import { put, call, takeLatest, delay } from 'redux-saga/effects';
import axios from 'axios';

// Constant
import { FCG_NEW } from "redux/constants";

// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { newRequestAPIPending, newRequestAPISuccess, newRequestAPIFailure, pollingAPIStart, pollingAPISuccess, pollingAPIFailure } from "./faceCardGeneratorv1.actions";

export function* handleNewRequestAPI(action) {
    try {
        yield put(newRequestAPIPending());
        const { XA, API_URL: { FACE_CARD_GENERATOR_ACKNOWLEDGE } } = CONFIG;
        const { payload } = action;
        const axiosConfig = {
            method: 'POST',
            endpoint: FACE_CARD_GENERATOR_ACKNOWLEDGE,
            params: payload,
            headers: {
                'x-api-key': XA
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response?.error.code === "200" && response?.requestId) {
            yield put(newRequestAPISuccess(response?.requestId));
            yield put(pollingAPIStart(response?.requestId));
        } else {
            yield put(newRequestAPIFailure(response?.error.toString()));
        }
    }
    catch (error) {
        yield put(newRequestAPIFailure(error.toString()));
    }
}

function* handlePollingAPI(action) {   //shreya
    try {
        const { API_URL: { FACE_CARD_GENERATOR_POLLING } } = CONFIG;
        const axiosConfig = {
            method: 'GET',
            endpoint: FACE_CARD_GENERATOR_POLLING + '?requestId=' + action.payload.requestId,
            headers: {
                'x-api-key': CONFIG.XA
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        const status = response?.status?.toLowerCase()
        if (response?.error.code === "200" && status !== 'error') {
            if (status === 'completed') {
                yield put(pollingAPISuccess(response));
            } else {
                yield delay(2000);  // Wait for 1 second before next poll
                yield put(pollingAPIStart(action.payload.requestId));
            }
        }

    } catch (error) {
        yield put(pollingAPIFailure(error.toString()));
    }
}

export default function* faceCardGeneratorSagaV1() {
    yield takeLatest(FCG_NEW.GET_FC_SLIDE_DATA, handleNewRequestAPI);
    yield takeLatest(FCG_NEW.FC_POLLING_API_PENDING, handlePollingAPI);
}
