import { isEmpty } from "lodash";
import moment from "moment";
import powerpointIcon from "assets/images/powerpoint.svg";
import excelIcon from "assets/images/excel.svg";
import wordIcon from "assets/images/word.svg";
import zipIcon from "assets/images/zip-folder.svg";
import adobeIcon from "assets/images/adobe.svg";
import linkIcon from "assets/images/linkify.svg";
import collectionIcon from "assets/images/collection.svg";
import miscIcon from "assets/images/misc-logo.svg";
import authService from 'redux/auth';
import { removeFromLocalStorage, sendToLocalStorage, getFromLocalStorageAsJSON } from "utils/localStorage/localStorage";
import CONFIG from 'config';
import LABELS from 'labels';
import axios from 'axios';
import CONSTANTS from 'globalConstants';
import { removeStopwords, eng } from 'stopword';
import { store } from "../../redux/store";


/**
 * @desc this function return a new array with filter
 * @param {Array<Object>} imageDetails
 * @returns update image details array of object
 */
export const filterImageDetails = (imageDetails) => {
  let images = [];
  const { SITECORE_XM_ENABLED } = CONFIG;
  imageDetails.forEach((image) => {
    if (image.Name !== "Betty Blue") {
      if (SITECORE_XM_ENABLED) {
        images.push({
          id: image.Id,
          name: image.Name,
          value: image.Path,
        });
      } else {
        images.push({
          name: image.Name,
          value: `${image.FieldValues.Small_Image_Path__Url}`,
        });
      }
    }
  });
  return images;
};

export const formatDisplayName = (name) => {
  let formattedDisplayName = name?.replace(/,/g, ' ')
    .split(' ')
    .reverse()
    .join(' ');
  return formattedDisplayName;
}

/**
 * 
 * @param {string} surveyName | Actual survey Name
 * @param {string} buttonId | option param
 * 
 * Example 
 * option 1: deleteUsabillaKey('KP_PUBLISH');
 * option 2: deleteUsabillaKey('KP_PUBLISH', 'd0d0c7d779ff);
 * 
 */

export const deleteUsabillaKey = (surveyName, buttonId) => {
  const { USABILLA: { GET_USABILLA_NAME, BUTTON_ID, MAPPING_SURVERY } } = CONFIG;
  const finalButtonId = buttonId ? buttonId : BUTTON_ID;
  const analyticsId = MAPPING_SURVERY[surveyName];
  const keyName = GET_USABILLA_NAME(finalButtonId, analyticsId);
  removeFromLocalStorage(keyName);
}

/**
 * 
 * @param {string} surveyName | Actual survey Name
 * @param {string} buttonId | option param
 * 
 * Example 
 * option 1: triggerUsabillaSurvey('KP_PUBLISH');
 * option 2: triggerUsabillaSurvey('KP_PUBLISH', 'd0d0c7d779ff);
 * 
 */
export const triggerUsabillaSurvey = (surveyName, buttonId) => {
  const { VIRTUAL_PAGE_VIEW } = CONSTANTS;
  if (!!window && window.usabilla_live) {
    deleteUsabillaKey(surveyName, buttonId);
    window.usabilla_live('trigger', surveyName);
    window.usabilla_live(VIRTUAL_PAGE_VIEW);
  }
};

// To prevent XSS vulnerability
export const escapeHTML = str =>
  str.replace(
    /[&<>'"]/g,
    tag =>
    ({
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      "'": '&#39;',
      '"': '&quot;'
    }[tag] || tag)
  );
export const downloadFileFromUrl = (url) => {
  const tempLink = document.createElement('a');
  tempLink.href = url;
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.remove();
}

export const downloadClippedFileFromUrl = async (clippedResponse, fileName) => {
  const clipBlob = new Blob([clippedResponse], { type: 'mime' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(clipBlob, `${fileName}`);
  } else {
    const clipDownloadResponse = window.URL.createObjectURL(clipBlob);
    const tempLink = document.createElement('a');
    tempLink.href = clipDownloadResponse;
    tempLink.setAttribute('download', `${fileName}`);
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.remove();
  }
}

export const replaceCharacters = (replacingCharacters, str) => {
  replacingCharacters.length &&
    replacingCharacters.forEach((char) => {
      const reg = new RegExp(char, "g");
      str = str.replace(reg, "_");
    });
  return str;
}

export const getPeopleDataByID = async (ids, inputByEmail, getPeopleDataUsingIDs) => {
  const data = await getPeopleDataUsingIDs(ids, inputByEmail);
  const temp = {};
  let temp1 = {};

  if (data && data[0]) {
    data.forEach((peopleObj) => {
      const { id } = peopleObj;
      temp['id-' + id] = peopleObj;
    });
    if (ids.length > 1) {
      //sorting loop in the same format
      ids.forEach((id, index) => {
        const idNew = 'id-' + id;
        temp1[idNew] = temp[idNew];
      });
    } else {
      temp1 = { ...temp };
    }
    return temp1;
  }
}

export const formattedDescription = (description) => {
  const desc = description.replace(/<p><\/p>/g, '').replace('/↵/g', '');
  return desc;
}


const trimDescriptionBlocksFromStart = (descriptionBlocks) => {
  try {
    let topData = [];
    let topDataFound = false;
    for (let i = 0; i < descriptionBlocks.length; i++) {
      let trimText = descriptionBlocks[i].text.trim();
      if (trimText.length) {
        topData.push(descriptionBlocks[i]);
        topDataFound = true;
      } else if (topDataFound) {
        topData.push(descriptionBlocks[i]);
      }
    }

    return topData;
  } catch (error) {
    console.log('error ', error)
  }
  return descriptionBlocks;
}

const trimDescriptionBlocksFromEnd = (topData) => {
  try {
    let bottomData = [];
    let bottomDataFound = false;
    for (let i = topData.length - 1; i >= 0; i--) {
      let trimText = topData[i].text.trim()
      if (trimText.length) {
        bottomData.push(topData[i])
        bottomDataFound = true
      } else if (bottomDataFound) {
        bottomData.push(topData[i])
      }
    }
    return bottomData;
  } catch (error) {
    console.log('error ', error)
  }
  return topData;
}

const getFinalDescriptionsBlocks = (bottomData) => {
  try {
    let finalBlocks = [];
    for (let i = bottomData.length - 1; i >= 0; i--) {
      if (bottomData.length === 1) {
        bottomData[i].text = bottomData[i].text.trim()
      } else if ((bottomData.length - 1) === i) {
        bottomData[i].text = bottomData[i].text.trimStart()
      } else if (i === 0) {
        bottomData[i].text = bottomData[i].text.trimEnd()
      }
      finalBlocks.push(bottomData[i]);
    }
    return finalBlocks;
  } catch (error) {
    console.log('error ', error)
  }
  return bottomData;
}

export const trimDescriptionBlocksFromStartAndEnd = (descriptionBlocks) => {
  try {
    let topData = trimDescriptionBlocksFromStart(descriptionBlocks);
    let bottomData = trimDescriptionBlocksFromEnd(topData);
    let finalBlocks = getFinalDescriptionsBlocks(bottomData)

    return finalBlocks;
  } catch (error) {
    console.log('error ', error)
  }
  return descriptionBlocks;
}

/**
 * format string from start and end
 * @param {*} description 
 * @returns 
 */
export const formattedDescriptionFromStartAndEnd = (description, simpleDescriptionPlainText = "") => {
  try {
    let descriptionAfterTrim = simpleDescriptionPlainText.trim();
    if (descriptionAfterTrim.length === 0) {
      description = "<p></p>"
    }
    return description;
  } catch (error) {
    console.log('Error in formatted Description ', error)
  }
}

/**
 * @desc this function return a new object with updated values
 * @param {Array<Object>} metadata
 * @returns update meta data object
 */
export const mapUpdatesToMetadata = (metadata, key, value) => {
  let newmetadata = metadata;
  let x;
  for (x in newmetadata) {
    if (x === key) {
      newmetadata[x].push(value);
    }
  }
  return newmetadata;
};

export const concat = (...args) => {
  return args.reduce((acc, val) => [...acc, ...val]);
};

export const getUtcToLocalDate = (date) => {
  let validDate = date.split(' ').join('T'); // this is required for IE and safari
  let localDate = new Date(validDate);
  return ('0' + (localDate.getMonth() + 1)).slice(-2) + '/' + ('0' + localDate.getDate()).slice(-2) + '/' + localDate.getFullYear();

};

/**
 * @description Get a UTC date in format YYYY-MM-DD HH:MM:SSZ
 * @param {Date} date | date object to be converted in UTC.
 * @returns {string} date | UTC date in format 2020-08-31 10:22:31Z
 */
export const getUtcDate = (date) => {
  try {
    if (date instanceof Date && !isNaN(date)) {
      let isoDate = date.toISOString(); // 2020-08-31T10:22:31.921Z
      return `${isoDate.split('T').join(' ').split('.')[0]}Z`;
    }
    throw new TypeError(LABELS.ERROR_MESSAGES.INVALID_DATE);
  } catch (err) {
    console.error(err);
  }
};

/***
 * @description method to format the date
 * @param  {dateTobeFormatted} dateTobeFormatted For eg. 2021-03-03T12:45:33Z
 * @param {dateformat} for eg. 'MMM DD, YYYY'
 * @return formatted date for eg. Jul 02, 2021
 */

export const formatTheDate = (dateTobeFormatted, dateformat) => {
  try {
    if (dateTobeFormatted) {
      let formattedDate = dateTobeFormatted ? moment.utc(dateTobeFormatted).local().format(dateformat) : null;
      return formattedDate;
    }
  } catch (err) {
    console.error(err);
  }
}

/**
 * @desc this function is used to get the file extension based on File Name
 * @param fileName as string
 * @returns the extension string
 */
export const getFileExtension = (FileName) => {
  const extn = !isEmpty(FileName)
    ? FileName.indexOf(".") <= -1
      ? ""
      : FileName.substring(FileName.lastIndexOf(".") + 1).toLowerCase()
    : "";
  return extn;
}

export const getFileNameOnly = (FileName) => {
  const extn = !isEmpty(FileName)
    ? FileName.indexOf(".") <= -1
      ? ""
      : FileName.substring(0, FileName.lastIndexOf("."))
    : "";
  return extn;
}

export const isFileTypePPT = (FileName) => {
  const fileextn = getFileExtension(FileName);
  const pptExtn = ["ppt", "pptx"];
  return pptExtn.indexOf(fileextn) !== -1;
}

/**
 * @desc this function is used to get the s3 document path based on File Name and id
 * @param fileName as string
 * @param id as string
 * @returns the extension string
 */
export const getDocumentPath = (fileName, id, version) => {
  let documentPath = "";
  if (fileName && id) {
    let fileextn = getFileExtension(fileName);
    documentPath = `${id}/${version}/${id}.${fileextn}`;
  }
  return documentPath;
}

/**
 * @desc this function is used to get the file icon path based on File Name
 * @param fileName as string
 * @returns the fileicon svg path string
 */
export const getFileIcon = (FileName) => {
  const extn = getFileExtension(FileName);
  let attachmentIcon = '';
  if (extn.search("ppt") !== -1 || extn.search("pptx") !== -1 || extn.search("ppsx") !== -1) {
    attachmentIcon = powerpointIcon;
  } else if (extn.search("xls") !== -1 || extn === "csv") {
    attachmentIcon = excelIcon;
  } else if (extn.search("zip") !== -1) {
    attachmentIcon = zipIcon;
  } else if (extn.search("pdf") !== -1) {
    attachmentIcon = adobeIcon;
  } else if (extn.search("doc") !== -1 || extn.search("docx") !== -1) {
    attachmentIcon = wordIcon;
  } else if (!extn && FileName === 'link') {
    attachmentIcon = linkIcon;
  } else if (extn.search("collection") !== -1) {
    attachmentIcon = collectionIcon;
  } else {
    attachmentIcon = miscIcon;
  }
  return attachmentIcon;
}

/**
 * @desc this function is used to compare response status and status text
 * @param response as object
 * @returns the flag
 */

export const isResponseOk = (response) => {
  return (response.status === 200 && response.statusText === "OK")
};

/**
 * @desc this function is calculate whether any pa topic is selected or not based on property isOwner
 * @param paTags as array of objects
 * @returns boolean flag
 */

export const isSectorTopicOwnerSelected = (paTags) => {
  let { ipaTags, fpaTags, bcgInternals } = paTags[0];
  let paTagsOwner = false;
  let newpaTags = [...ipaTags, ...fpaTags, ...bcgInternals];
  Object.keys(newpaTags).forEach((key) => {
    if (!!newpaTags[key].isOwner) {
      paTagsOwner = true;
    }
  });
  return paTagsOwner;
};

export const getCountOfFlagsAsTrue = (arrayOfObjects, flagName) => {
  let count = 0;
  arrayOfObjects.forEach(obj => {
    if (obj[flagName] && Boolean(obj[flagName])) {
      count++;
    }
  });
  return count;
}

/**
 * @desc this function is add isOwner property to each array element
 * @param tags as array of objects
 * @param pillTopicId as string
 * @returns array with new isOwner property added
 */

export const setSectorOwner = (tags, pillTopicId) => {
  let newtags = tags;
  !!newtags.length && Object.keys(newtags).forEach((key) => {
    if (newtags[key].id === pillTopicId) {
      newtags[key].isOwner = newtags[key].isOwner !== "undefined" ? !newtags[key].isOwner : true;
    } else {
      newtags[key].isOwner = false;
    }
  });
  return newtags;
}

/**
 * @description this function creates copy of an object
 * @param obj as an object 
 * @returns object copy
 */
export const copyObject = (obj) => {
  if (!!obj && typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
};

/**
 * @description this function compares two objects
 * @param obj1 as an object
 *  @param obj2 as an object
 * @returns true/false
 */
export const areObjectsEqual = (obj1, obj2) => {
  const stringifiedObj1 = obj1 && typeof obj1 === 'object' ? JSON.stringify(obj1) : obj1 ? obj1 : "";
  const stringifiedObj2 = obj2 && typeof obj2 === 'object' ? JSON.stringify(obj2) : obj2 ? obj2 : "";
  return stringifiedObj1 === stringifiedObj2;
};

/**
 * @desc this function toggles isOwner property to specific element and set false for all other
 * @param tabs as object of objects
 * @param pillId as string
 * @returns object with new isOwner property updated
 */
export const setOwnerForAllTabs = (tabs, pillId) => {
  const copy = copyObject(tabs);
  copy && Object.keys(copy).forEach(tab => {
    copy[tab] && Object.keys(copy[tab]).forEach(id => {
      if (id === pillId) {
        copy[tab][id].isOwner = !copy[tab][id].isOwner;
      }
      else {
        copy[tab][id].isOwner = false;
      }
    });
  });
  return copy;
};

/**
 * @desc this function is add lockedByName property to each array element
 * @param knowledgeItems as array of objects
 * @param people as array of objects
 * @param isKb as boolean
 * @returns array with new lockedByName property added
 */

export const mergeKnowledgeWithLockInfo = (knowledgeItems, people) => {
  if (!people || !people[0]) {
    return knowledgeItems;
  }
  var result = knowledgeItems.map(item => {
    const person = people.find(p => p.id === item.lockedBy?.toString());
    const personName = !!person ? person.preferredName : "";
    let displayName = formatDisplayName(personName)
    item = { ...item, lockedByName: displayName };
    return item;
  });
  return result;
};

/**
 * @desc this function is check whether a keyed object has any value or not
 * @param multiarray as array of objects
 * @returns boolean flag
 */
export const getLengthOfMultiArray = (multiarray) => {
  let length = 0;
  for (const obj in multiarray) {
    if (Array.isArray(multiarray[obj]) && multiarray[obj].length) {
      length += multiarray[obj].length;
    }
  }
  return length;
}

/**
 * @desc this function is scan param id and its objects inside the object
 * @param data as array of objects
 * @param whichId as string
 * @returns boolean flag
 */
export const checkIfNodePresentInObject = (data, whichId, responseKeyName) => {
  const { id } = responseKeyName;
  let alldata = JSON.parse(JSON.stringify(data));
  let flag = false;
  for (let eachitem in alldata) {
    if (alldata[eachitem][id] === whichId) {
      flag = true;
    }
  }
  return flag;
}

/**
 * @desc this function is to collect all nodes inside array
 * @param allObjects as array of objects
 * @returns array
 */

export const getAllNodesIntoArray = (allObjects) => {
  let newsaveadata = [];
  for (let eachchild in allObjects) {
    newsaveadata.push(allObjects[eachchild]);
  }
  return newsaveadata;
}

export const showDownloadIcon = (previewDownload, previewDownloadRestricted, isRestricted) => {
  let response = false;
  if (previewDownload) {
    response = isRestricted ? previewDownloadRestricted : true;
  }
  return response;
}

export const isPreviewDisabled = (kpItem) => {
  const {
    isURLAdded,
    attachment: {
      fileName
    }
  } = kpItem;

  if (isURLAdded === '1' || !isValidDocvizFile(fileName)) {
    // if url type kp and if not a supported docviz type  hide preview 
    return true;
  } else {
    return false;
  }
}

export const showPreviewIcon = (kpItem, previewDownload, previewDownloadRestricted, isRestricted) => {
  const {
    isURLAdded,
    fileName,
    attachment
  } = kpItem;
  const fileNamev1 = fileName ? fileName : attachment?.fileName;
  if (isURLAdded === '1') {
    // if url type kp, hide preview
    return false;
  } else if (!isValidDocvizFile(fileNamev1)) {
    // if not a supported docviz type, return false
    return false;
  } else {
    // if doc type kp, compute entitlement, hide preview based on result
    return showDownloadIcon(previewDownload, previewDownloadRestricted, isRestricted);
  }
}

/**
 * @desc returns true if all entitlement value are true/false(checkFor) else return false
 * @param {Array} entitlements: an array of entitlement object
 * @param {Boolean} checkFor: check all the entitlement value for the given Boolean - true or false
 * @returns boolean
 */
export const checkAllEntitlementValue = (entitlements, checkFor) => {
  if (typeof entitlements === 'undefined') {
    /**
     * This case is for protection when the entitlement call fails.
     * It will make sure that we have the least visibility by default/error 
     */
    return checkFor ? false : true;
  }
  return Object.prototype.toString.call(entitlements) === '[object Object]' ?
    Object.values(entitlements).every(val => val === checkFor) : false;
}

/**
 * @desc this function is to check whether content is restricted or NULL/null
 * @param content as string
 * @returns boolean flag
 */
export const isContentRestrictedOrNull = (content) => {
  const { GLOBAL: { RESTRICTED } } = LABELS;
  if (typeof content !== "undefined" && content.length && content.trim !== "" && (content.toLowerCase() === 'null' || content.trim().toLowerCase() === RESTRICTED)) {
    return true;
  }
  return false;
}

/**
 * @desc this function is to return time duration in years and months
 * @param startDate string date
 * @param endDate string date
 * @param inputDateFormat string
 * @param noEndDateValue value to return if there is no endDate
 */
export const getDuration = (startDate, endDate, inputDateFormat, noEndDateValue) => {
  if (!startDate) {
    // just checking this to prevent the exception
    return '';
  }
  else if (!endDate) {
    return noEndDateValue;
  }
  else {
    const { DURATIONS: { YEAR, YEARS, MONTH, MONTHS, DAYS, DAY } } = LABELS;
    const endDateObject = moment(endDate, inputDateFormat);
    const startDateObject = moment(startDate, inputDateFormat);
    const years = endDateObject.diff(startDateObject, 'year');
    startDateObject.add(years, 'years');
    const months = endDateObject.diff(startDateObject, 'months');
    startDateObject.add(months, 'months');
    const days = endDateObject.diff(startDateObject, 'days');

    let duration = '';
    // Setting Years
    if (years >= 10) {
      duration = `${years} ${YEARS} `;
    }
    else if (years >= 2) {
      duration = `0${years} ${YEARS} `;
    }
    else if (years === 1) {
      duration = `01 ${YEAR} `;
    }

    // Setting Months
    if (months > 9) {
      duration = `${duration}${months} ${MONTHS} `;
    }
    else if (months > 1) {
      duration = `${duration}0${months} ${MONTHS} `;
    }
    else if (months === 1) {
      duration = `${duration}0${months} ${MONTH} `;
    }

    // Setting Days
    if (days === 0) {
      return duration.trim();
    }
    else if (days === 1) {
      return `${duration}0${days} ${DAY}`
    }
    else if (days <= 9) {
      return `${duration}0${days} ${DAYS}`
    }
    else {
      return `${duration}${days} ${DAYS}`
    }
  }
}

/**
 * @desc this function is to append protocol in case the url is missing that
 * @param url as string
 * @returns url with protocol
 */
export const getURLWithProtocol = (url) => {
  if (!url || url.includes('http')) {
    return url;
  }
  return `http://${url}`;
}

//ENR-8593 Resolution and Aspect ratio is not accurate for windows 10 laptop
export const getZoomValue = () => {
  let scaleValue = 1;
  if (window.devicePixelRatio === 1.5) {
    scaleValue = 1 / window.devicePixelRatio;
    scaleValue += 0.2;
  }
  return scaleValue;
}

export const removeSpaces = (data) => {
  let filtereddata = data.trim();
  return filtereddata;
}

/**
 * @desc removes delimiter and put firstname ahead of last name with a space between
 * @param {String} name ==> lastName, preferredFirstName
 * @param {String} splitBy ==> delimiter on which the name is to split
 * @returns {String}
 */
export const getSanitizedName = (name, splitBy = ",") => {
  const { GLOBAL: { RESTRICTED } } = LABELS;
  let sanitizedName = "";
  // can come NULL when API don't return the data
  if (name && name !== "NULL" && name !== RESTRICTED) {
    const nameSplitBy = name.split(splitBy);
    if (Array.isArray(nameSplitBy) && nameSplitBy.length) {
      sanitizedName = `${nameSplitBy[1].trim()} ${nameSplitBy[0].trim()}`;
    }
  }
  return sanitizedName;
}

export const getConcatNames = (first, last) => {
  if (first && last) {
    return first + " " + last
  }
  else if (first) {
    return first;
  }
  else return;
}

/**
 * @desc generates Link Profile url and open it in new tab/window
 * @param {String} hrid ==> navigator staff is
 */
export const openLinkProfile = (hrid, self = false) => {
  const {
    API_URL: {
      LINK_PROFILE_REDIRECT
    }
  } = CONFIG;
  !!window && window.open(LINK_PROFILE_REDIRECT(hrid), !self ? '_blank' : '_self', 'noopener');
}

/**
 * @desc this function is to return number in two digits always (prefixed with 0)
 * @param number
 */
export const getNumberInTwoDigits = (number) => {
  if (number > 9) {
    return number;
  }
  return `${0}${number}`;
}

/**
 * @desc this method will scroll page to the top
 */
export const scrollToTop = (location = 0) => {
  window.scrollTo({ top: location, behavior: 'smooth' });
}

/**
 * @desc check whether a string is a number or not
 * @param {String} str ==> string to be checked
 * @returns {Boolean}
 */
export const isNumeric = (str) => {
  if (typeof str !== "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * @desc sort an array elements
 * @param {Array} target ==> array elements to be sorted
 * @param {String} key ==> if an array of objects then key should be passed on which the objects are to be sroted
 * @param {ENUM} direction ==> direction in which the array's elements are to be sorted, default DESC
 * @returns {Array} return sorted array
 */
export const sortArray = (target, key, direction) => {
  const {
    SORT: {
      ASC
    }
  } = CONFIG;
  let sortedTarget = [];
  if (Array.isArray(target) && target.length) {
    if (typeof target[0] === "object" && key) {
      sortedTarget = [...target].sort((a, b) => -1 * (a?.[key] - b?.[key]));
    }
  }
  return direction === ASC ? sortedTarget.reverse() : sortedTarget;
}


export const sortArrayAlphabetically = (target, key, direction) => {
  const {
    SORT: {
      ASC
    }
  } = CONFIG;
  let sortedTarget = [];
  if (Array.isArray(target) && target.length) {
    if (typeof target[0] === "object" && key) {
      sortedTarget = [...target].sort((a, b) => a?.[key] === b?.[key] ? 0 : a?.[key] < b?.[key] ? -1 : 1);
    }
  }
  return direction === ASC ? sortedTarget.reverse() : sortedTarget;
}

/**
 * @desc check whether an object is empty or not
 * @param {Object} obj ==> object to be checked for emptiness
 * @returns {Boolean} true if isEmpty else false
 */
export const isObjEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}

/**
 * @desc: format the date to user friendly format
 * @param: date, format
*/

export const formatDate = (date, formatOfDate, isUTC = false) => {
  const { CP_UI_DATE_FORMAT } = CONFIG;
  if (!date) {
    return '';
  }
  if (!formatOfDate) {
    formatOfDate = CP_UI_DATE_FORMAT
  }
  if (isUTC) {
    return moment.utc(date).local().format(formatOfDate);
  }
  return moment(date).format(formatOfDate);
}
/**
 * @desc: filter the region data by global ID and name
 * @param: data - array of objects
 * @returns: filtered data - array of objects 
 * */

export const filterRegionsDataByGlobal = (data, keyname) => {
  const globalGUID = CONFIG.TAXONOMY_IDS['GLOBAL_REGION'].toLowerCase();
  const newarr = data.filter(eachnode => (eachnode[keyname] !== globalGUID));
  return newarr;
}

/**
 * @desc: filter the region data by global ID and name
 * @param: data - array of objects
 * @returns: filtered data - array of objects 
 * */

export const filterInternalOfficeDataByGlobal = (data, keyname) => {
  const globalGUID = CONFIG.TAXONOMY_IDS['GLOBAL_INTERNAL_OFFICE'].toLowerCase();
  const newarr = data.filter(eachnode => (eachnode[keyname] !== globalGUID));
  return newarr;
}

/**
 * @desc: Sort array of objects based on another array
 * @param: array - array of objects, order - array order, key - array object key
 * @returns: filtered data - sorted array of objects 
 * */

export function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    let A = Number(a[key])
    let B = Number(b[key]);
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export function matchesQuery(option, keyname, query) {
  const { REGEX: { SEARCH_FILTER } } = CONFIG;
  if (option[keyname]) {
    return option[keyname].toLowerCase().indexOf(query.replace(SEARCH_FILTER, '').toLowerCase()) > -1
  }
}

export const appendDomain = (images) => {
  // append media domain for cover images
  if (!images || !images.length) {
    return images;
  }
  const { THIRDPARTY_URL: { COVER_IMAGE }, SITECORE_XM_ENABLED } = CONFIG;
  const imagesWithDomain = images.map(img => (
    { ...img, value: SITECORE_XM_ENABLED ? `${img.value}` : `${COVER_IMAGE(img.value)}` }
  ));
  return imagesWithDomain;
};

// Check if the browser is IE
export const isIE = () => {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  // IE 10 or older
  const msie = ua.indexOf('MSIE ');
  // IE 11
  const trident = ua.indexOf('Trident/');
  return msie > -1 || trident > -1;
}


// Check if url contain http | https : ftp and return link url
/**
 * @param {string} url
 */
export const checkOrAddProtocolToUrl = (url) =>
  /^((http|https|ftp):\/\/)/.test(url) ? url : `http://${url}`;

/**
 * @desc: Split array into subarrays
 * @param: array - array of objects, order
 * @param: parts - number of elements requested in subarrays
 * @returns: an array or subarrays 
 * 
 */
export const splitToChunks = (array, parts) => {
  if (parts < 1) return [array];
  let tempArray = [...array]
  return new Array(Math.ceil(tempArray.length / parts))
    .fill()
    .map(_ => tempArray.splice(0, parts))
}

// Fetch kp/kb/tc id from consumption url
/**
 * @param {string} str
 */
export const inspectIdFromURL = (str) => {
  const matches = str.match(/(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/);
  return matches ? matches[0] : "";
}

/**
 * Returns a flag indicating if the file is a supported Docviz file.
 * @param {string} filename 
 */
export const isValidDocvizFile = (filename) => {
  const extn = getFileExtension(filename);
  return CONSTANTS.DOCVIZ_EXTNS.includes(extn);
}


/**
 * @desc: Break array of objects into separate arrays based on a property
 * @param: array - array of objects
 * @param: property - object property for separate
 * @returns: an oject subarrays 
 * 
 */
export const groupBy = (arr, property) => {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

/**
 * @desc this function is to get the desired value if content is "null"/"restricted"/undefined/other
 * @param content as string
 * @param defaultValue as string
 * @returns output as string
 */
export const getContentToDisplay = (content, defaultValue = '', condition = true) => {
  const { GLOBAL: { RESTRICTED } } = LABELS;
  if (condition && content && (typeof content === 'object' || (content.toLowerCase() !== 'null' && content.trim().toLowerCase() !== RESTRICTED))) {
    return content;
  }
  return defaultValue;
}

export const isValidCaseGuid = (id) => id && id.length === 36;

/**
 * @desc function to open consumption pages
 * @param id - guid of knowledge item
 * @param type - type of knowledge item(TC, KP, KB)
 * 
 */

export const openKnowledgeConsumptionPage = (id, type) => {
  const { UI_URL: { KB_CONSUMPTION, KP_CONSUMPTION } } = CONFIG;
  let url;
  const {
    MY_NAVIGATOR: {
      ENTITY_TYPES: {
        KB,
        KP,
        TCS
      }
    }
  } = LABELS;
  if (type === KB || type === TCS) {
    url = KB_CONSUMPTION(id);
  } else if (type === KP) {
    url = KP_CONSUMPTION(id);
  }
  window.open(url, '_blank');
}

/**
 * @description copy text to Clipboard
 * @param stringToCopy  
*/


export const checkIfInList = (arr1, arr2, duplicateMessage) => {
  try {
    const set = new Set(arr1.map(item => (item.id || item.caseGuid)?.toLowerCase()));
    return arr2?.map(item => ({
      ...item,
      alreadySelectedAndSelf: {
        isAlreadySelected: set.has((item?.id || '').toLowerCase()),
        message: duplicateMessage
      }
    }));
  } catch (e) {
    console.error('failed in checkIfInList method: ', e, arr1, arr2);
  }
}

export const copyStringToClipboard = (stringToCopy) => {
  const isMobileSafari = false; // temp hardcoding till we write mobile code
  const textArea = document.createElement('textarea');
  textArea.value = stringToCopy;
  document.body.appendChild(textArea);
  textArea.style.position = 'fixed';
  textArea.style.bottom = 0;
  textArea.style.left = 0;
  let range;
  let selection;
  if (isMobileSafari) {
    textArea.contentEditable = true;
    textArea.readOnly = true;
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

/**
 * @desc function to rename keys in nested object
 * @param obj - original object
 * @param keysMap - object containing new and old key map
 * 
 */

export const renameKeys = (obj, keysMap) => {
  const keyValues = obj && Object.keys(obj).map(key => {
    let newKey = null;
    let index = Object.keys(keysMap).indexOf(key);
    index > -1 ? newKey = keysMap[key] : newKey = key
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map(obj => renameKeys(obj, keysMap));
    }
    return {
      [newKey]: obj[key]
    };
  });
  return Object.assign({}, ...keyValues);
}

/** 
 * @description method to get current window location 
*/

export const getmyCurrentLocation = () => {
  if (window) {
    return window.location.href;
  }
  return null;
}

/**
 * Returns the text from a HTML string
 * 
 * @param {html} String The html string
 */
export const stripHtml = (html) => {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

/**
 * @description trim string by charlimit but last word intac
 * @param {yourString} String 
 * @returns trimmedString
 */

export const noWordsTrimmingInString = (yourString, maxLength) => {
  let trimmedString = '';
  //trim the string to the maximum length
  trimmedString = yourString.substr(0, maxLength);
  //re-trim if we are in the middle of a word and 
  trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))

  return trimmedString;

}

/**
 * Returns categorized KP lists (vignettes and materials)
 * @param {data} Array The input array of KPs 
 */
export const getFilteredKPCategorizedList = (data) => {
  const { CP_VIGNETTES } = CONFIG;
  const materials = [], vignettes = [];
  data.forEach((item, index) => {
    const { contentType: { id } } = item;
    if (id !== CP_VIGNETTES) {
      materials.push({
        ...item,
        itemIndex: index,
      });
    }
    else {
      vignettes.push({
        ...item,
        itemIndex: index,
      });
    }
  });
  return { materials, vignettes };
}

/**
 * Validates case number search
 * @param {keyword} String
 * @returns: Specific Error Message
 */
export const validateCaseSearch = (keyword) => {
  const {
    CP_CONTRIBUTION: {
      STEP_2_FIELDS: {
        SEARCH_VALIDATION_MESSAGE
      }
    }
  } = LABELS;
  const { REGEX: { CASE_NUMBER } } = CONFIG;
  if (!keyword || keyword.length < 5 || !CASE_NUMBER.test(keyword)) {
    return SEARCH_VALIDATION_MESSAGE;
  }
  return "";
}

/**
 * Opens the url in new tab or same tab
 * @param {url} String
 * @param {newTab} boolean
 */
export const openURL = (url, newTab) => {
  if (newTab) {
    window.open(url, '_blank');
  }
  else {
    window.open(url);
  }
}

/**
 * @description - Check the existence of Batching tool parameter(isrpareview)
 * @description - Please note - this parameter is sent in Ngen url by external 3rd party tool(batching tool)
 * @param - none
 * @returns - boolean flag whether param is present or not
*/
export const getIsRpaReview = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isRpaReviewParam = searchParams.get('isrpareview');
  return isRpaReviewParam === "true";
}

/** 
 * @description - appends the query paramter to the present url
 * @param - none
 * @returns - url with isrpareview search param in url
 * 
*/
export const getQueryParamIsRpa = () => {
  const isRpaReview = getIsRpaReview();
  return isRpaReview ? '?isrpareview=true' : '';
}

export const isKPSavedinBundle = (kpid, kpidlist) => {
  if (kpid && kpidlist.length) {
    return kpidlist.indexOf(kpid) >= 0;
  }
  return false;
}

export const removeCurlyBracketFromId = (id) => {
  return id?.indexOf('{') > -1 ? id.substring(1, id.length - 1) : id
}

export const removeCurlyBrackets = (ids = []) => {
  if (Array.isArray(ids))
    return ids.map(id => removeCurlyBracketFromId(id));
  else
    return removeCurlyBracketFromId(ids)
}

export const isValidData = (content) => {
  const { GLOBAL: { RESTRICTED } } = LABELS;
  if (typeof content === "undefined" || !content.length || content.trim === "" || content.toLowerCase() === 'null' || content.trim().toLowerCase() === RESTRICTED) {
    return false
  } else {
    return true;
  }
}

export const addColonToValidData = (content) => {
  return isValidData(content) ? content + " : " : ""
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTimeStampDate = (date) => {
  if (date && date.length > 0) {
    const dateArr = date.split("-")
    return new Date(`${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`).getTime()
  }
}

export const sortArrayObjectByDate = (data, key) => {
  if (data && data.length > 0) {
    return data.sort((a, b) => {
      const dateA = getTimeStampDate(a.data[key]);
      const dateB = getTimeStampDate(b.data[key]);
      if (dateA && dateB) {
        return dateB - dateA;
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }
};

export const replaceInLabel = (originalString, searchValue, replaceWith) => {
  if (Array.isArray(replaceWith)) {
    let replacestr = originalString;
    replaceWith.forEach(current => {
      replacestr = replacestr.replace(searchValue, current)
    })
    return replacestr;
  } else {
    return originalString && originalString.replace(searchValue, replaceWith)
  }
}

export const getStartEndPagination = (size, number, total) => {
  return {
    start: size * (number - 1) + 1,
    end: size * number > total ? total : size * number,
  }
}

export const checkIfAllQueryStringsExist = (url, qsCollection) => {
  let isContain = false;
  for (var i = 0; i < qsCollection.length; i++) {
    if (url.indexOf(qsCollection[i]) !== -1) {
      isContain = true;
    }
  }
  return isContain;
}

/**
 * @description - Check both arrays contains same element
 * @param - array1, array 2
 * @returns - boolean
*/
export const findCommonElements = (arr1, arr2) => {
  return arr1.some(item => arr2.includes(item))
}

/**
 * function to find non stop words in a given string
 * @param {*} string 
 */
export const findNonStopWords = (value = '') => {
  if (typeof value == 'string') {
    const words = value.split(' ').filter((x) => x !== "");
    const nonStopWords = removeStopwords(words, eng);
    return nonStopWords;
  }
}

export const checkIsGivenValueIsAString = (value) => {
  if (typeof value === 'string')
    return true;

  return false
}

export const checkIsGivenValueIsAObj = (value) => {
  if (typeof value === 'object')
    return true;

  return false
}

export const checkIsGivenValueIsABoolean = (value) => {
  if (typeof value === 'boolean')
    return true;

  return false
}

export const checkIsGivenValueIsAArray = (value) => {
  if (value && Array.isArray(value))
    return true;

  return false
}

export const checkIsGivenValueIsPresentInObj = (value, key) => {
  if (typeof value === 'object' && value.hasOwnProperty(key)) {
    return true;
  }
  return false
}

export const getNumbersOfWords = (stringText) => {
  let words = [];
  if (stringText) {
    words = stringText.split(" ");
    words = words.filter((ele) => ele !== "");
  }
  return words.length;
}

/**
 * function to find a word in the list of obj or string
 * @param {*} string 
 * @param {*} array 
 */
export const isWordPresentInList = (word = '', list = []) => {
  try {
    let response = false;
    if (checkIsGivenValueIsAString(word) && checkIsGivenValueIsAArray(list)) {

      for (let j = 0; j < list.length; j++) {
        let termTxt = '';
        termTxt = word.toLowerCase();

        let listValue = list[j];
        if (checkIsGivenValueIsAObj(listValue) && checkIsGivenValueIsAString(listValue.name)) {
          let listName = listValue.name;
          listName = listName.split(' ');
          if (listName && listName.length > 0) {
            let findText = false;
            listName.forEach(item => {
              if (item.toLowerCase() === termTxt) {
                findText = true;
              }
              return item;
            })
            response = findText;
          }
        }

        if (response) {
          break;
        }

      }
    }
    return response;
  } catch (error) {
    console.log('error ', error)
  }
}

/**
 * Function used to return true of false for array field
 * conditions: 1. fields should be array
 * 2. validaiotn should be shown for isInternal
 * @param {*} obj 
 * @param {*} state 
 * @param {*} value 
 * @returns 
 */
export const checkForFieldSpecificValidation = (obj = {}, state = {}, value = []) => {
  try {
    const { maxLengthCheckOnTotal = false } = obj;

    if (maxLengthCheckOnTotal &&
      checkIsGivenValueIsPresentInObj(state, 'isInternal') &&
      state.isInternal &&
      checkIsGivenValueIsAArray(value) &&
      value.length < maxLengthCheckOnTotal.value) {
      return true;
    }

    return false;

  } catch (error) {
    console.log('error ', error)
  }
}

/**
 * data = array of objects
 * @param {*} data 
 * @returns 
 */
export const sortOrderForKpType = (data) => {
  try {
    const { KP_TYPE_SORTING_KEY_ORDER } = CONFIG;
    let count = Object.keys(KP_TYPE_SORTING_KEY_ORDER).length;
    if (checkIsGivenValueIsAArray(data)) {
      let isKPFoundCount = 0;
      let updatedData = data.map(item => {
        if (checkIsGivenValueIsAObj(item.term)) {
          if (KP_TYPE_SORTING_KEY_ORDER[item.term.name]) {
            isKPFoundCount++;
            item.orderKey = KP_TYPE_SORTING_KEY_ORDER[item.term.name]
          }
        }
        return item;
      })

      if (isKPFoundCount === count) {
        const sortedTarget = sortArray(updatedData, "orderKey", 'ASC');
        data = sortedTarget;
      }
    }
  } catch (error) {
    console.log('error ', error)
  }
  return data;
}


export const sortDataByKey = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key]?.toLowerCase(), B = b[key]?.toLowerCase();
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
}

/**
 * Transfering obj value to array
 * eg: obj ={a: 1, b:2, c:3}
 * output = [1,2,3]
 * @param {*} obj 
 * @returns 
 */
export const transferObjValueToArray = (obj) => {
  let tranformObjToArray = [];
  if (checkIsGivenValueIsAObj(obj)) {
    obj = copyObject(obj);
    for (let key in obj) {
      tranformObjToArray.push(obj[key])
    }
  }
  return tranformObjToArray;
}



/**
 * Function to get People object for more than 100 data
 * eg: email = ["guglani.lalit@bcg.com", "gupta.ankita@bcg.com"]
 * output = [1,2,3]
 * @param {*} emailIds array 
 * @returns 
 */
export const getPeopleApiDataByEmails = async (emailIds, peopleMethod) => {

  let peopleData = [];
  // console.log('emailIds ', emailIds);
  if (emailIds.length > 100) {
    for (let i = 0; i < emailIds.length; i += 100) {
      const emailIdsSet = emailIds.slice(i, i + 100);
      const peopleResultSet = await peopleMethod(emailIdsSet);
      peopleData = [...peopleData, ...peopleResultSet];
    }
  } else {
    peopleData = await peopleMethod(emailIds);
  }

  const sortedPeopleData = sortDataByKey(peopleData, emailIds, "workEmail");
  // console.log('emailIds ', peopleData, sortedPeopleData);
  return sortedPeopleData;
}


/**
 * Function to get People object for more than 100 data
 * eg: email = ["guglani.lalit@bcg.com", "gupta.ankita@bcg.com"]
 * output = [1,2,3]
 * @param {*} emailIds array 
 * @returns 
 */
export const getPeopleApiDataByHrids = async (ids, peopleMethod, inputByEmail = false, isBigImage = false) => {

  let peopleData = [];
  if (ids.length > 100) {
    for (let i = 0; i < ids.length; i += 100) {
      const hrIdsSet = ids.slice(i, i + 100);
      const peopleResultSet = await peopleMethod(hrIdsSet, inputByEmail, {}, isBigImage);
      peopleData = [...peopleData, ...peopleResultSet];
    }
  } else {
    peopleData = await peopleMethod(ids, inputByEmail, {}, isBigImage);
  }
  const sortedPeopleData = sortDataByKey(peopleData, ids, "id");
  return sortedPeopleData;
}

/**
 * Fucntion is used to check validation for Array of objects
 * @param {*} arrayOfObj 
 * @param {*} objKey 
 * @returns 
 */
export const checkValidationsForArrayOfObjects = (arrayOfObj, objKey) => {
  let isError = false;
  try {
    if (checkIsGivenValueIsAArray(arrayOfObj)) {
      for (let i = 0; i < arrayOfObj.length; i++) {
        const obj = arrayOfObj[i];
        if (checkIsGivenValueIsAObj(obj) && checkIsGivenValueIsPresentInObj(obj, objKey) && obj[objKey] === "") {
          isError = true;
          break;
        }
      }
    }
  } catch (error) {
    console.log('error in checkValidationsForArrayOfObjects function ', error)
  }
  return isError;
}

/**
 * Fucntion is convert string into object of prefix, postfix and image url
 * @param {*} image URL string 
 * @returns object of prefix, postfix and image url
 */

export const getImageNamePreAndPost = (imageUrl) => {
  try {
    const domainSplitStr = '00/';
    const policySplitStr = '?';
    const firstSplit = imageUrl.split(domainSplitStr);
    const domain = firstSplit[0];
    const imageName = firstSplit[1].split(policySplitStr)[0];
    const policy = imageUrl.split(policySplitStr)[1];
    return { domain: domain + domainSplitStr, imageName, policy: policySplitStr + policy };
  } catch (error) {
    console.log('error in getImageNamePreAndPost function ', error);
    return { domain: '', imageName: imageUrl, policy: '' };
  }

}

/**
 * Function used to check if selectedTaxonomyId is a part of
 * polyherarchi or not
 * @param {*} selectedTaxonomyId 
 * @returns 
 */
const checkIfIdIsPolyHierarchcal = (selectedTaxonomyId) => {
  const currentState = store?.getState()
  const NEW_POLY_HIERARCHICAL = currentState?.polyhierarchy?.polyhierarchyData?.value;
  const { POLY_HIERARCHICAL, IS_OLD_POLY_CONFIG } = CONFIG;

  let polyHierarchicalIdList = [];
  try {
    if (IS_OLD_POLY_CONFIG) {
      for (let i = 0; i < POLY_HIERARCHICAL.length; i++) {
        let hierarchicalData = POLY_HIERARCHICAL[i];
        let hierarchicalDataFound = false;
        for (let j = 0; j < hierarchicalData.length; j++) {
          let hierarchicalObj = hierarchicalData[j];
          const { id } = hierarchicalObj;
          if (id === selectedTaxonomyId) {
            polyHierarchicalIdList = JSON.parse(JSON.stringify(hierarchicalData));
            hierarchicalDataFound = true;
            break;
          }
          if (hierarchicalDataFound) {
            break;
          }
        }
      }
    } else {
      if (NEW_POLY_HIERARCHICAL?.length > 0) {
        for (let i = 0; i < NEW_POLY_HIERARCHICAL.length; i++) {
          let hierarchicalData = NEW_POLY_HIERARCHICAL[i];
          let hierarchicalDataFound = false;
          const { id, polyhierarchyterms, type } = hierarchicalData;

          if (id === selectedTaxonomyId) {
            polyHierarchicalIdList = JSON.parse(JSON.stringify([{ id, type }, ...polyhierarchyterms]));
            hierarchicalDataFound = true;
            break;
          }

          if (hierarchicalDataFound) {
            break;
          }
        }
      }
    }
  } catch (error) {
    console.log('Error in checkIfIdIsPolyHierarchcal function ', error)
  }
  return polyHierarchicalIdList
}

const findItemNested = (arr, itemId, nestingKey) => (
  arr?.reduce((a, item) => {
    if (a) return a;
    if (item.Id === itemId) return item;
    if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
  }, null)
);

/**
 * Funcito used to return obj for all guids which are present in polyherarchi
 * @param {*} polyId 
 * @param {*} taxonomyTypeData 
 * @returns 
 */
const getPolyIdDataFromTaxonomyList = (polyId, taxonomyTypeData) => {
  return findItemNested(taxonomyTypeData, polyId, "Children");
}

/**
 * Update polyhierarchi list of data so that we can add them in taxonomy data
 * @param {*} selectedTaxonomyId 
 * @param {*} listOfPolyHierarchiIDs 
 * @param {*} taxonomyTypeData 
 * @returns 
 */
const updatedPolyHierarchalList = (listOfPolyHierarchiIDs, taxonomyTypeData) => {
  let updatedPolyData = [];
  try {
    listOfPolyHierarchiIDs.map(item => {
      let obj = getPolyIdDataFromTaxonomyList(item.id, taxonomyTypeData[item.type])
      if (obj) {
        obj.type = item.type;
      }
      updatedPolyData.push(obj)
      return item;
    })
  } catch (error) {
    console.log('Error in updatedPolyHierarchalList function ', error)
  }
  return updatedPolyData;
}

/**
 * Function used to return list of polyhierarchi data
 * @param {*} selectedTaxonomyId 
 * @param {*} taxonomyTypeData 
 * @returns 
 */
export const getPolyHierarchiData = (selectedTaxonomyId, taxonomyTypeData) => {
  let obj = {
    isPolyHierarchiDataPresent: false,
    polyHierarchiIdsList: []
  }
  try {
    let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(selectedTaxonomyId);
    if (polyHierarchiResponseList.length > 0) {
      obj.isPolyHierarchiDataPresent = true;
      obj.polyHierarchiIdsList = updatedPolyHierarchalList(polyHierarchiResponseList, taxonomyTypeData);
    }
  } catch (error) {
    console.log('Error in getPolyHierarchiData function ', error)
  }
  return obj;
}

export const isCurrentAPolyGuid = (currentId) => {
  const currentState = store?.getState()
  const NEW_POLY_HIERARCHICAL = currentState?.polyhierarchy?.polyhierarchyData?.value;
  const { POLY_HIERARCHICAL, IS_OLD_POLY_CONFIG } = CONFIG;
  let isPolyGuid = false
  if (NEW_POLY_HIERARCHICAL?.length > 0) {
    let polyData = JSON.parse(JSON.stringify(IS_OLD_POLY_CONFIG ? POLY_HIERARCHICAL : NEW_POLY_HIERARCHICAL));
    if (IS_OLD_POLY_CONFIG) {
      let obj = {}
      polyData.map(polyOuterArray => {
        polyOuterArray.map(polyInnerItems => {
          const { id } = polyInnerItems;
          obj[id] = id;
          return polyInnerItems;
        })
        return polyOuterArray;
      });
      if (obj && obj[currentId]) {
        isPolyGuid = true;
      }

    } else {
      polyData.map(obj => {
        const { id } = obj;
        if (id === currentId) {
          isPolyGuid = true;
        }
      });
    }
  }

  return isPolyGuid;
}
const isElementPresentInArrayOfArray = (arrayOfArray, elemTofind) => {

  let isElementExist = false;
  arrayOfArray?.forEach((innerArray) => {
    if (innerArray && innerArray.length > 0) {
      innerArray.forEach((element) => {
        if (element?.id === elemTofind) {
          isElementExist = true;
        }
      });
    }
  });
  return isElementExist;
}

const updateCorrectPolyHIdsData = (polyIdsList) => {
  let updatedPolyIdsList = [];

  for (let i = 0; i < polyIdsList.length; i++) {
    const elemTofind = polyIdsList[i];

    if (!isElementPresentInArrayOfArray(updatedPolyIdsList, elemTofind)) {
      let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(elemTofind);
      if (polyHierarchiResponseList.length > 0) {
        updatedPolyIdsList.push(polyHierarchiResponseList);
      } else {
        updatedPolyIdsList.push([elemTofind]);
      }
    }
  }

  return updatedPolyIdsList;
}

export const polyHMaxLimitValidation = (polyIdsList, maxLimit, singleID) => {
  try {
    let updatedPolyIdsList = updateCorrectPolyHIdsData(polyIdsList);

    let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(singleID);

    if (singleID && polyHierarchiResponseList.length > 0 && updatedPolyIdsList.length <= maxLimit && isElementPresentInArrayOfArray(updatedPolyIdsList, polyHierarchiResponseList[0].id)) {
      return false;
    } else if (singleID && polyHierarchiResponseList === 0 && updatedPolyIdsList.length < maxLimit) {
      return false;
    } else if (updatedPolyIdsList.length < maxLimit) {
      return false;
    }
  } catch (error) {
    console.log('Error in polyHMaxLimitValidation function ', error)
  }

  return true;
}

export const getPolyHierarchiLength = (polyIdsList) => {
  let updatedPolyIdsList = updateCorrectPolyHIdsData(polyIdsList);
  return updatedPolyIdsList.length;
}

export const getLengthOfMultiArrayWithPoly = (practiseAreaTags) => {
  let allPracticeAreasIDsList = [];
  try {
    if (practiseAreaTags && Array.isArray(practiseAreaTags) && practiseAreaTags.length > 0) {
      let practiceAreaData = practiseAreaTags[0];

      for (let item in practiceAreaData) {
        let practiceAreaObj = practiceAreaData[item];
        practiceAreaObj.map(subItem => {
          allPracticeAreasIDsList.push(subItem.id)
          return subItem;
        })
      }
    }
  } catch (error) {
    console.log('Error in getLengthOfMultiArrayWithPoly function ', error)
  }

  return getPolyHierarchiLength(allPracticeAreasIDsList);
}

export const getHighLighterText = (searchWord, textToHighlight) => {
  let parts = [];
  try {
    let text_escaped = searchWord.replace(/([.^$|*+?()[\]{}\\-])/g, "\\$1");
    parts = textToHighlight.split(new RegExp(`(${text_escaped})`, 'gi'));
  } catch (error) {
    console.log('Error in getHighLighterText function ', error);
  }
  return parts;
}

export const getTagsInfoIconDetailsifAny = (item, infoConfig) => {
  let infoData = null;

  try {
    if (infoConfig && checkIsGivenValueIsAArray(infoConfig) && checkIsGivenValueIsAObj(item)) {
      infoConfig.map(infoItem => {
        const { infoIconKeyName, infoIconValueName } = infoItem;
        if (item[infoIconKeyName] === infoIconValueName) {
          infoData = {
            ...infoItem,
            isTagged: true
          }
        }
        return infoItem;
      })
    }
  } catch (error) {
    console.log('Error in getTagsInfoIconDetailsifAny function ', error)
  }

  return infoData;
}

export const convertArrayToObject = (arr = []) => {
  const obj = {};

  try {
    if (arr && checkIsGivenValueIsAArray(arr))
      arr.forEach((element) => {
        if (checkIsGivenValueIsAObj(element) && checkIsGivenValueIsPresentInObj(element, 'Id')) {
          obj[`${element.Id}`] = element;
        }
      });
  } catch (error) {
    console.log('Error in convertArrayToObject function ', error)
  }

  return obj;
}

//Divide tickets into two array based on given number
export const splitKpIdsArray = (arr = [], num = 0) => {

  let updatedSplitIdsObj = {
    firstArr: [],
    lastArr: []
  }
  try {
    if (arr && checkIsGivenValueIsAArray(arr) && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (i < num) {
          updatedSplitIdsObj.firstArr.push(arr[i]);
        } else {
          updatedSplitIdsObj.lastArr.push(arr[i]);
        }
      }
    }
  } catch (error) {
    console.log('Error in splitKpIdsArray function ', error)
  }

  return updatedSplitIdsObj;
}

export const isDocvizNotSupported = (kpItem, slides = []) => {
  let isPreviewDisable = isPreviewDisabled(kpItem);

  return ((checkIsGivenValueIsAArray(slides) && slides.length === 0) || isPreviewDisable) ? true : false;
}

export const getElemDistanceFromTop = (elem_id) => {
  try {
    let elem = document.querySelector(`${elem_id}`);

    // Get an element's distance from the top of the page
    let location = 0;
    if (elem?.offsetParent) {
      do {
        location += elem.offsetTop;
        elem = elem.offsetParent;
      } while (elem);
    }
    location = location >= 0 ? location - 180 : 0;

    scrollToTop(location);
  } catch (error) {
    console.log('Error in getElemDistance function ', error);
  }
}

export const convertStrToBool = (str) => {
  return str === "true" ? true : false
}

export const convertStrToNum = (str) => {
  return str ? +str : null;
}

const getPathIndex = (pathArray) => {
  let arrayIndex = null;
  try {
    const {
      TBDB_IDS: {
        FPA,
        IPA,
        BCG_INTERNAL
      }
    } = CONFIG;

    let functionalPracticeArea = {
      [FPA]: FPA,
      [IPA]: IPA,
      [BCG_INTERNAL]: BCG_INTERNAL
    };

    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i] === functionalPracticeArea[pathArray[i]]) {
        arrayIndex = i;
        break;
      }
    }
  } catch (error) {
    console.log('Error in getPathIndex function ', error);
  }

  return arrayIndex;
}

const getUpdatePath = (path) => {
  let responsePathArray = [];
  try {
    if (path) {
      let pathArray = path.split('/');

      if (pathArray && pathArray.length > 0) {
        let arrayIndex = getPathIndex(pathArray);

        if (arrayIndex) {
          for (let i = arrayIndex + 1; i < pathArray.length; i++) {
            if (pathArray[i]) {
              responsePathArray.push(pathArray[i])
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('Error in getUpdatePath function ', error)
  }

  return responsePathArray;
}

const updateItemNested = (arr, obj) => {
  try {
    if (arr.length === 0) return;

    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      const { Id, Children, Path } = item;
      if (!obj[Id]) {
        obj[Id] = getUpdatePath(Path);
      }
      if (Children && Children.length > 0) {
        updateItemNested(Children, obj);
      }
    }
  } catch (error) {
    console.log('Error in updateItemNested function ', error)
  }
}

export const createTaxonomyDataPath = (data) => {
  let updatedObj = {};

  try {
    if (checkIsGivenValueIsAArray(data)) {
      updateItemNested(data, updatedObj);
    }
  } catch (error) {
    console.log('Error in createTaxonomyDataPath function ', error);
  }
  return updatedObj;

}

export const getDefaultViewSection = (sendToLocalStorageFlag = false) => {
  //Below code is used to make grid as default view
  let kp_tc_default_view = 'grid';
  try {
    const { KEY_KB_TC_DEFAULT_VIEW } = CONFIG;
    let default_view = getFromLocalStorageAsJSON(KEY_KB_TC_DEFAULT_VIEW);
    if (default_view) {
      kp_tc_default_view = default_view;
    } else if (sendToLocalStorageFlag) {
      sendToLocalStorage(KEY_KB_TC_DEFAULT_VIEW, 'grid');
    }
  } catch (error) {
    console.log('Error in getDefaultViewSection function ', error);
  }
  return kp_tc_default_view;
}

export const getDocvizSupportedFileState = (fileObj) => {
  try {
    const { DOCVIZ_SCROLL_VIEW_EXTNS } = CONFIG;
    if (checkIsGivenValueIsAObj(fileObj)) {
      const { newDocvizDoc: docvizDoc, isMDPRestricted, isPreviewDownloadEntitlement, isPreviewDownloadREntitlement } = fileObj;
      const isEntitled = showDownloadIcon(isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, isMDPRestricted)
      const isValidDocviz = isValidDocvizFile(docvizDoc?.fileName);
      const isDocvizNotSupported = !isValidDocviz;
      let showUnsupportedPreview = isEntitled && isDocvizNotSupported;
      if (docvizDoc && docvizDoc.attachmentFileType && (DOCVIZ_SCROLL_VIEW_EXTNS.indexOf(docvizDoc.attachmentFileType) > -1) && !showUnsupportedPreview && isEntitled) {
        return true
      }
    }
  } catch (error) {
    console.log('Error in getDocvizSupportedFileState function ', error);
  }
  return false
}
export const getScroll = () => {
  let doc = document.documentElement;
  let top = doc.scrollTop;
  return top;
}

export const setScroll = (scrollY) => {
  window.scrollTo({ top: parseInt(scrollY), behavior: 'instant' });
}

export const isValidHrid = (hrid) => {
  return typeof hrid === 'number' && !Number.isNaN(hrid) && hrid > 0;
}

export const getOwnerInfo = async (kpOwner, getPeopleDataUsingIDs) => {
  const ownerInfo = await getPeopleDataUsingIDs(kpOwner, false);
  return ownerInfo;
};

export const isOwnerMdp = (ownerInfo) => {
  const { TITLES_FOR_MDP_OR_ABOVE } = CONFIG;
  return TITLES_FOR_MDP_OR_ABOVE.some(title => title === ownerInfo?.businessTitle);
};

export const conditionsApprovalMdp = (isReview, isInternal, isOwnerMdp, knowledgeType, status, isMdpApprovalRequired) => {
  const { KNOWLEDGE_STATUS: { REVIEW }, IS_MDP_APPROVED_NEWFIELD_KP } = CONFIG;
  return IS_MDP_APPROVED_NEWFIELD_KP && ((isReview && !isInternal && !isOwnerMdp && !isObjEmpty(knowledgeType)) || (status === REVIEW && !!isMdpApprovalRequired));
};