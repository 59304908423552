
import './FaceCardSlideListing.scss'
import React from 'react'
import LABELS from 'labels';
// import { v4 as uuidv4 } from 'uuid';

const FaceCardSlideListing = ({ isTitleShow, isLocationShow, isNameShow, dividedSlides, startImageUrl, endImageUrl }) => {
    const { FACE_CARD_SLIDE } = LABELS.FACE_CARD_GENERATOR;
    return (
        dividedSlides.map((slide, index) => {
            return (
                <div className='faceCardContainer' key={"main" + slide[index]?.hrid}>
                    {index === 0 && <p className='faceCardContainer__title'>{FACE_CARD_SLIDE}</p>}
                    <div className='faceCardContainer__slide'>
                        {slide?.length > 0 && slide.map((personData) => {
                            const { name, title, image, location, hrid } = personData;
                            return (
                                <div className='faceCardContainer__slide__item' key={hrid}>
                                    <img src={startImageUrl + image + endImageUrl} className='faceCardContainer__slide__item__image' />
                                    {isNameShow && <p className='faceCardContainer__slide__item__name'>{name}</p>}
                                    {isTitleShow && <p className='faceCardContainer__slide__item__title'>{title}</p>}
                                    {isLocationShow && <p className='faceCardContainer__slide__item__location'>{location}</p>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        })

    )
}

export default FaceCardSlideListing