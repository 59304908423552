// PRIMARY CATEGORY AS NEEDED BY ANALYTICS
const PRIMARY_CATEGORY = {
  KB: "Knowledge_Bundle_Creation",
  KB_CONSUMPTION: "Knowledge_Bundle",
  KP: "Knowledge_Page_Creation",
  KP_CONSUMPTION: "Knowledge_Page",
  MY_NAVIGATOR: "MyNavigator",
  CREATION: "Creation_Options",
  CASE_PAGE: "Case_Page",
  CASE_PAGE_CREATION: "Case_Page_Creation",
  ENTITLEMENT_ERROR_PAGE: "Entitlement_error_page",
  FACE_CARD_GENERATOR: "Face Card Generator"
}; // to be send in page.category.primaryCategory, right now it is same for all
const DISPLAY_MODE = {
  DOCVIZ_OVERLAY: "docviz overlay"
}
const DTMRULE = {
  KB: "collection_creation",
  KP: "Knowledge_Page_Creation",
  CREATION: "Creation_Options",
  MY_CREATION_ACTIONS: "My_Creation_Actions",
  DISPLAY_OPTION_SELECTION: "display_option_selected",
  RESULTS_SHOW: "results_show",
  INTERNAL_SEARCH: "internal_search",
  KNOWLEDGE_PAGE_ACTION: "Knowledge_Page_Action",
  // KNOWLEDGE_BUNDLE_ACTION: "Knowledge_Bundle_Action",
  CASE_PAGE_ACTIONS: "Case_Page_Actions",
  VIEW_AUTHOR_PROFILE: "view_profile_result_expanded_view",// event 26
  AUTHOR_EMAIL_INTERACTION: "author_email_interaction",// event 70
  VIEW_MATERIAL_PAGE: "view_material_page", // event24
  PREVIEW_INTERACTIONS: "preview_interactions", // event15
  DOWNLOAD_INTERACTIONS: "download_interactions", // event14
  DOCVIZ_OVERLAY_OPEN: "docVis_Overlay_Open", // event81
  VISIT_URL_INTERACTIONS: "visit_url_interactions",// event 35
  TAB_CLICK: "Tab_Click",
  TAB_CLICKS: "Tab_Clicks", //event49
  RELATED_CASE_CLICK: "Case_Page_Actions",
  SEARCH_WITHIN_FILTER: "search_within_filter",
  FILTER_TERM_CLICKED: "filterTermClicked",
  EVENT55_COPY_LINK: "copy_details", //event55,
  EVENT16_SHAREVIAEMAIL: "email_interactions", //event16
  CP_CREATE: "CP_Create",
  SORT_ORDER_SELECTION: "sort_order_selected", //event161
  CLONE_BUTTON_CLICK: "SS_Clone_Button_Clicks",
  CLONE_CANCEL_BUTTON_CLICK: "SS_Cancel_Button_Clicks",
  RESULT_EXPLICIT_FEEDBACK: "result_explicit_feedback", // event 116
  DOCVIZ_OVERLAY_CLOSES: "docVis_Overlay_Close", // event 210
  DOCVIZ_SLIDE_UP_ARROW: "slide_keyword_find_sign_up", // event 75
  DOCVIZ_SLIDE_DOWN_ARROW: "slide_keyword_find_sign_down", // event 76
  DOCVIZ_SLIDE_SEARCH: "search_within_docViz", // event 76
  DOCVIZ_PAGE_LOAD_FAIL: "Docviz_Page_Load_Fail", // event 268
  DOCVIZ_FRAMEWORK_CLICK: "Framework_Click", // event 91
  KP_PAGE_SUGGESTION_TAXONOMY_CLICK: "material_page_Taxonomy_click", //event 
  KB_TOGGLE_VIEW: "Toggle_View", //event 79
  CLIPPING_KP: "docVis_download_slides" //event 39
}
const TRIGGERS = {
  NEW_COLLECTION: "New Bundle",
  NEXT: "Next",
  DELETE: "Delete",
  REARRANGE: "Rearrange",
  SELECT_ITEM: "Select Item",
  PUBLISH: "Publish",
  SEND_FOR_REVIEW: "Send for Review",
  BACK: "Back",
  PREVIEW_BUNDLE: "Preview Bundle",
  HOME: "Home",
  SUBMIT: "Submit",
  REMOVE_SELECTION: "Remove Selection",
  TOPIC_SUBJECT_SELECTION: "Topic Subject Selection",
  BUTTON_PA_TOPIC: "Btn Display PA/TopicPage",
  BUTTON_IPA_FPA: "BtnIPA_FPA",
  BUTTON_PA_SUBJECT: "BtnSubject",
  HELPMODAL: "HELP_KP",
  KP_SEARCH_ON_KB: "KP_Search on KB",
  KP_SEARCH_REGIONS: "KP_Region / Country",
  NO_TYPEAHEAD: "no typeahead",
  TYPEAHEAD_CREATOR: "Typeahead_Creator",
  TYPEAHEAD_CONTRIBUTORS: "Typeahead_Contributers",
  TYPEAHEAD_CREATEDBY: "Typeahead_CreatedBy",
  TYPEAHEAD_KP_AUTHOR: "Typeahead_KP_Authors",
  TYPEAHEAD_SUBJECT_SELECTION: "Typeahead_Subject Selection",
  AUTHOR_CARD: "Authors",
  CURATOR_CARD: "Curator",
  ADDITIONAL_CONTACT_CARD: "Additional Contacts",
  ADDITIONAL_CONTRIBUTORS_CARD: "Additional Contributors",
  ORIGINAL_REQUESTOR_CARD: "Original Requestor",
  TYPEAHEAD_KP_MDPNAME: "Typeahead_KP_MDP Name",
  TYPEAHEAD_BCG_OFFICE: "Typeahead_BCG_Office",
  REPLACE_FILE: "Replace File",
  UPLOAD_FILE: "Upload File",
  ADDITIONAL_CONTACT: "Typeahead_Additional Contacts",
  KP_EDIT: "KP_Edit",
  KP_UNPUBLISH: "KP_Unpublish",
  KP_PREVIEW: "KP_Preview",
  CREATION: {
    CREATE_KP_BTN: "Create_KP",
    CREATE_KB_BTN: "Create_KB",
    CREATE_TPKB_BTN: "Create_Bundle_PATopic",
    CREATE_EDITCASE_BTN: "Edit_Case_Page"
  },
  CONTRIBUTE_KNOWLEDGE: "Contribute Knowledge",
  EDIT: "Edit",
  PREVIEW: "Preview",
  DOWNLOADS: "Downloads",
  ARCHIVE: "Archive",
  MY_CONTRIBUTION: "MyContribution",
  CP_KN_LEGACY: "CP_KNLegacy",
  CP_KNOWLEDGE_ASSETS: "CP_KnowledgeAssets",
  CP_KNOWLEDGE_ASSETS_INACTIVE: "CP_KnowledgeAssets_Inactive",
  CP_KNOWLEDGE_ASSETS_ACTIVE: "CP_KnowledgeAssets_Active",
  CP_RELATED_VIEW: "CP_RelatedCPView",
  CP_RELATED_CASE_CLICK: "CP_Related Case Click",
  CP_RELATED_CASE: "CP_Related Case",
  CP_DESCRIPTION_SHOW_MORE: 'CP_DescriptionShowMore',
  CP_DESCRIPTION_SHOW_LESS: 'CP_DescriptionShowLess',
  CP_PRICING: 'CP_Pricing',
  CP_EDIT: 'CP_Edit',
  CP_SEARCH_AND_DISCOVERY_TAGS: "CP_Search and Discovery Tags",
  CP_ASSOCIATED_PA_TOPIC_SECTOR: "CP_Associated PA/Topic/Sector",
  CP_CONTACTS: {
    CP_PRIMARY_CONTACT: "CP_PrimaryContact",
    CP_ALUMNI: "CP_Alumni",
    CP_ADDITIONAL_TEAM: "CP_AdditionalTeamMember",
    CP_CCO: "CP_CCO",
    CP_KMS_OWNER: "CP_KMS Owner",
    CP_BILLING_PARTNER: "CP_BillingPartner",
    CP_VIEW_OTHER_CONTACTS: "CP_ViewOtherContacts",
    CP_CASE_TEAM_MEMBER: "CP_CaseTeamMember"
  },
  KB_CONSUMPTION: {
    KB_KP_VIEW_PAGE: "KB_KP_View Page",
    KB_KP_PREVIEW: "KB_KP_Preview",
    KB_KP_DOWNLOAD: "KB_KP_Download",
    KB_PA_RECOMMENDED: "KB_PA Recommended",
    KB_PA_RECOMMENDED_SAVE: "KB_PA Recommended_Save"
  },
  KP_CONSUMPTION: {
    KP_DOWNLOAD: "KP_Download",
    KP_ASSOCIATED_BUNDLE: "KP_Associated Bundle",
    KP_PREVIEW: "KP_Preview",
    KP_PA_RECOMMENDED: "KP_PA Recommended",
    KP_PA_RECOMMENDED_SAVE: "KP_PA Recommended_Save"
  },
  CP_CONSUMPTION: {
    CP_KP_VIEW_PAGE: "CP_KP_View Page",
    CP_KP_PREVIEW: "CP_KP_Preview",
    CP_KP_DOWNLOAD: "CP_KP_Download",
    CP_KP_CASE_MATERIAL: "CP_KP_Case Material",
    CP_KP_CASE_VIGNETTE: "CP_KP_Case Vignette",
  },
  ADDITIONAL_URLS: "Additional URLs",
  UNPUBLISH: "Unpublish",
  MOVE_TO_DRAFT: "Move to Draft",
  MY_KN_KP_VIEW_PAGE: "MyKN_KP View Page",
  MY_KN_KB_VIEW_PAGE: "MyKN_KB View Page",
  SUPP_URLS: "Supporting URLS",
  KNOWL_PAGES: "Knowledge Pages",
  MY_NAVIGATOR: {
    TAB_ACTIVITIES: "Tab_Activities",
    TAB_CONTRIBUTIONS: "Tab_Contributions",
    TAB_MYCASES: "Tab_Mycases"
  },
  CP_CREATION: {
    CASES_KP_DOWNLOAD: "Cases_KP_Download",
    TAB_CASE_OVERVIEW: "Tab_Case Overview",
    TAB_CASE_INSIGHTS: "Tab_Case Insights",
    TAB_CASE_TEAM: "Tab_Case Team",
    TAB_CASE_KNOWLEDGEASSETS: "Tab_KnowledgeAssets",
  }
};
const SCREEN_NAMES = {
  PUBLISH: "Publish",
  ADDMETADATA: "Describe Bundle",
  ADDITEMS_KP: "Select Items_Knowledge Pages",
  ADDITEMS_SL: "Select Items_Supporting URLS",
  KNOWL_PAGES: "Knowledge Pages",
  KB_SUBJECT_MODAL: "KB_Subject_Tagging_Modal",
  KB_BST_MODAL: "KB_BST_Tagging_Modal",
  KB_Topic_Modal: "KB_Topic_Tagging_Modal",
  KP_STEP_1: "KP_Upload&Describe",
  KP_STEP_2: "KP_Case&Authors",
  KP_STEP_3: "KP_SelectTags",
  EDIT_KP_STEP_1: "KP_Upload&Describe",
  CREATION: "Creation_Options",
  KP_BST_TAGGING_MODAL: "KP_BST_Tagging_Modal",
  KP_TOPIC_MODAL: "KP_Topic_Tagging_Modal",
  MY_CONTRIBUTIONS: "My_Contributions_Home",
  MY_CONTRIBUTIONS_ACTIVITIES: "My_Contributions_Activities",
  SELECT_ITEMS_KP: "Select Items_KnowledgePages",
  KB: "KB",
  KP: "KP",
  SELECT_ITEM: "Select Item",
  SELECT_TAGS: "Select Tags",
  KP_CONSUMPTION: "Knowledge_Page",
  CASE_OVERVIEW: "Case Overview",
  CASE_INSIGHTS: "Case Insights",
  CASE_TEAM: "Case Team",
  KNOWLEDGE_ASSETS: "Knowledge Assets",
  RECOMMENDING_TAGGING_SUBJECT_TAGS: "search and discovery tags - recommended tagging",
  REPLACE_KP_STEP_1: "File Supported",
  REPLACE_UNSUP_KP_STEP_1: "File Unsupported"
};
const PAGES = {
  KB_CONSUMPTION: "Knowledge_Bundle",
  KB_CREATION: "Knowledge_Bundle_Creation",
  KP_CREATION: "Knowledge_Page_Creation",
  KP_CONSUMPTION: "Knowledge_Page",
  ADDITEMS: "Select Items",
  KP_PAGES: "Knowledge_Page_Creation",
  CREATION: "Creation_Options",
  MY_NAVIGATOR: "My_Navigator",
  MY_NAVIGATOR_INIT: "My_Contributions_Home",
  CASE_PAGE: "Case_Page",
  CP_CREATION: "case_page_creation",
  CASE_PAGE_CREATION: "Case_Page_Creation",
  ENTITLEMENT_ERROR: "Entitlement error",
  FACE_CARD_GENERATOR: "Face_Card_Generator",
  KP_PREVIEW: 'knowledge_page_creation_preview',
};
const TOOLNAME = {
  CONTRIBUTE_KNOWLEDGE: "Ngen knowledge app",
  CASE_PAGE: "Case page"
};

const NOT_AVAILABLE = "Not available"

const MATERIAL_STATUS = {
  PA_RECOMMENDED: "Recommended",
  NOT_PA_RECOMMENDED: "Not Recommended",
};

const COPY_COMPONENT = {
  KB_LINK: "knowledge bundle link",
  KP_LINK: "knowledge page link"
};

export { SCREEN_NAMES, TRIGGERS, PRIMARY_CATEGORY, PAGES, DTMRULE, NOT_AVAILABLE, TOOLNAME, MATERIAL_STATUS, COPY_COMPONENT, DISPLAY_MODE };
