// React
import React from "react";
// PropTypes
import PropTypes from "prop-types";

// Semantic UI
import { Button } from "semantic-ui-react";

import { CustomButton } from "components/shared/CustomButton";
import { Tooltip } from "components/shared/Tooltip";

// Css
import "./FooterWorkflow.scss";

/**
 * @desc create a dynamic step progress bar
 * @param {Array} stepper
 * @returns html
 */
const FooterWorkflow = ({ buttons, getEvent, tooltipHtml, isButtonAtTop = false }) => {
  const footerTop = isButtonAtTop ? 'footer-top' : '';
  const getVisibleButtonsCount = (buttons) => {
    let count = 0;
    buttons.forEach(btn => {
      if (btn?.children && btn?.children?.length) {
        btn.children.forEach(btnchild => {
          if (btnchild?.visible) {
            count++;
          }
        });
      }
      else {
        if (btn?.visible) {
          count++;
        }
      }
    });

    return count;
  };

  const getFlexClass = () => {
    let classname = '';
    if (getVisibleButtonsCount(buttons) === 1) {
      if (buttons[0].visible) {
        classname = buttons[0].align.toUpperCase().trim() === "RIGHT" ? "flex-end" : "flex-start";
      } else if (buttons[1].visible) {
        classname = buttons[1].align.toUpperCase().trim() === "RIGHT" ? "flex-end" : "flex-start";
      }
    } else {
      classname = "space-between";
    }
    return classname;
  }
  return (
    <div
      className={`footer-workflow btns-count-is-${getVisibleButtonsCount(buttons)} ${footerTop}`}
      style={{ justifyContent: getFlexClass() }}
    >
      {buttons &&
        buttons.map((btn, ind) => (
          <React.Fragment key={`btn-${ind}`}>
            {btn?.children?.length > 0 && getVisibleButtonsCount(btn?.children) > 0 ? (
              <Button.Group>
                {btn.children.map((b, i) => (
                  <React.Fragment key={`btn-child-${i}`}>
                    {b?.visible && (
                      <>
                        {b.disabled && b.tooltip ? (
                          <Tooltip 
                          id="footer-tooltip" 
                          position="left"
                          className="publish-btn"
                          icon={
                          <CustomButton btn={b} getEvent={getEvent} />}
                           >
                          <span dangerouslySetInnerHTML={{ __html: b?.tooltip?.props?.children }} />
                          </Tooltip>
                        ) : (
                          <CustomButton btn={b} getEvent={getEvent} />
                        )}
                      </>
                    )}                  
                    </React.Fragment>
                    
                ))}
                {tooltipHtml && (
                  <Tooltip id="footer-tooltip" position="top">{tooltipHtml}</Tooltip>
                )}
              </Button.Group>
            ) : (
              btn?.visible && <CustomButton btn={btn} getEvent={getEvent} />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

// PropTypes
FooterWorkflow.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      align: PropTypes.string,
      icon: PropTypes.string,
      iconAlign: PropTypes.oneOf(["pre", "post"]),
      text: PropTypes.string,
      link: PropTypes.string,
      classname: PropTypes.string,
      target: PropTypes.bool,
      disabled: PropTypes.bool,
      visible: PropTypes.bool,
      outFilled: PropTypes.bool,
      isClick: PropTypes.bool,
      tooltip: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          order: PropTypes.number,
          type: PropTypes.string,
          align: PropTypes.string,
          icon: PropTypes.string,
          iconAlign: PropTypes.oneOf(["pre", "post"]),
          text: PropTypes.string,
          classname: PropTypes.string,
          link: PropTypes.string,
          target: PropTypes.bool,
          disabled: PropTypes.bool,
          visible: PropTypes.bool,
          outFilled: PropTypes.bool,
          isClick: PropTypes.bool,
          tooltip: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  getEvent: PropTypes.func,
};
// export default workflow
export default FooterWorkflow;
