import React from 'react';
import LABELS from 'labels';
import './FcgTemplate.scss';
import FcgGridTemplate from 'components/shared/FcgGridTemplate/FcgGridTemplate';

const {
    FACE_CARD_GENERATOR: { TEMPLATE_MESSAGE, TEMPLATE_MESSAGE_2 }
} = LABELS;

const templates = [
    { id: 1, rows: 2, columns: 5, shape: 'circle', label: 'Circle 2X5' },
    { id: 2, rows: 2, columns: 5, shape: 'square', label: 'Square 2X5' },
    // { id: 2, rows: 6, columns: 8, shape: 'square', label: 'Square 2X5' }
];


class FcgTemplate extends React.PureComponent {
    constructor(props) {
        super();
    }
    render() {
        return (
            <div className='faceCardTemplate__mainContainer'>
                <div className="faceCardTemplate__templateContainer">
                    <div className='faceCardTemplate__templateMessage'>{TEMPLATE_MESSAGE}</div>
                    <div className='faceCardTemplate__gridContainer'>
                        <p>{TEMPLATE_MESSAGE_2}</p>
                        <div className="faceCardTemplate__templatesGrid">
                            {templates.map((template) => {
                                const { id, rows, columns, label, shape } = template;
                                return (
                                    <div key={id} className={`faceCardTemplate__templateSection  ${rows === 2 ? 'faceCardTemplate__templateSection--two-rows' : ''}`}
                                        onClick={() => this.props.onTemplateClick(template.id, template.label)}
                                    >
                                        <div className="faceCardTemplate__gridBox">
                                            <FcgGridTemplate rows={rows} columns={columns} shape={shape} />
                                        </div>
                                        <h5 className='faceCardTemplate__templateLabel'>{label}</h5>
                                    </div>
                                )
                            }
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FcgTemplate;
