import { put, call, takeLatest } from 'redux-saga/effects';
// Constant
import { FACE_CARD_GENERATOR } from "redux/constants";

// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { faceCardSlideSuccess, faceCardSlideFailure, faceCardSlidePending } from "./faceCardGenerator.actions";


export function* fetchFaceCardData(action) {
    try {
        yield put(faceCardSlidePending());
        const { API_URL: { FACE_CARD_GENERATION }, XA } = CONFIG;
        const { payload } = action;
        const axiosConfig = {
            method: 'POST',
            endpoint: FACE_CARD_GENERATION(),
            params: payload,
            headers: {
                'x-api-key': XA
            }
        };
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response && response?.preSignedUrl) {
            yield put(faceCardSlideSuccess(response || []));
        } else {
            yield put(faceCardSlideFailure({ message: "Presigned URL missing" }));
        }
    } catch (error) {
        console.log('error in saga', error)
        yield put(faceCardSlideFailure(error));
    }
};

export default function* faceCardGeneratorSaga() {
    try {
        yield takeLatest(FACE_CARD_GENERATOR.GET_FACECARD_SLIDE_DATA, fetchFaceCardData);
    } catch (error) {
        yield put(faceCardSlideFailure(error));

    }
}
