import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Slider from "react-slick";
import { CustomArrow } from "./CustomArrow";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss"
import LOCAL_CONFIG from "components/HelpModal/HelpModal.config";

class Carousel extends PureComponent {
  onNext = () => {
    this.slider.slickNext();
  };
  onPrev = () => {
    this.slider.slickPrev();
  };
  render() {
    const { config, hideButtonText } = this.props;
    const settings = {
      dots: true,
      infinite: false,
      speed: 700,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      lazyLoad: true,
      nextArrow: <CustomArrow btnText={!hideButtonText ? LOCAL_CONFIG.btnNext.text : ""} showBtn={false} />,
      prevArrow: <CustomArrow btnText={!hideButtonText ? LOCAL_CONFIG.btnPrev.text : ""} showBtn={true} />
    };

    return (
      <Slider ref={(c) => (this.slider = c)} {...settings} {...config}>
        {this.props.children}
      </Slider>
    );
  }
}
Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carousel;
