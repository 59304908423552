import React from 'react';
import { Pill } from 'components/shared/Pill';
import moment from 'moment';
import './ConsumptionHeader.scss';
import { Icon } from 'semantic-ui-react';
import LABELS from 'labels';
import { getUtcToLocalDate, getmyCurrentLocation } from 'utils/helpers/helpers';
import { ConsumptionOptions } from 'components/shared/ConsumptionOptions/ConsumptionOptions';
import { ConsumptionShare } from "components/ConsumptionShare";
import { Tooltip } from "components/shared/Tooltip";
import { ThumbsUpDown } from 'components/ThumbsUpDown';
import CONFIG from 'config';

export class ConsumptionHeader extends React.PureComponent {
  getUpdatedLabel(name) {
    const {
      KPCONSUMPTIONGROUP: {
        HEADER_AUDIT,
        HEADER_AUDIT_BUNDLE,
        HEADER_AUDIT_COLLECTION,
      },
      MY_NAVIGATOR: {
        ENTITY_TYPES: { TC, KB }
      }
    } = LABELS;
    if (name === TC) {
      return HEADER_AUDIT_COLLECTION;
    }
    if (name === KB) {
      return HEADER_AUDIT_BUNDLE;
    }
    return HEADER_AUDIT;
  }

  componentDidMount() {
    const {
      consumptionMetaData: { id, attachment },
      consumptionData: { isBundle, isCollection },
      getDownloadsCount,
    } = this.props;
    if (!isBundle
      && !isCollection
      && !!getDownloadsCount
      && attachment?.fileName
    ) {
      getDownloadsCount(id);
    }
  }

  render() {
    const {
      consumptionData: { name, title, updatedDate, isBundle, isCollection, links, KPs },
      consumptionMetaData,
      consumptionMetaData: { documentReplacementDate },
      paRecommendedCount,
      editIconEntitlement,
      publishEntitlement,
      isContinueEditVisible,
      isSiteAdmin,
      hideEllipsis,
      hideShare,
      downloads,
      getConsumptionData,
      isContributeKnowlegeVisible,
      isMyNavigatorVisible,
      isArchiveVisible,
      isEditVisible,
      isDeleteVisible,
      isDraftVisible,
      totalKPs,
      emailBody,
      copyURLAnalytics,
      shareviaEmailAnalytics,
      showThumbsUpDown,
      showAssociatedOffices,
      isRpaReview,
      finalSuccess,
      internalOffice,
    } = this.props;

    const {
      KPCONSUMPTIONGROUP: {
        PA_RECOMMENDED,
        KNOWLEDGE_PAGES,
        LINKS,
        DOWNLOADS,
        DOCUMENT_AUDIT,
      },
      BUNDLE_TYPE: { TOPIC_COLLECTION },
      MY_NAVIGATOR: { ENTITY_TYPES: { TC } },
      THUMBSUPDOWN: {
        HEADING_KP
      }
    } = LABELS;
    const { CP_UI_DATE_FORMAT, TAXONOMY_IDS: { GLOBAL_INTERNAL_OFFICE } } = CONFIG;
    const { ALT_TEXTS: { MORE_OPTIONS } } = LABELS;
    const formattedDate = moment(getUtcToLocalDate(updatedDate)).format(
      CP_UI_DATE_FORMAT
    );
    const formattedDocumentDate = !!documentReplacementDate && moment(getUtcToLocalDate(documentReplacementDate)).format(
      CP_UI_DATE_FORMAT
    );
    const modifiedName = name === TOPIC_COLLECTION ? TC : name;
    return (
      <section className="consumptionheader layout__fix--inner margin_bottom--30">
        {!!modifiedName &&
          <div className="csHeader">
            <Pill
              defaultText={modifiedName}
              active
              className="csHeader__case-text"
            />
          </div>
        }
        <div className="csHeader__header-row">
          <label className="csHeader__header">{title}</label>
          <div className="csHeader__images">
            {!hideShare &&
              <div class="shareWrapper">
                <ConsumptionShare
                  className="csHeader__images--share"
                  emailSubject={title}
                  copyURL={getmyCurrentLocation()}
                  emailBody={emailBody}
                  copyURLAnalytics={copyURLAnalytics}
                  paRecommendedCount={paRecommendedCount}
                  shareviaEmailAnalytics={shareviaEmailAnalytics}
                />
              </div>
            }

            {!hideEllipsis &&
              <ConsumptionOptions
                entity={consumptionMetaData}
                isBundle={isBundle}
                isCollection={isCollection}
                editIconEntitlement={editIconEntitlement}
                isSiteAdmin={isSiteAdmin}
                title={MORE_OPTIONS}
                getConsumptionData={getConsumptionData}
                publishEntitlement={publishEntitlement}
                isContinueEditVisible={isContinueEditVisible}
                downloads={downloads}
                entityName={modifiedName}
                isContributeKnowlegeVisible={isContributeKnowlegeVisible}
                isMyNavigatorVisible={isMyNavigatorVisible}
                isArchiveVisible={isArchiveVisible}
                isEditVisible={isEditVisible}
                isDeleteVisible={isDeleteVisible}
                isDraftVisible={isDraftVisible}
                isRpaReview={isRpaReview}
              />
            }
          </div>
        </div>
        <div className="consumption__smalldescription">
          {
            paRecommendedCount ?
              <>
                <Icon className="csHeader__starimg" name='star' />
                <label className="csHeader__downloads">{PA_RECOMMENDED}</label>
                <span className="csHeader__count">{paRecommendedCount}</span>
                {(KPs || downloads || links) && <span className="csHeader__divider" />}
              </>
              : null
          }
          {isBundle &&
            <>
              {
                KPs ?
                  <>
                    <label className="csHeader__downloads">{KNOWLEDGE_PAGES}</label>
                    <span className="csHeader__count">{totalKPs}</span>
                  </>
                  :
                  null
              }
              {
                (KPs && links) ? <span className="csHeader__divider" /> : null
              }
              {
                links ?
                  <>
                    <label className="csHeader__downloads">{LINKS}</label>
                    <span className="csHeader__count">{links}</span>
                  </>
                  :
                  null
              }
            </>
          }
          {!!downloads &&
            <>
              <label className="csHeader__downloads">
                {DOWNLOADS}
              </label>
              <span className="csHeader__count">{downloads}</span>
            </>
          }
          {!!showAssociatedOffices &&
            <>
              <span className="csHeader__divider csHeader__divider--thumbs" />
              <span className="csHeader__downloads">{"Associated Offices"}</span>
              {internalOffice?.length && internalOffice[0].id === GLOBAL_INTERNAL_OFFICE ?
                <span className="csHeader__count">{"Global"}</span>
                :
                <span className="csHeader__count">
                  <Tooltip
                    id={"internal-office"}
                    className="csHeader__tooltipInternal"
                    position="bottom"
                    delayHide={100}
                    icon={
                      <span className='csHeader__countInternalOffice'>{internalOffice?.length}</span>
                    }
                    addScrollbar
                  >
                    <p className="csHeader__internalofficeContent">{internalOffice.map((item) => item.name).join(",  ")}</p>
                  </Tooltip>

                </span>
              }
            </>
          }
          {!!showThumbsUpDown &&
            <>
              <span className="csHeader__divider csHeader__divider--thumbs" />
              <span className="csHeader__downloads">{HEADING_KP}</span>
              <span className="csHeader__thumbs">
                <ThumbsUpDown
                  finalSuccess={finalSuccess} />
              </span>
            </>
          }
          {!!updatedDate && (
            <p className="csHeader__audit">
              {`${this.getUpdatedLabel(modifiedName)}${formattedDate}`}
            </p>
          )}
        </div>
        {
          !isBundle && !!documentReplacementDate && (
            <p className="csHeader__audit">
              {`${DOCUMENT_AUDIT}${formattedDocumentDate}`}
            </p>
          )
        }
      </section >
    );
  }
}