const CONFIG = {
    footerWorkflow: [
        {
            order: 1,
            id: "btn-preview",
            text: "PREVIEW",
            classname: "previewBtn",
            disabled: false,
            visible: true,
            isClick: true,
        },
        {
            type: "button",
            order: 2,
            id: "btn-download",
            text: "DOWNLOAD",
            classname: "downloadBtn",
            disabled: true,
            visible: true,
            isClick: true,
        }
    ]
};

export default CONFIG;