import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import OutsideClickHandler from 'react-outside-click-handler';
import Image from "components/shared/Image";
import SearchIcon from "assets/images/Enter/enter-arrow.svg";
import warningIcon from "assets/images/warning/warning.svg";
import './EmailInput.scss';

const EmailInput = ({ onSubmit, placeholder, prefillEmails, isLoading }) => {
  const [emailIds, setEmailIds] = useState('');
  const [error, setError] = useState('');
  const [invalidEmails, setInvalidEmails] = useState([]);

  const handleChange = (event) => {
    setEmailIds(event.target.value);
    setError('');
  };

  useEffect(() => {
    if (prefillEmails.length === 0) {
      setEmailIds('');
      setError('');
    } else {
      const finalList = [...prefillEmails, ...invalidEmails];
      if (finalList.length) {
        // console.log("finalList", finalList);
        setEmailIds(finalList.join("; "));
        setError('Unable to find ' + finalList.length + ' email ID' + (finalList.length > 1 ? 's' : ''));
      }
    }
  }, [prefillEmails, isLoading]);

  const extractEmails = text => text.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g) || [];
  const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/.test(email);

  const handleEnter = () => {
    const extractedEmails = extractEmails(emailIds) || [];
    const validEmails = [];
    const invalidEmailsList = [];
    const emailSemicolons = emailIds.includes(';');

    // Separate valid and invalid emails
    extractedEmails.forEach(email => {
      email = email?.toLowerCase();
      if (isValidEmail(email)) {
        validEmails.push(email);
      } else {
        invalidEmailsList.push(email);
      }
    });

    if (invalidEmailsList.length) {
      setInvalidEmails(invalidEmailsList);
    }
    if (validEmails.length > 0) {
      if (validEmails.length > 1 && !emailSemicolons && emailIds.indexOf(' ') === -1) {
        setError('Please enter valid email IDs separated by semi-colon');
      } else {
        onSubmit(validEmails);
        setEmailIds('');
        setError('');
      }
    }
    else {
      setError('Please enter valid email IDs separated by semi-colon');
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleEnter();
    }
  };
  const handleOusideClick = () => {
    setError('');
    setInvalidEmails([]);
  }

  return (
    <div className="emailInputWrapper">
      <OutsideClickHandler onOutsideClick={handleOusideClick}>
        <Form className='emailInputWrapper__emailInput' onSubmit={(e) => { e.preventDefault(); }}>
          <Form.Group>
            <Form.Field className='emailInputWrapper__field'>
              <div className={`emailInputWrapper__input ${error ? "emailInputWrapper__input--error" : ""}`}>
                <Form.Input
                  type="text"
                  value={emailIds}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder={placeholder}
                  icon={<span className='emailInputWrapper__searchIcon' onClick={handleEnter}>
                    <Image
                      src={SearchIcon}
                      className="emailInputWrapper__input__searchIcon"
                    />
                  </span>
                  }
                />

              </div>
              {error &&
                <div className="emailInputWrapper__error">
                  <p>
                    <Image src={warningIcon}
                      className='email__warningIcon'
                    />
                    {error}
                  </p>
                </div>}
            </Form.Field>
          </Form.Group>
        </Form>
      </OutsideClickHandler>
    </div>
  );
};

EmailInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  prefillEmails: PropTypes.array
};

EmailInput.defaultProps = {
  placeholder: '',
  isLoading: false,
  prefillEmails: []
};

export default EmailInput;
