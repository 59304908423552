import { Card, Icon } from 'semantic-ui-react';
import React, { PureComponent } from 'react';
import { openLinkProfile, isContentRestrictedOrNull } from "utils/helpers/helpers";
import './ContactCard.scss';
import PropTypes from "prop-types";
import LABELS from 'labels';

const {
  IDENTIFIER: {
    CARD_AUTHOR,
    CARD_EMAIL
  } } = LABELS
class ContactCard extends PureComponent {
  handleClick(event) {
    const { cardClickCallback, tabIdentifier, id } = this.props;
    if (event) {
      event.stopPropagation();
    }
    if (cardClickCallback) {
      cardClickCallback(
        {
          tabIdentifier: tabIdentifier,
          cardIdentifier: CARD_AUTHOR,
          hrId: id
        }
      );
    }
    openLinkProfile(id);
  }

  emailHandleClick(event) {
    const { cardClickCallback, tabIdentifier, id } = this.props;
    if (event) {
      event.stopPropagation();
    }
    if (cardClickCallback) {
      cardClickCallback(
        {
          tabIdentifier: tabIdentifier,
          cardIdentifier: CARD_EMAIL,
          hrId: id
        }
      );
    }
  }

  render = () => {
    const {
      displayName,
      picture,
      businessTitle,
      hostOfficeLocation,
      workEmail,
      alumni
    } = this.props;
    const { GLOBAL: { YES, ALUMNI } } = LABELS;
    return (
      <div>
        <Card className="customizedCard cardhover" onClick={(event) => this.handleClick(event)}
        >
          <Card.Content>
            <span className="customizedCard__profile" >
              {!!picture ? (
                <img
                  className="customizedCard__profile__img"
                  src={picture}
                  alt="Selected pic"
                />
              ) : (
                <Icon className="user circle" />
              )}
            </span>
            {alumni === YES
              ?
              <span className="customizedCard__alumni">{ALUMNI}</span>
              :
              <a
                className="customizedCard__mail"
                onClick={(event) => this.emailHandleClick(event)}
                href={`mailto:${workEmail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="envelope contactActionIcon" />
              </a>
            }
            {!!displayName && (
              <div className="customizedCard__name">
                {displayName}
              </div>
            )}
            {!!businessTitle && <div className="customizedCard__title">{businessTitle}</div>}

            {!!hostOfficeLocation && (
              <div className="customizedCard__location">
                {isContentRestrictedOrNull(hostOfficeLocation?.name) ? hostOfficeLocation?.city : hostOfficeLocation?.name}
              </div>
            )}
          </Card.Content>
        </Card>
      </div>
    );
  };
}
export default ContactCard;
ContactCard.propTypes = {
  displayName: PropTypes.string,
  businessTitle: PropTypes.string,
  hostOfficeLocation: PropTypes.object,
  id: PropTypes.string,
  workEmail: PropTypes.string,
  alumni: PropTypes.string
};

ContactCard.defaultProps = {
  displayName: "",
  businessTitle: "",
  hostOfficeLocation: { city: "", name: "" },
  workEmail: "",
  alumni: LABELS.GLOBAL.NO
};