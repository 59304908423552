import './FaceCardUserProfile.scss'
import { openLinkProfile } from 'utils/helpers/helpers'

import React from 'react'
const FaceCardUserProfile = (props) => {
    const { picture, preferredFirstName, preferredLastName, id } = props.userDetails

    const handleProfileClick = () => {
        openLinkProfile(id, true);
    };
    return (
        <div className='faceCardUserProfile' onClick={handleProfileClick}>
            <img src={picture} className='faceCardUserProfile__image' />
            <p className='faceCardUserProfile__name'>{`${preferredFirstName} ${preferredLastName}`}</p>
        </div>
    )
}

export default FaceCardUserProfile;