import React from "react";
import { connect } from "react-redux";
import { KBStep3 } from "containers/KnowledgeBundles/KBStep3";
import { KBStep2 } from "containers/KnowledgeBundles/KBStep2";
import { KBStep1 } from "containers/KnowledgeBundles/KBStep1";
import FooterWorkflow from "components/shared/FooterWorkflow";
import { WarningModal } from "components/WarningModal/WarningModal";
import { GlobalStyle, OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import { bindActionCreators } from "redux";
import { Layout } from "containers/Layout";
import { selectors as metaDataSelectors } from "redux/reducers/metadata/metadata_reducer";
import { actions as metaDataActions } from "redux/actions/metadata/metadata_actions";
import { actions as knowledgeItemActions } from "redux/actions/knowledgeitem/knowledgeitem_actions";
import {
  notifyWarning,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
import { enableBodyOverflow, disableBodyOverflow } from "redux/actions/global/global.actions";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as associatedKPSelectors } from "redux/reducers/associatedKPs/associatedKPs_reducer";
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer"
import CONSTANTS from "globalConstants";
import LOCAL_CONFIG from "./KnowledgeBundles.config";
import {
  nextBtnAnalytics, publishBtnAnalytics, backBtnAnalytics, btnPreviewBundleAnalytics,
  knowledgeItemActionAnalytics
} from "./kb_analytics";
import DOCVIZ_CONFIG from '../Docviz/Docviz.config';

// Constant
import { META_DATA, KNOWLEDGE_ITEMS, DOCVIZ, KP } from "redux/constants";
import { validateForm, validateField } from "./kb_utils.js";
import { appendDomain, copyObject, getQueryParamIsRpa, getIsRpaReview, getLengthOfMultiArrayWithPoly } from "utils/helpers/helpers";
import CONFIG from "config";
import LABELS from "labels";
import { getUserId } from "utils/auth/auth";
// Analytics
import ANALYTICS from "utils/analytics/analytics";
import { Helmet } from "react-helmet";
import {
  SCREEN_NAMES,
  TRIGGERS,
  PRIMARY_CATEGORY,
  MATERIAL_STATUS,
  DTMRULE,
  PAGES
} from "utils/analytics/analytics_constants";
import { clearAssociatedKPs } from "redux/actions/associatedKPs/associatedKPs.actions";
import DocvizOverlayWrapper from "components/DocvizOverlayWrapper/DocvizOverlayWrapper";
import { resetAllParentTags } from "redux/actions/taxonomy/Taxonomy.actions";
import { triggerClippingAnalytics } from "utils/analytics/utils_analytics";

const { STEP1, STEP2, STEP3 } = CONSTANTS;
const { ENTITLEMENTS: { KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT, VIEW } } = CONSTANTS;
const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_BUNDLE_APP_NAME }, SITECORE_XM_ENABLED } = CONFIG;

class KnowledgeBundlesContainer extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params }, history, KBData } = this.props;
    const { step } = params;
    const { location: { search } } = history;
    const searchParams = new URLSearchParams(search);
    const paramBundleType = searchParams.get('bundleType');
    const currentBundleId = KBData?.kbId ? KBData?.bundleType?.id : this.getBundleId(paramBundleType);
    let metadataobj = {};
    if (step === STEP1 || step === STEP3) {
      metadataobj = {
        ...LOCAL_CONFIG.defaultMetaData,
        bundleType: { id: currentBundleId }
      };
    }

    this.state = {
      activeStep1: step === STEP1,
      activeStep2: step === STEP2,
      activeStep3: step === STEP3,
      localMetaData: metadataobj,
      errors: [],
      step2State: {
        isRequiredDataPresent: false,
        step2TabActiveIndex: 0
      },
      confirmViaPopup: false,
      queryParamTopicPage: [],
      comment: "",
      docvizOpen: false,
      kpDocvizAnalyticsData: {}
    };
    this.overlaySubscriber = null;
  }
  componentDidMount() {
    const { match: { params }, getMetaData, KBData } = this.props;
    const { localMetaData: prevKBData } = this.state;
    const { kbId } = params;
    if (kbId) {
      try {
        getMetaData(kbId);
      } catch (err) {
        console.error(err);
      }
    };

    if (Object.keys(KBData).length > 0 && JSON.stringify(KBData) !== JSON.stringify(prevKBData)) {
      this.setState({
        localMetaData: KBData
      });
    }

    const { activeStep1 } = this.state;
    const isKnowledgeBundle = this.isKnowledgeBundle();
    if (activeStep1) {
      this.fetchHridAndSet();
      if (!isKnowledgeBundle) {
        const { metaDataActions: { getImage } } = this.props;
        try {
          getImage();
        } catch (err) {
          console.error(err);
        }
      }
    }
    this.setTPOFDetails();

    this.overlaySubscriber = OverlayViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.CLOSE_CLICK:
          const { globalActions: { enableBodyOverflow } } = this.props;
          this.setState({
            docvizOpen: false
          });
          enableBodyOverflow();
          break;
        case SUBSCRIBERS.CLIP_DOWNLOAD_CLICK:
          const { slides } = action;
          const {
            downloadSelectedSlides,
            docvizDoc
          } = this.props;
          const slideNumbers = slides.map(s => s.slideNumber);
          downloadSelectedSlides(slideNumbers, docvizDoc.kpId, docvizDoc);
          triggerClippingAnalytics({
            id: kbId,
            kpid: docvizDoc.kpId,
            isCases: false,
            paRecommended: docvizDoc?.paRecommended,
            pageName: PAGES.KB_CREATION,
            primaryCategory: PRIMARY_CATEGORY.KB,
            slideNumber: slideNumbers.join("|")
          });
          break;
        default:
          break;
      }
    });
  }
  componentDidUpdate = (prevProps, prevState) => {
    const {
      match: {
        params: { step: prevStep },
      },
      KBData: prevKBData
    } = prevProps;
    const {
      match: {
        params: { step, kbId },
      },
      KBData,
      history
    } = this.props;

    let { activeStep1 } = this.state;
    const { UI_URL: { METADATA_KB } } = CONFIG;
    if (step !== prevStep) {
      this.setState({
        activeStep1: step === STEP1,
        activeStep2: step === STEP2,
        activeStep3: step === STEP3,
      });
    }

    if (KBData?.kbId && activeStep1 && !kbId) {
      history.replace({
        pathname: METADATA_KB(STEP1, KBData?.kbId),
      });
    }

    if (Object.keys(KBData).length > 0
      && JSON.stringify(KBData) !== JSON.stringify(prevKBData)
      && KBData?.status !== "Published"
    ) {
      this.setState({
        localMetaData: KBData
      });
    }

    // if the docviz loading state changes from loading true to false
    // means the metadata has been fetched and we should open the docviz modal
    // and also call analytics
    if (prevProps.isDocvizLoading && !this.props.isDocvizLoading) {
      const { globalActions: { disableBodyOverflow } } = this.props;
      this.setState({
        docvizOpen: true
      });
      disableBodyOverflow();
      this.handleDocvizPreviewAnalytics();
    }
  }

  componentWillUnmount() {
    if (this.overlaySubscriber) {
      this.overlaySubscriber.unsubscribe();
    }
    const { metaDataActions: { clearMetaData }, clearAssociatedKPs, clearAllParentTags } = this.props;
    clearMetaData();
    clearAssociatedKPs();
    clearAllParentTags();
  }

  setTPOFDetails = () => {
    let {
      localMetaData,
      queryParamTopicPage
    } = this.state;

    const { history: { location: { search } } } = this.props;
    const searchParams = new URLSearchParams(search);
    const topicId = searchParams.get('tpId');
    const topicName = searchParams.get('tpName');
    const topicPath = searchParams.get('tpType');

    if (topicId && topicName) {
      let topicpagesobj = [{ topicId, topicName, topicPath: SITECORE_XM_ENABLED == true && topicPath != null ? topicPath : "" }];
      localMetaData['topicPages'] = topicpagesobj;
      queryParamTopicPage["topicPages"] = topicpagesobj;
      this.setState({
        localMetaData: { ...localMetaData },
        queryParamTopicPage: { ...queryParamTopicPage }
      });
    }
  }


  fetchHridAndSet = async () => {
    let hrid = "";
    const { match: { params: { kbId } } } = this.props;
    let { localMetaData } = this.state;

    try {
      hrid = await getUserId();
      if (!localMetaData?.createdBy?.hrid && !kbId) {
        localMetaData["createdBy"] = { hrid: hrid };
        this.setState({
          localMetaData: { ...localMetaData }
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @desc handle the auto save operation
   * @memberof AddItems
   * @param no param
   * @return no return
   */

  handleAutoSaveApi = (newmetadata, autoSave = true) => {
    const { metaDataActions: { saveMetaData } } = this.props;
    const { localMetaData } = this.state;
    if (autoSave) {
      try {
        let datatobeSenttoApi = newmetadata ? newmetadata : localMetaData;
        const kbData = copyObject(datatobeSenttoApi);
        const isRpaReview = getIsRpaReview();
        if (isRpaReview) {
          kbData["isrpareview"] = true;
        }
        saveMetaData(kbData);
      } catch (err) {
        console.error(err);
      }
    }
  };

  updateMeta = (updatedState, historyObj) => {
    const { metaDataActions: { saveMetaData } } = this.props;
    try {
      updatedState["polyHierarchiLength"] = this.getPolyHierarchiLength(updatedState)
      const kbData = copyObject(updatedState);
      const isRpaReview = getIsRpaReview();
      if (isRpaReview) {
        kbData["isrpareview"] = true;
      }
      // update in db 
      setTimeout(() =>
        saveMetaData(kbData, historyObj), 500
      );
    } catch (err) {
      console.error(err);
    }
  };
  /**
 * @desc handle the input change
 * @memberof Metadata
 * @param {Object} updatedState
 * @return no return
 */
  updateMetaData = (updatedState) => {
    // setTimeout((updatedState) => {
    // api call
    this.updateMeta(updatedState)
    //}, CONFIG.TIMEOUT_LIMITS.DEBOUNCE_LIMIT)
  };

  /**
   * @desc handle the input change
   * @memberof Metadata
   * @param {Any} val - selected current component value
   * @param {String} type - decide to handle state object
   * @return no return
   */
  handleChange = (val, type, eventType = true) => {
    const { localMetaData } = this.state;
    const { KBData } = this.props;
    // mutate the meta with updated value
    let newValueObj = {};
    if (type === "paTags") {
      newValueObj = {
        industryPATags: val[0],
        functionalPATags: val[1],
        bcgInternals: val[2],
      }
    } else {
      newValueObj[type] = val;
    }
    // update local state
    this.setState({
      localMetaData: { ...localMetaData, ...newValueObj }
    }, () => {
      if (eventType && KBData?.status !== "Published") {
        // call the function and put into db
        const { localMetaData } = this.state;
        this.updateMetaData(localMetaData);
      }
    });
    eventType && this.validateKBField(val, type);
  };

  isKnowledgeBundle = () => {
    const { history, KBData } = this.props;

    const { KNOWLEDGEBUNDLE } = CONSTANTS;
    const { BUNDLE_TYPE } = CONFIG;
    const { location: { search } } = history;
    const searchParams = new URLSearchParams(search);
    const paramBundleType = searchParams.get('bundleType');
    const currentBundleId = KBData?.kbId ? KBData?.bundleType?.id : this.getBundleId(paramBundleType);
    const isKnowledgeBundle = currentBundleId === BUNDLE_TYPE[KNOWLEDGEBUNDLE];

    return isKnowledgeBundle;
  }

  validateKBField = (val, type) => {
    const { errors } = this.state;
    const { dismissNotifications } = this.props;

    let newerrors = [];
    const isKnowledgeBundle = this.isKnowledgeBundle();
    newerrors = validateField(val, type, LOCAL_CONFIG, isKnowledgeBundle);
    this.setState({
      errors: newerrors
    }, () => {
      if (!errors.length) {
        try {
          dismissNotifications();
        } catch (err) {
          console.error(err);
        }
      } else {
        dismissNotifications();
      }
    }
    );
  }
  validateKBForm = (step) => {
    const { localMetaData, errors } = this.state;
    const { dismissNotifications, notifyWarning } = this.props;
    const { GLOBAL: { INCOMPLETE_FORM_NOTIFICATION_MESSAGE } } = LABELS;
    let err = [];
    const isKnowledgeBundle = this.isKnowledgeBundle();
    err = validateForm(localMetaData, errors, LOCAL_CONFIG, isKnowledgeBundle, step);
    if (err.length > 0) {
      this.setState({
        errors: err
      });
      try {
        dismissNotifications();
        setTimeout(() => notifyWarning('', INCOMPLETE_FORM_NOTIFICATION_MESSAGE), 200);
      } catch (err) {
        console.error(err);
      }
      return false;
    } else {
      dismissNotifications();
    }
    return true;
  }

  getBundleId = (bundleName) => {
    const { BUNDLE_TYPE } = CONFIG;
    const { TOPICCOLLECTION } = CONSTANTS;
    let id = BUNDLE_TYPE[TOPICCOLLECTION];
    if (bundleName && BUNDLE_TYPE[bundleName]) {
      id = BUNDLE_TYPE[bundleName];
    }
    return id;
  }
  getStep2TabIndex = (step2TabActiveIndex) => {
    const { step2State } = this.state;
    this.setState({
      step2State: {
        ...step2State,
        step2TabActiveIndex: step2TabActiveIndex
      }
    });
  }
  getStep2RequiredDataFlag = (flag) => {
    const { step2State } = this.state;
    this.setState({
      step2State: {
        ...step2State,
        isRequiredDataPresent: flag
      }
    });
  }
  handleBack = () => {
    const { activeStep2, activeStep3, step2State: { step2TabActiveIndex } } = this.state;
    const { history, KBData, dismissNotifications } = this.props;
    let step = activeStep2 ? STEP1 : activeStep3 ? STEP2 : "";
    if (step) {
      history.push({
        pathname: CONFIG.UI_URL.METADATA_KB(step, KBData?.kbId),
        state: { mode: history.location?.state?.mode },
      });
    }
    dismissNotifications();

    let screenName = "";
    if (step2TabActiveIndex === 1)
      //when active tab index is 1(supplinks)
      screenName = SCREEN_NAMES.ADDITEMS_SL;
    else if (step2TabActiveIndex === 0) {
      //when active tab index is 0(k items)
      screenName = SCREEN_NAMES.ADDITEMS_KP;
    }
    screenName = activeStep2 ? screenName : activeStep3 ? SCREEN_NAMES.SELECT_TAGS : "";
    backBtnAnalytics(KBData, screenName);
  }

  handlePreview = () => {
    const { history, KBData } = this.props;
    const { localMetaData } = this.state;
    history.push({
      pathname: CONFIG.UI_URL.KB_PREVIEW(localMetaData.kbId)
    });

    btnPreviewBundleAnalytics(localMetaData, KBData);
  }
  footerButtonsClick = (e) => {
    if (e.target.id !== "btn-next") {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.target.id === "btn-back") {
      this.handleBack();
    } else if (e.target.id === "btn-previewbundle") {
      this.handlePreview();
    } else if (e.target.id === "btn-publish" || e.target.id === "btn-next") {
      this.handleSubmit(e);
    }
  };

  handleSubmit = (event, checkConfirm = true) => {
    // prevent to form submit
    if (event.target.id !== "btn-publish") {
      event.preventDefault();
      event.stopPropagation();
    }
    const { STEP1, STEP2, STEP3 } = CONSTANTS;
    const { activeStep1, activeStep2, localMetaData } = this.state;
    let nextstep = '', step = '';
    nextstep = activeStep1 ? STEP2 : activeStep2 ? STEP3 : STEP1;
    step = activeStep1 ? STEP1 : activeStep2 ? STEP2 : STEP3;

    const { KBData, history } = this.props;

    if (event.target.id === "btn-next") {
      // validate form
      if (activeStep1) {
        if (!this.validateKBForm(step)) {
          return false;
        }
      }

      if (KBData?.status === "Published") {
        this.updateMeta(localMetaData, history);
      }
      // redirect to add items page
      if (KBData?.kbId && KBData?.status !== "Published") {
        history.push({
          pathname: CONFIG.UI_URL.METADATA_KB(nextstep, KBData?.kbId),
          state: { mode: history.location?.state?.mode },
          search: getQueryParamIsRpa(),
        });
      }
      nextBtnAnalytics(localMetaData, KBData);
    }
    else if (event.target.id === "btn-publish") {
      const { comment } = this.state;
      let metaData = localMetaData;
      if (!this.validateKBForm(step)) {
        return false;
      } else {
        if (checkConfirm) {
          this.setState({ confirmViaPopup: true });
        } else {
          metaData["polyHierarchiLength"] = this.getPolyHierarchiLength(metaData);
          // mutate the local state
          metaData = {
            ...metaData,
            status: CONFIG.KNOWLEDGE_BUNDLE_STATUS.PUBLISHED,
            comment
          };
          // set local state
          //setLoading(true);
          if (JSON.stringify(localMetaData) !== metaData) {
            this.setState({
              localMetaData: metaData
            }, () => {
              this.handleAutoSaveApi(metaData);
              publishBtnAnalytics(localMetaData);
            });
          }

        }
      }
    }
  };

  handleUpdateKnowledgeItemList = (newItemslist) => {
    //debounce((newItemslist) => {
    const { reorderAssociatedKPs } = this.props;
    try {
      if (newItemslist) {
        //debounce((newItemslist) => {
        reorderAssociatedKPs(newItemslist);// saga
        //}, LOCAL_CONFIG.debounceTime),
      }
    } catch (err) {
      console.error(err);
    }
    // }, LOCAL_CONFIG.debounceTime),
    // []
  };

  /**
       * @desc set the knowledge items count in local state
       * @memberof AddItems
       * @param {object} of total knowledge items added on screen
       * @return no return
   */
  getItemsFromApi = (koidList, source = false, items = [], trigger) => {
    let { localMetaData } = this.state;
    if (Array.isArray(koidList) && localMetaData.kbId) {
      if (source) {
        let newMetadata = { ...localMetaData, knowledgeObjects: koidList };
        // save in cms
        if (items.length) {
          this.handleUpdateKnowledgeItemList(items);
        }
        this.setState({
          localMetaData: newMetadata
        }, () => {
          this.handleAutoSaveApi(newMetadata);
        });
        //
      }
    }

    knowledgeItemActionAnalytics(localMetaData, trigger);
  };
  /**
   * @desc set the supporting links count in local state
   * @memberof AddItems
   * @param {object} of total supporting links added on screen
   * @return no return
   */
  getLinksFromApi = (links, trigger) => {
    let { localMetaData } = this.state;
    // check links as an array & metadata kbid    
    if (Array.isArray(links) && localMetaData.kbId) {
      let newMetadata = { ...localMetaData, relatedLinks: links };
      this.setState({
        localMetaData: newMetadata
      }, () => {
        this.handleAutoSaveApi(newMetadata);
      });
      const topicPages = localMetaData?.topicPages;
      let topicId = "";
      if (Array.isArray(topicPages) && topicPages.length > 0) {
        topicId = topicPages[0]?.topicId ?? "";
      }
      let aa_trigger;
      if (trigger === "delete") aa_trigger = TRIGGERS.DELETE;
      else if (trigger === "reorder") aa_trigger = TRIGGERS.REARRANGE;
      else if (trigger === "addlink") aa_trigger = TRIGGERS.SELECT_ITEM;

      let newAdobeData = {
        collection: {
          trigger: aa_trigger,
          ID: localMetaData.kbId,
          screenName: SCREEN_NAMES.ADDITEMS_SL,
        },
        suggestedTopicID: topicId,
      };
      ANALYTICS.kc.sendEventData(newAdobeData);
    }
  };


  /**
   * @desc Updates the local state with Knowledge object id, updates metadata in db, adds selected item in list
   * @memberof AddItems
   * @param {object} of searched knowledge item
   * @return no return
   *
   *
   */
  handleOnSelection = async (localSearchItem) => {
    // look for item searched
    const {
      knowledgeItemActions: { addKnowledgeObjectToBundle },
      saveAssociatedKPs,
      KBData: {
        subjectTags: subjectTagsFromApi
      }
    } = this.props;
    const { localMetaData, localMetaData: { knowledgeObjects } } = this.state;
    const newlist = [...knowledgeObjects, localSearchItem['id']];
    const stagsList = localSearchItem && localSearchItem['subjects'] ? localSearchItem['subjects'] : [];

    let subjectTags = [];
    if (subjectTagsFromApi?.length || stagsList?.length) {
      subjectTags = [...subjectTagsFromApi, ...stagsList];
    }

    let newMetadata = { ...localMetaData, knowledgeObjects: newlist, subjectTags: subjectTags ?? [] };
    if (JSON.stringify(localMetaData) !== JSON.stringify(newMetadata)) {
      newMetadata["polyHierarchiLength"] = this.getPolyHierarchiLength(newMetadata);
      this.setState({
        localMetaData: newMetadata
      }, () => {
        this.handleAutoSaveApi(newMetadata);
      });
    }

    // Update the store metadata with updated KnowledgeObject ( push koid)
    addKnowledgeObjectToBundle(localSearchItem['id']); // thunk
    saveAssociatedKPs(localSearchItem);

    // once store metadata is updated, dispatch the changes to cms
    //setTimeout(()=> this.handleAutoSaveApi(localMetaData), CONFIG.TIMEOUT_LIMITS.DELAY_TIME);

    const topicPages = localMetaData?.topicPages;
    let topicId = "";
    if (Array.isArray(topicPages) && topicPages.length > 0) {
      topicId = topicPages[0]?.topicId ?? "";
    }
    let newAdobeData = {
      collection: {
        trigger: TRIGGERS.SELECT_ITEM,
        ID: localMetaData.kbId,
        screenName: SCREEN_NAMES.KNOWL_PAGES,
      },
      suggestedTopicID: topicId,
    };
    ANALYTICS.kc.sendEventData(newAdobeData);
  };
  /**
   * @desc API call to search the material via  search api
   * @param {*} legacyId
   * @memberof AddItems
   *
   */
  handleOnSearch = async (legacyId, searchTerm) => {
    const { localMetaData } = this.state;
    const {
      knowledgeItemActions: { searchKnowledgeObjDetails }
    } = this.props;

    // dispatch the Search Knowledge Object API call
    searchKnowledgeObjDetails(legacyId, searchTerm);

    const newAdobeData = {
      collection: {
        trigger: TRIGGERS.KP_SEARCH_ON_KB,
        ID: localMetaData.kbId,
        screenName: SCREEN_NAMES.SELECT_ITEMS_KP,
      },
      search: {
        term: searchTerm,
        type: SCREEN_NAMES.KNOWL_PAGES,
        searchTrigger: TRIGGERS.NO_TYPEAHEAD
      }
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.INTERNAL_SEARCH);
  };

  handleOnRecentContributions = async () => {
    const { knowledgeItemActions: { searchRecentContributions } } = this.props;
    searchRecentContributions();
  }

  sendAnalyticsForHelpModal = () => {
    const { localMetaData } = this.state;
    const topicPages = localMetaData?.topicPages;
    let topicId = "";
    if (Array.isArray(topicPages) && topicPages.length > 0) {
      topicId = topicPages[0]?.topicId ?? "";
    }
    let newAdobeData = {
      collection: {
        trigger: TRIGGERS.HELPMODAL,
        ID: localMetaData.kbId,
        screenName: SCREEN_NAMES.ADDITEMS_KP,
      },
      suggestedTopicID: topicId,
    };
    ANALYTICS.kc.sendEventData(newAdobeData);
  };

  handleDocvizPreview = (kpId, ...kpData) => {
    const { getDocvizMetadata } = this.props;
    const {
      match: {
        params: { kbId },
      }
    } = this.props;
    const { KB_CREATION } = PAGES;
    this.setState({
      kpDocvizAnalyticsData: {
        id: kbId,
        paRecommended: kpData[2],
        category: KB_CREATION
      }
    })
    getDocvizMetadata(kpId);
  };

  handleDocvizPreviewAnalytics = () => {
    const { localMetaData } = this.state;
    const { docvizDoc } = this.props;
    const { PA_RECOMMENDED, NOT_PA_RECOMMENDED } = MATERIAL_STATUS;
    const newAdobeData = {
      resultType: PRIMARY_CATEGORY.KB,
      displayMode: LABELS.DISPLAY_MODE.DOCVIZ,
      collection: {
        trigger: TRIGGERS.KB_CONSUMPTION.KB_KP_PREVIEW,
        ID: localMetaData.kbId,
        screenName: SCREEN_NAMES.ADDITEMS_KP
      },
      page: {
        category: {
          primaryCategory: PRIMARY_CATEGORY.KB,
        },
      },
      KCpage: {
        id: docvizDoc.kpId
      },
      materialPAStatus: docvizDoc.paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      numberofSlides: docvizDoc.slides?.length
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOCVIZ_OVERLAY_OPEN);
  };

  getFooterBtnsConfig = (btnsConfig) => {
    const { activeStep1, activeStep2, activeStep3 } = this.state;
    const { KBData, isPublishContentEntitlement, isViewEntitlement } = this.props;
    const { ALT_TEXTS: { NEXT_STEP }, KB_FORM_PLACEHOLDERS: { KB_ASSETS_VALIDATION_ONLYTEXT } } = LABELS;
    if (activeStep1) {
      btnsConfig[1].disabled = !KBData?.kbId ?? true; // NEXT
      btnsConfig[0].visible = false; // BACK
      btnsConfig[1].visible = true; // NEXT
      btnsConfig[1].tooltip = '';

      btnsConfig[2].children[0].visible = false; // Preview Bundle
      btnsConfig[2].children[1].visible = false; // Publish
      btnsConfig[2].children[2].visible = false; //send for review            
    }
    else if (activeStep2) {
      const { step2State: { isRequiredDataPresent } } = this.state; // TODO: 
      btnsConfig[1].disabled = !isRequiredDataPresent; // NEXT 
      if (isRequiredDataPresent) {
        btnsConfig[1].tooltip = NEXT_STEP;
      } else {
        btnsConfig[1].tooltip = KB_ASSETS_VALIDATION_ONLYTEXT;

      }
      btnsConfig[0].visible = true; // BACK
      btnsConfig[1].visible = true; // NEXT

      btnsConfig[2].children[0].visible = false; // Preview Bundle
      btnsConfig[2].children[1].visible = false; // Publish
      btnsConfig[2].children[2].visible = false; //send for review
    }
    else if (activeStep3) {
      btnsConfig[1].visible = false; // NEXT
      btnsConfig[0].visible = true; // BACK
      btnsConfig[2].children[0].visible = isViewEntitlement; // Preview Bundle            

      if (typeof isPublishContentEntitlement !== "undefined" && !isPublishContentEntitlement) {
        btnsConfig[2].children[1].visible = false; // publish
        btnsConfig[2].children[2].visible = true; //send for review
      }
      else {
        btnsConfig[2].children[1].visible = true; // publish
        btnsConfig[2].children[1].disabled = false; // publish
        btnsConfig[2].children[2].visible = false; //send for review        
      }
    }
    return btnsConfig;
  }

  /**
 * @param {boolean} isConfirm
 */
  handleConfirmViaPopupChoice = async (isConfirm) => {
    if (!isConfirm) {
      this.setState({ confirmViaPopup: false });
    }
    if (isConfirm) {
      this.handleSubmit({ target: { id: "btn-publish" } }, false);
    }
  }

  /**
 * @param {key} state key 
 * @param {data} state data 
 */
  setStateData = (key, data) => {
    this.setState({ [key]: data })
  }


  getPolyHierarchiLength = (kbMainData) => {
    let polyHLength = 0;
    try {
      if (kbMainData) {
        const { industryPATags, functionalPATags, bcgInternals } = kbMainData;
        polyHLength = getLengthOfMultiArrayWithPoly([{
          ipaTags: industryPATags || [],
          fpaTags: functionalPATags || [],
          bcgInternals: bcgInternals || []
        }])
      }
    } catch (error) {
      console.log('Error in getPolyHierarchiLength function ', error)
    }
    return polyHLength;
  }

  render() {
    const {
      activeStep1, activeStep2, activeStep3, localMetaData,
      confirmViaPopup, queryParamTopicPage, errors, kpDocvizAnalyticsData
    } = this.state;
    const { KBData, coverimages, history, match: { params, params: { step } }, knowledgeItemsState, getKPFile, downloadAllStatus, expertTagsIds, subjectTagsIds } = this.props;
    const { status, version } = KBData;
    const {
      TPKB: {
        BREADCRUMB_NEW_KB_TITLE,
        BREADCRUMB_EDIT_KB_TITLE
      },
      MY_NAVIGATOR: {
        ITEM_STATUS: {
          DRAFT, PUBLISH
        }
      },
      KB: { CONFIRM_MSG_KB, CONFIRM_MSG_TC },
      KNOWLEDGE_PRODUCT_NAME: { TPKB },
      WORK_SPACE: {
        WARNING_MODAL_CANCEL,
        WARNING_MODAL_CONFIRM,
      },
      PAGETITLE: {
        KB_KP_TC_CONTRIBUTION
      }
    } = LABELS;

    const { kbId } = params;

    const { KNOWLEDGEBUNDLE } = CONSTANTS;
    const { BUNDLE_TYPE } = CONFIG;
    const { location: { search } } = history;
    const searchParams = new URLSearchParams(search);
    const paramBundleType = searchParams.get('bundleType');
    const currentBundleId = KBData?.kbId ? KBData?.bundleType?.id : this.getBundleId(paramBundleType);
    const isKnowledgeBundle = currentBundleId === BUNDLE_TYPE[KNOWLEDGEBUNDLE];

    const { KNOWLEDGE_STATUS: { UNPUBLISHED, PUBLISHED, ARCHIVED } } = CONFIG;
    const isPublishModalVisible = KBData.status === PUBLISHED || KBData.status === UNPUBLISHED || KBData.status === ARCHIVED;
    const { KB: {
      KB_PUBLISHED_MESSAGE,
      KB_UNPUBLISHED_MESSAGE,
      TC_PUBLISHED_MESSAGE,
      TC_UNPUBLISHED_MESSAGE,
      TC_ARCHIVED_MESSAGE,
      KB_ARCHIVED_MESSAGE } } = LABELS;

    let succesMessage = '';
    if (KBData.status === PUBLISHED) {
      succesMessage = isKnowledgeBundle ? KB_PUBLISHED_MESSAGE : TC_PUBLISHED_MESSAGE;
    } else if (KBData.status === UNPUBLISHED) {
      succesMessage = isKnowledgeBundle ? KB_UNPUBLISHED_MESSAGE : TC_UNPUBLISHED_MESSAGE;;
    } else if (KBData.status === ARCHIVED) {
      succesMessage = isKnowledgeBundle ? KB_ARCHIVED_MESSAGE : TC_ARCHIVED_MESSAGE;;
    }

    const btnsConfig = this.getFooterBtnsConfig(LOCAL_CONFIG.footerWorkflow);

    const hinderRendering = kbId ? Object.keys(KBData).length ? true : false : true;

    const { TPKB_WORKFLOW_BAR } = CONFIG;
    const { otherProps } = DOCVIZ_CONFIG;
    return (
      <Layout
        id="layout__tpkb"
        layoutClass="layout__tpkb"
        pillFlag
        workflowFlag
        breadCrumbFlag
        bannerFlag
        activeStep={parseInt(step.replace("step", ""))}
        stepper={TPKB_WORKFLOW_BAR()}
        contentStage={status ? status : "--"}
        productName={TPKB}
        pageTitle={
          history.location?.state?.mode === PUBLISH ||
            history.location?.state?.mode === DRAFT
            ? BREADCRUMB_EDIT_KB_TITLE
            : BREADCRUMB_NEW_KB_TITLE
        }
      >
        <Helmet>
          <title>{KB_KP_TC_CONTRIBUTION}</title>
        </Helmet>
        <GlobalStyle />
        {this.state.docvizOpen && (
          <DocvizOverlayWrapper
            getKPFile={getKPFile}
            downloadAllStatus={downloadAllStatus}
            doc={this.props.docvizDoc}
            {...otherProps}
            downloadStatus={this.props.downloadStatus}
            kpDocvizAnalyticsData={kpDocvizAnalyticsData}
          />
        )}
        {!!hinderRendering && <>
          {activeStep1 && (
            <KBStep1
              isKnowledgeBundle={isKnowledgeBundle}
              images={coverimages}
              errors={errors}
              handleChange={this.handleChange}
              kbId={kbId}
              apidata={localMetaData}
            />
          )}
          {activeStep2 && (
            <KBStep2
              isPublishModalVisible={isPublishModalVisible}
              kbId={kbId}
              setStep2TabIndex={this.getStep2TabIndex}
              setStep2RequiredDataFlag={this.getStep2RequiredDataFlag}
              apidata={KBData}
              knowledgeItemsState={knowledgeItemsState}
              getItemsFromApi={this.getItemsFromApi}
              getLinksFromApi={this.getLinksFromApi}
              handleOnSelection={this.handleOnSelection}
              handleOnSearch={this.handleOnSearch}
              handleOnRecentContributions={this.handleOnRecentContributions}
              sendAnalyticsForHelpModal={this.sendAnalyticsForHelpModal}
              handleDocvizPreview={this.handleDocvizPreview}
              getKPFile={getKPFile}
            />
          )}
          {activeStep3 && (
            <>
              <KBStep3
                isKnowledgeBundle={isKnowledgeBundle}
                errors={errors}
                handleChange={this.handleChange}
                kbId={kbId}
                apidata={localMetaData}
                queryParamTopicPage={queryParamTopicPage}
                subjectTagsIds={subjectTagsIds}
                expertTagsIds={expertTagsIds}
              />
            </>
          )}
          <FooterWorkflow
            buttons={[...btnsConfig]}
            getEvent={(e) => this.footerButtonsClick(e)}
          />
          <WarningModal
            heading={succesMessage}
            button1={{
              text: LABELS.BUTTONS.HOME,
              clickHandler: () => {
                history.push("/");
              },
              colorClass: "green"
            }}
            button2={{
              text: LABELS.BUTTONS.VIEW_CONSUMPTION,
              clickHandler: () => {
                history.push(
                  {
                    pathname: CONFIG.UI_URL.KB_CONSUMPTION(KBData?.kbId)
                  }
                );
              },
              colorClass: "green"
            }}
            iconName={'check'}
            isOpen={isPublishModalVisible}
          />

          <WarningModal
            heading={isKnowledgeBundle ? CONFIRM_MSG_KB : CONFIRM_MSG_TC}
            button1={{
              text: WARNING_MODAL_CANCEL,
              clickHandler: () => this.handleConfirmViaPopupChoice(false),
            }}
            button2={{
              text: WARNING_MODAL_CONFIRM,
              clickHandler: () => this.handleConfirmViaPopupChoice(true),
            }}
            isOpen={confirmViaPopup && !isPublishModalVisible}
            commentTextField={version > 1}
            setCommentCallback={this.setStateData}
            onRequestClose={this.closeModalW}
          />
        </>
        }

      </Layout>
    );
  }

}

KnowledgeBundlesContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  KBData: metaDataSelectors.getFilteredKbMetaData(state),
  coverimages: appendDomain(metaDataSelectors.getImages(state)),
  isViewEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, VIEW),
  knowledgeItemsState: associatedKPSelectors.getAssociatedKPsBundle(state),
  isPublishContentEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT),
  docvizDoc: docvizSelectors.getDocvizDocument(state),
  isDocvizLoading: docvizSelectors.getLoading(state),
  expertTagsIds: taxonomySelector.getExpertTagsIdsOnly(state),
  subjectTagsIds: taxonomySelector.getSubjectTagsIdsOnly(state),
  downloadStatus: docvizSelectors.getDownloadStatus(state),
  downloadAllStatus: kpSelectors.getLoadingDownloadAll(state)
});

const mapDispatchToProps = (dispatch) => ({
  getMetaData: (kbId) => dispatch({ type: META_DATA.API_GET_METADATA, payload: { kbId } }),
  metaDataActions: bindActionCreators({ ...metaDataActions }, dispatch),
  knowledgeItemActions: bindActionCreators({ ...knowledgeItemActions }, dispatch),
  notifyWarning: (title, msg) => dispatch(notifyWarning(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
  globalActions: bindActionCreators({ enableBodyOverflow, disableBodyOverflow }, dispatch),
  reorderAssociatedKPs: (newItemslist) => dispatch({ type: KNOWLEDGE_ITEMS.REORDER_ASSOCIATED_KPS, payload: { newkpdata: newItemslist } }),
  saveAssociatedKPs: (localSearchItem) => dispatch({ type: KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP, payload: { kpdata: localSearchItem } }),
  clearAssociatedKPs: () => dispatch(clearAssociatedKPs()),
  getDocvizMetadata: (id) => dispatch({ type: DOCVIZ.GET_DOCVIZ_METADATA, payload: { id } }),
  downloadSelectedSlides: (pages, documentId, docvizDoc) => dispatch({
    type: DOCVIZ.DOWNLOAD_SELECTED_SLIDES,
    payload: {
      pages,
      documentId,
      doc: docvizDoc,
      appName: KNOWLEDGE_BUNDLE_APP_NAME
    }
  }),
  clearAllParentTags: () => dispatch(resetAllParentTags()),
  getKPFile: (kpId) => dispatch({
    type: KP.GET_FILE,
    payload: {
      kpId: kpId,
      appName: KNOWLEDGE_BUNDLE_APP_NAME,
      type: CONFIG.downloadTypeForKP
    }
  })
});

export const KnowledgeBundles = connect(
  mapStateToProps,
  mapDispatchToProps
)(KnowledgeBundlesContainer);

export { KnowledgeBundlesContainer };
