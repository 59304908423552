import React from 'react';
import PropTypes from 'prop-types';
import CONFIG from "config";

//Components
import { Docviz } from "containers/Docviz";
import LoadingSpinner from "components/shared/LoadingSpinner/LoadingSpinner";
import KPGridShowMoreLess from "components/KPGridShowMoreLess/KPGridShowMoreLess";

//Helper
import { isDocvizNotSupported } from "utils/helpers/helpers";

// SCSS
import "./KPGrid.scss";

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME } } = CONFIG;

const KPGrid = ({
    docvizMultiDoc,
    knowledgePages,
    isDocvizInitialLoading,
    isDocvizFinalLoading,
    mergDocvizWithKnowledgePagesData,
    handleFileDownloadFromGrid,
    handlePreviewFromGrid,
    showKpGridView,
    handleTitleClickFromGrid,
    isShowMore,
    handleShowMore,
    handleErrorAnalyticsFromGrid
}) => {
    return (
        <div>
            {!isDocvizInitialLoading && (
                <ul className={`kpgridlist__container--detailedView ${docvizMultiDoc?.length < 3 ? "kpgridlist__container--detailedView--2" : ""}`}>
                    {docvizMultiDoc?.length > 0 && docvizMultiDoc.map((item, index) => {
                        let updatedDocvizItem = mergDocvizWithKnowledgePagesData(item, knowledgePages[index]);
                        const showFrameWorkRibbon = updatedDocvizItem?.frameworkSlides?.length > 0;
                        const showReleventMarkers = updatedDocvizItem?.frameworkSlides?.length > 0;
                        return (
                            <li className="kpgridlist__element" key={item?.kpId} >
                                <Docviz
                                    kpMetaData={knowledgePages[index]}
                                    docvizDoc={updatedDocvizItem}
                                    docvizActions={{
                                        showDownload: true,
                                        showPreview: true,
                                        showEyePreview: false,
                                        showShare: true,
                                        showEmail: true
                                    }}
                                    download={(id) => handleFileDownloadFromGrid(knowledgePages[index], id)}
                                    handlePreviewFromGrid={(id) => handlePreviewFromGrid(knowledgePages[index], id)}
                                    shouldHandleClipDownload={false} ////ClipDownload already handled in Knowledge File which is parent of this file
                                    isConsumptionView
                                    isDocvizNotSupported={isDocvizNotSupported(knowledgePages[index], item?.slides)}
                                    isMDPRestricted={knowledgePages[index]?.isRestricted}
                                    downloadApplicationName={KNOWLEDGE_PAGE_APP_NAME}
                                    isMultipleCardView={showKpGridView}
                                    handleTitleClickFromGrid={(id) => handleTitleClickFromGrid(knowledgePages[index], id)}
                                    handleErrorAnalyticsFromGrid={(msg, id) => handleErrorAnalyticsFromGrid(item?.kpId, id, msg)}
                                    showFrameWorkRibbon={showFrameWorkRibbon}
                                    showReleventMarkers={showReleventMarkers}
                                    isDetailCardView={true}
                                />
                            </li>
                        )
                    })}
                </ul>
            )}

            <LoadingSpinner showSpinner={isDocvizInitialLoading || isDocvizFinalLoading} />

            {knowledgePages && docvizMultiDoc && docvizMultiDoc.length > 0 && knowledgePages.length > 9 && (
                <KPGridShowMoreLess isShowMore={isShowMore} handleShowMore={handleShowMore} />
            )}
        </div>
    );
};


KPGrid.propTypes = {
    knowledgePages: PropTypes.array,
    isDocvizLoading: PropTypes.bool,
    docvizMultiDoc: PropTypes.array,
    showKpGridView: PropTypes.bool,
    isShowMore: PropTypes.bool,
    mergDocvizWithKnowledgePagesData: PropTypes.func,
    handleFileDownloadFromGrid: PropTypes.func,
    handlePreviewFromGrid: PropTypes.func,
    handleTitleClickFromGrid: PropTypes.func,
    handleShowMore: PropTypes.func,
    handleErrorAnalyticsFromGrid: PropTypes.func,
};

KPGrid.defaultProps = {
    knowledgePages: [],
    isDocvizLoading: false,
    docvizMultiDoc: [],
    showKpGridView: false,
    isShowMore: false,
    mergDocvizWithKnowledgePagesData: () => { },
    handleFileDownloadFromGrid: () => { },
    handlePreviewFromGrid: () => { },
    handleTitleClickFromGrid: () => { },
    handleShowMore: () => { },
    handleErrorAnalyticsFromGrid: () => { }
};

export default KPGrid;