import { FACE_CARD_GENERATOR } from "redux/constants";
export const name = "faceCardGenerator";

// initial state
export const initialState = {
    loading: false,
    error: false,
    errorMessage: "",
    requestId: null,
    preSignedUrl: "",
    peopleData: [],
    peoplePayloadData: null
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getRequestID: state => state[name].requestId,
    getPresignedURL: state => state[name].preSignedUrl,
    getPeopleData: state => state[name].peopleData,
    getPeoplePayloadData: state => state[name].peoplePayloadData
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case FACE_CARD_GENERATOR.FACECARD_SLIDE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action?.error?.message || ''
            };
        case FACE_CARD_GENERATOR.FACECARD_SLIDE_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: ''
            };
        case FACE_CARD_GENERATOR.FACECARD_SLIDE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                requestId: action.payload.requestId,
                preSignedUrl: action.payload.preSignedUrl,
                errorMessage: ''

            };
        case FACE_CARD_GENERATOR.FACECARD_SLIDE_PEOPLE_DATA:
            return {
                ...state,
                peopleData: action.payload
            };
        case FACE_CARD_GENERATOR.GET_FACECARD_SLIDE_DATA:
            return {
                ...state,
                peoplePayloadData: action.payload
            }
        default:
            return state;
    }
}