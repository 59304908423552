import React from 'react';
import "./PaRecommended.scss";
import LABELS from 'labels';
import { PopupModal } from 'components/shared/PopupModal/PopupModal';
import { groupBy, areObjectsEqual } from "utils/helpers/helpers";
import Image from "components/shared/Image";
import starIcon from "assets/images/star.svg";
import starIconGrey from "assets/images/star-grey.svg";
import CONFIG from 'config';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

export class PaRecommended extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openPAmodel: false,
      groupedPaRecommendedtopic: {}
    }
  }
  paButtonClick = () => {
    const { buttonClickCallback } = this.props;
    this.setState({
      openPAmodel: true
    }, () => {
      if (buttonClickCallback)
        buttonClickCallback();
    });
  }
  changePAIsAssigned = (category, index) => {
    const { groupedPaRecommendedtopic } = this.state;
    let updatedPaAssigned = groupedPaRecommendedtopic[category].map(
      (content, i) => i === index ? { ...content, isassigned: !content.isassigned }
        : content)
    this.setState({
      groupedPaRecommendedtopic: {
        ...groupedPaRecommendedtopic,
        [category]: updatedPaAssigned
      }
    });
  }
  componentDidMount = () => {
    this.prepareGroupedData();
  }
  componentDidUpdate(prevProps) {
    const { getPaRecommendedData } = this.props;
    const { getPaRecommendedData: prevPaRecommendedData } = prevProps;
    !areObjectsEqual(getPaRecommendedData, prevPaRecommendedData) && this.prepareGroupedData();
  }
  prepareGroupedData() {
    const { getPaRecommendedData: { paRecommendedtopic } } = this.props;
    const groupedPaRecommendedtopic = paRecommendedtopic && groupBy([...paRecommendedtopic], "category");
    this.setState({
      groupedPaRecommendedtopic,
      openPAmodel: false
    })
  }
  savePa = () => {
    const { groupedPaRecommendedtopic } = this.state;
    const { updatePaRecommended, getPaRecommendedData: { id }, savePaClickCallback } = this.props;
    const ungroupedPA = groupedPaRecommendedtopic && Object.values(groupedPaRecommendedtopic).flat();
    ungroupedPA.forEach(function (topic) {
      delete topic.category;
      delete topic.path;
    });
    const updatedPAData = {
      id,
      paRecommendedtopic: ungroupedPA
    }
    if (savePaClickCallback) {
      savePaClickCallback();
    }
    updatePaRecommended(updatedPAData)
    this.setState({
      openPAmodel: false
    })
  }

  /**
  * @param {boolean} isConfirm
  */
  handleConfirmViaPopupChoice = (isConfirm) => {
    isConfirm ? this.savePa() : this.prepareGroupedData();
  }

  render() {
    const { PA_RECOMMEND, BUTTONS: {
      SAVE,
      CANCEL
    } } = LABELS;
    const { popupMessage, paButtonDisabled } = this.props;
    const { openPAmodel, groupedPaRecommendedtopic } = this.state;
    const { PA: {
      IPA,
      FPA,
      BCG_INTERNAL,
      MODAL_WIDTH
    } } = CONFIG;

    return (
      <div className="paRecommended">
        <span className={`paRecommended--pabutton ${paButtonDisabled ? ' disabled' : ''}`} onClick={this.paButtonClick}>
          <Image src={paButtonDisabled ? starIconGrey : starIcon} className='paRecommended--pabutton__icon' />
          <span className="paRecommended--pabutton__text">{PA_RECOMMEND}</span>
        </span>
        <PopupModal
          header={true}
          isOpen={openPAmodel}
          button1={{
            text: CANCEL,
            clickHandler: () => this.handleConfirmViaPopupChoice(false),
            button1Class: 'paRecommended--cancel black',
          }}
          button2={{
            text: SAVE,
            clickHandler: () => this.handleConfirmViaPopupChoice(true),
            button2Class: 'paRecommended--save green',
          }}
          headerMessage={popupMessage}
          onRequestClose={() => this.handleConfirmViaPopupChoice(false)}
          modalContentWidth={MODAL_WIDTH}
        >
          <div className="popup">
            {groupedPaRecommendedtopic[IPA] &&
              <>
                <p className="popup--category"> Industries</p>
                {groupedPaRecommendedtopic[IPA].map((topic, index) => <span key={uuidv4()} onClick={() => this.changePAIsAssigned(IPA, index)} className={`${topic.isassigned ? 'popup--blackPill' : 'popup--pill'}`}> {topic?.name}</span>)}
              </>
            }
            {groupedPaRecommendedtopic[FPA] &&
              <>
                <p className="popup--category"> Functions</p>
                {groupedPaRecommendedtopic[FPA].map((topic, index) => <span key={uuidv4()} onClick={() => this.changePAIsAssigned(FPA, index)} className={` ${topic.isassigned ? 'popup--blackPill' : 'popup--pill'}`}> {topic?.name}</span>)}
              </>
            }
            {groupedPaRecommendedtopic[BCG_INTERNAL] &&
              <>
                <p className="popup--category"> BCG_INTERNAL</p>
                {groupedPaRecommendedtopic[BCG_INTERNAL].map((topic, index) => <span key={uuidv4()} onClick={() => this.changePAIsAssigned(BCG_INTERNAL, index)} className={` ${topic.isassigned ? 'popup--blackPill' : 'popup--pill'}`}> {topic?.name}</span>)}
              </>
            }
          </div>
        </PopupModal>
      </div>
    )
  }

}

PaRecommended.propTypes = {
  popupMessage: PropTypes.string.isRequired,
  updatePaRecommended: PropTypes.func,
  getPaRecommendedData: PropTypes.object
}

PaRecommended.defaultProps = {
  getPaRecommendedData: {}
};