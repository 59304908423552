import { FACE_CARD_GENERATOR } from "redux/constants";

export const getFaceCardSlideData = (data) => ({
    type: FACE_CARD_GENERATOR.GET_FACECARD_SLIDE_DATA,
    payload: data
});
export const faceCardSlideSuccess = (data) => ({
    type: FACE_CARD_GENERATOR.FACECARD_SLIDE_SUCCESS,
    payload: data
});
export const faceCardSlideFailure = (error) => ({
    type: FACE_CARD_GENERATOR.FACECARD_SLIDE_FAILURE,
    error
});
export const faceCardSlidePending = () => ({
    type: FACE_CARD_GENERATOR.FACECARD_SLIDE_PENDING
});
export const faceCardSlidePeopleData = (data) => ({
    type: FACE_CARD_GENERATOR.FACECARD_SLIDE_PEOPLE_DATA,
    payload: data
});