import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { KnowledgeNoResults } from "components/KnowledgeNoResults";
import { EntityList } from "components/EntityList/EntityList";
import { Pagination } from "components/Pagination";
import CONFIG from "config";
import LABELS from "labels";
import ANALYTICS from "utils/analytics/analytics";
import { DTMRULE, PAGES, PRIMARY_CATEGORY, SCREEN_NAMES, MATERIAL_STATUS } from "utils/analytics/analytics_constants";
import { getmyCurrentLocation, openKnowledgeConsumptionPage } from "utils/helpers/helpers";
import { MY_NAVIGATOR } from 'redux/constants';
import { actions as mynavigatorActions } from "redux/actions/mynavigator/mynavigator_actions";
import {
  notifyError,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
import { selectors as mynavigatorSelectors } from "redux/reducers/mynavigator/mynavigator_reducer";
import { changeActivitiesPage } from "redux/actions/mynavigator/mynavigator.actions";
import { Helmet } from "react-helmet";

import "./MyActivites.scss";
import { ActivitesFilters } from "components/ActivitesFilters";
import { FullPageLoader } from "components/shared/FullPageLoader";

class MyActivitesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount = () => {
    const { getMyActivitiesList, activitiesSearchCriteria } = this.props;
    getMyActivitiesList(activitiesSearchCriteria);
  }

  statusFilterHandler = (status) => {
    const {
      mynavigatorActions: {
        changeStatusFilter
      },
      searchCriteria
    } = this.props;

    const {
      MY_NAVIGATOR: {
        STATUS_FILTERS: {
          ALL,
          UNPUBLISHED
        }
      },
    } = LABELS;
    const {
      KNOWLEDGE_STATUS: {
        WITHDRAW,
      }
    } = CONFIG;
    let updatedStatus = status;
    if (status === WITHDRAW) {
      updatedStatus = UNPUBLISHED;
    } else if (status === "") {
      updatedStatus = ALL;
    }

    if (searchCriteria.status !== status) {
      const newAdobeData = {
        page: {
          pageInfo: {
            pageName: PAGES.MY_NAVIGATOR
          },
          category: {
            primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
          }
        },
        collection: {
          screenName: SCREEN_NAMES.MY_CONTRIBUTIONS
        },
        selectedDisplayOption: updatedStatus
      };
      ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DISPLAY_OPTION_SELECTION);
      searchCriteria.status = status;
      searchCriteria.pageNumber = 1;
      changeStatusFilter(searchCriteria);
    }
  };

  changeActivitiesPageHandler = (pageNumber) => {
    const {
      changeActivitiesPage,
      activitiesSearchCriteria,
      getMyActivitiesList
    } = this.props;

    if (activitiesSearchCriteria.pageNumber !== pageNumber) {
      // analytics to go her --future

      activitiesSearchCriteria.pageNumber = pageNumber;
      changeActivitiesPage(activitiesSearchCriteria);
      getMyActivitiesList(activitiesSearchCriteria);
    }
  }

  entityClickActivities = (id, type, item) => {
    const { KB, KP, MY_CONTRIBUTIONS_ACTIVITIES } = SCREEN_NAMES;
    const { VIEW_MATERIAL_PAGE } = DTMRULE;
    const { PA_RECOMMENDED, NOT_PA_RECOMMENDED } = MATERIAL_STATUS;
    const {
      MY_NAVIGATOR: {
        ENTITY_TYPES: {
          KB: TYPE_KB,
          KP: TYPE_KP,
          TCS: TYPE_TCS
        }
      }
    } = LABELS;

    const isKB = type === TYPE_KB || type === TYPE_TCS;
    const isKP = type === TYPE_KP;

    const newAdobeData = {
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR, //evar1
          pageURL: getmyCurrentLocation(), //evar3
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR //evar50
        }
      },
      collection: {
        screenName: MY_CONTRIBUTIONS_ACTIVITIES, //evar112
        ID: isKB ? id : "" //evar111
      },
      KCpage: {
        id: isKP ? id : "" //evar123
      },
      resultType: (isKB ? KB : KP), //evar6
      materialPAStatus: item.paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,//evar14
    };
    ANALYTICS.kc.sendEventData(newAdobeData, VIEW_MATERIAL_PAGE);
    openKnowledgeConsumptionPage(id, type);

  }

  daysChangeHandler = (value) => {
    const {
      changeActivitiesPage,
      activitiesSearchCriteria,
      getMyActivitiesList
    } = this.props;
    if (activitiesSearchCriteria.days !== value) {

      activitiesSearchCriteria.days = value;
      activitiesSearchCriteria.pageNumber = 1;
      changeActivitiesPage(activitiesSearchCriteria);
      getMyActivitiesList(activitiesSearchCriteria);
    }
  }


  render() {
    const {
      recordStats,
      searchCriteria,
      myActivityList,
      activitiesRecordStats,
      activitiesSearchCriteria,
      isMyActivitiesLoading
    } = this.props;

    const { status } = searchCriteria;
    const {
      KNOWLEDGE_STATUS: {
        NO_RESULTS
      }
    } = CONFIG;
    const {
      PAGETITLE: {
        MY_NAVIGATOR
      }
    } = LABELS;
    const { days, pageNumber, pageSize } = activitiesSearchCriteria;
    const { total } = activitiesRecordStats;

    return (
      <>
        <Helmet>
          <title>{MY_NAVIGATOR}</title>
        </Helmet>
        <div className="my-activities my-activities__content">
          <div className="my-activities__filters">
            <ActivitesFilters
              selectedStatus={status}
              selectedDays={days}
              totalActivities={total}
              recordStats={recordStats}
              onStatusClick={this.statusFilterHandler}
              onDaysChange={this.daysChangeHandler}
            />
          </div>
          {!!status && status !== NO_RESULTS &&
            <>
              {!!myActivityList?.length &&
                <>
                  <div className="my-activities__tiles">
                    <EntityList list={myActivityList} onEntityClick={this.entityClickActivities} />
                  </div>
                  <div className="my-activities__pager">
                    <Pagination
                      totalItems={total}
                      onChange={this.changeActivitiesPageHandler}
                      activePage={pageNumber}
                      itemsPerPage={pageSize}
                    />
                  </div>
                </>
              }
              {!myActivityList?.length &&
                <div className="my-activities__nocontent">
                  <KnowledgeNoResults />
                </div>
              }
            </>
          }

        </div>
        <FullPageLoader isVisible={isMyActivitiesLoading} inSection />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  recordStats: mynavigatorSelectors.getRecordStatistics(state),
  searchCriteria: mynavigatorSelectors.getsearchCriteria(state),
  activitiesSearchCriteria: mynavigatorSelectors.getActivitiesSearchCriteria(state),
  myActivityList: mynavigatorSelectors.getActivityList(state),
  activitiesRecordStats: mynavigatorSelectors.getActivitiesRecordStatistics(state),
  isMyActivitiesLoading: mynavigatorSelectors.getActivitiesLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getMyActivitiesList: (searchCriteria) => dispatch({ type: MY_NAVIGATOR.GET_MY_ACTIVITY_LIST, payload: { searchCriteria } }),
  changeActivitiesPage: (searchCriteria) => dispatch(changeActivitiesPage(searchCriteria)),
  mynavigatorActions: bindActionCreators({ ...mynavigatorActions }, dispatch),
  notifyError: (title, msg) => dispatch(notifyError(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
});

export const MyActivites = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyActivitesContainer);

export { MyActivitesContainer };
