/***
 * HOC to check whether the user have all the flags as false.
 * If yes, then redirect the user to error page.
 * Else, continue with displaying the passed on children
 * 
 */
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";

import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { ENTITLEMENTS } from "redux/constants";
import { checkAllEntitlementValue } from "utils/helpers/helpers";
import { ErrorPage } from "containers/ErrorPage/ErrorPage";
import ANALYTICS from "utils/analytics/analytics";
import { PAGES, PRIMARY_CATEGORY } from "utils/analytics/analytics_constants";

const {
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    VIEW,
    CREATE_EDIT_DRAFT,
    SHOW_BUNDLE,
    EDIT,
    ALL,
    CASE_PAGES
  },
  API_STATUS: {
    COMPLETED,
    ERROR,
    START,
    PENDING
  },
  ERROR_401,
  VIRTUAL_PAGE_VIEW,
  ERROR_ENTITLEMENT_API
} = CONSTANTS;
const {
  KC_ALL_URLS,
  KC_CONSUMPTION_URLS,
  KC_CONTRIBUTION_URLS,
  KC_CONTRIBUTION_BUNDLE_URL,
  CASE_ALL_URLS,
  CASE_CONSUMPTION_URLS,
  CASE_CONTRIBUTION_URLS,
  FACE_CARD_ALL_URL,
  FACE_CARD_URL,
  UI_URL: {
    ERROR: ERROR_401_URL
  }
} = CONFIG;

export class Entitlement401RedirectContainer extends React.PureComponent {

  componentDidMount() {
    this.scrollToTop();
    const { getEntitlements } = this.props;
    getEntitlements();
  }

  componentDidUpdate(prevProps) {
    const {
      getEntitlements,
      history,
      entitlementApiStatus
    } = this.props;

    if (history.action !== "REPLACE" && history?.location?.pathname !== prevProps?.location?.pathname) {
      this.scrollToTop();
      if ([COMPLETED, ERROR].includes(entitlementApiStatus)) {
        getEntitlements();
      }
      if (!!window && window.usabilla_live) {
        window.usabilla_live(VIRTUAL_PAGE_VIEW);
      }
    }

    if (prevProps.entitlementApiStatus !== entitlementApiStatus) {
      if ([ERROR].includes(entitlementApiStatus)) {
        ANALYTICS.page.initialize({ pageName: PAGES.ENTITLEMENT_ERROR, category: PRIMARY_CATEGORY.ENTITLEMENT_ERROR_PAGE, trackPageLoad: true });
      }
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  shouldRedirectTo = () => {
    const {
      isViewKcEntitlement,
      isCreateEditKcEntitlement,
      showBundleEntitlement,
      allKcEntitlements,

      allCpEntitlements,
      isViewCpEntitlement,
      isEditCpEntitlement,
      entitlementApiStatus,
      match: {
        path
      }
    } = this.props;
    const areAllKcEntFalse = checkAllEntitlementValue(allKcEntitlements, false);
    const areAllCpEntFalse = checkAllEntitlementValue(allCpEntitlements, false);

    let redirectTo401 = false;
    if (KC_ALL_URLS.includes(path)) {
      if (
        areAllKcEntFalse &&
        [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      } else if (
        !isViewKcEntitlement
        && KC_CONSUMPTION_URLS.includes(path)
        && [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      } else if (
        !isCreateEditKcEntitlement
        && KC_CONTRIBUTION_URLS.includes(path)
        && [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      } else if (
        !showBundleEntitlement
        && KC_CONTRIBUTION_BUNDLE_URL.includes(path)
        && [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      }
    } else if (CASE_ALL_URLS.includes(path)) {
      if (
        areAllCpEntFalse &&
        [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      } else if (
        !isViewCpEntitlement
        && CASE_CONSUMPTION_URLS.includes(path)
        && [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      } else if (
        !isEditCpEntitlement
        && CASE_CONTRIBUTION_URLS.includes(path)
        && [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      }
    }
    else if (FACE_CARD_ALL_URL.includes(path)) {
      if (
        !isViewKcEntitlement
        && FACE_CARD_URL.includes(path)
        && [COMPLETED].includes(entitlementApiStatus)
      ) {
        redirectTo401 = true;
      }
    }
    return redirectTo401;
  }

  redirectTo = () => <Redirect to={{ pathname: ERROR_401_URL(ERROR_401) }} />;

  render() {
    const {
      children,
      entitlementApiStatus
    } = this.props;

    const btnClick = () => {
      if (!!window) window.location.reload();
    }
    const btn2Click = () => {
      if (!!window && window.usabilla_live) window.usabilla_live("click");
    }

    return (
      <>
        {
          (![START, PENDING].includes(entitlementApiStatus)) ?
            ([ERROR].includes(entitlementApiStatus))
              ? <ErrorPage
                errorCodeProp={ERROR_ENTITLEMENT_API}
                btnClick={btnClick}
                btn2Click={btn2Click}
              /> :
              (this.shouldRedirectTo()
                ? this.redirectTo() : children
              )
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  allKcEntitlements: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, ALL),
  isViewKcEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, VIEW),
  isCreateEditKcEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, CREATE_EDIT_DRAFT),
  showBundleEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, SHOW_BUNDLE),

  allCpEntitlements: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, ALL),
  isViewCpEntitlement: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, VIEW),
  isEditCpEntitlement: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, EDIT),

  entitlementApiStatus: entitlementSelectors.getApiCallStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
  getEntitlements: () => dispatch({ type: ENTITLEMENTS.GET_ENTITLEMENTS })
});

export const Entitlement401RedirectWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Entitlement401RedirectContainer);

export default withRouter(Entitlement401RedirectWrapper);