import { FCG_NEW } from "redux/constants";

export const newRequestAPIPending = () => ({
    type: FCG_NEW.FC_REQUEST_API_PENDING,
});

export const newRequestAPISuccess = (requestId) => ({
    type: FCG_NEW.FC_REQUEST_API_SUCCESS,
    payload: { requestId },
});

export const newRequestAPIFailure = (error) => ({
    type: FCG_NEW.FC_REQUEST_API_FAILURE,
    payload: { error },
});



export const pollingAPIStart = (requestId) => ({
    type: FCG_NEW.FC_POLLING_API_PENDING,
    payload: { requestId },
});

export const pollingAPISuccess = (status) => ({   //shreya
    type: FCG_NEW.FC_POLLING_API_SUCCESS,
    payload: { status },
});

export const pollingAPIFailure = (error) => ({
    type: FCG_NEW.FC_POLLING_API_FAILURE,
    payload: { error },
});



export const getFaceCardv1SlideData = (data) => ({
    type: FCG_NEW.GET_FC_SLIDE_DATA,
    payload: data
});
export const faceCardv1SlidePeopleData = (data) => ({
    type: FCG_NEW.FC_SLIDE_PEOPLE_DATA,
    payload: data
});