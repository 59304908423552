import React from 'react'
import './FcgHeader.scss'
import logoIcon from "assets/images/bcg_logo.svg";
import wavingHand from "assets/images/FCG_waving_hand.svg";
import LABELS from 'labels';
import FaceCardUserProfile from 'components/FaceCardUserProfile/FaceCardUserProfile';

const {
    FACE_CARD_GENERATOR: {
        PAGE_TITLE,
        HEADER_TEXT,
        HI
    }
} = LABELS

export class FcgHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isScrolled: false
        };
        this.handleScroll = (this.handleScroll.bind(this));

    }
    componentDidMount() {
        window?.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window?.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll() {
        const scrollY = window?.scrollY;
        const headerOuterHeight = document.querySelector('.FCGfaceCardHeader')?.offsetHeight;
        const headerInnerHeight = 85;
        const threshold= 10;
        const isScrolled = window?.scrollY > headerOuterHeight - headerInnerHeight + threshold;

        if (isScrolled !== this.state.isScrolled) {
            this.setState({isScrolled} )
            }
        

    }



    render() {
        const { userDetails } = this.props;
        const { isScrolled } = this.state;
        const firstName = userDetails?.preferredFirstName?.split(' ')[0];
        return (
            <div className={`FCGfaceCardHeader ${isScrolled ? 'FCGfaceCardHeader-scrolled' : ''}`} >
                <div className={`FCGfaceCardHeader__headerBox`}>
                    <div className='FCGfaceCardHeader__content'>
                        <div className='FCGfaceCardHeader__left'>
                            <img src={logoIcon} className='FCGfaceCardHeader__logoIcon' />
                            <p className='FCGfaceCardHeader__logoText'>{PAGE_TITLE}</p>
                        </div>
                        <div className='FCGfaceCardHeader__right'>
                            {<FaceCardUserProfile userDetails={userDetails} />}
                        </div>
                    </div>
                    <div className={`FCGfaceCardHeader__textGroup `}>
                        <div>
                            <p>{HI} {firstName}! <img src={wavingHand} /></p>
                        </div>
                        <p>{HEADER_TEXT}</p>
                    </div>
                </div>
            </div>
        )
    }
}