// React
import React from "react";

// semantic
import { Form } from "semantic-ui-react";

import { setSectorOwner } from "utils/helpers/helpers";
import { Tooltip } from 'components/shared/Tooltip';

import { Taxonomy } from "containers/Taxonomy";
import LABELS from "labels";

import {
  SCREEN_NAMES,
  TRIGGERS
} from "utils/analytics/analytics_constants";

// config
import CONFIG from "../KnowledgeBundles.config";
import GLOBAL_CONFIG from "config";
import { subjectTaxonomyModalActionsAnalytics, onPageLoadAnalytics } from "../kb_analytics";
import GLOBAL_CONSTANTS from "globalConstants";
// scss
import "./KBStep3.scss";

const {
  TBDBS: {
    SEARCH_FPA_IPA_INTERNAL,
    SEARCH_SUBJECT,
  }
} = GLOBAL_CONSTANTS;

const {
  TAXONOMY: {
    IPA_FPA_LABEL,
    IPA_FPA_TIPTEXT,
    IPA_FPA_PLACEHOLDER,
    TOPIC_LABEL,
    TOPIC_PLACEHOLDER,
    IPA_FPA_MODAL_HEADING,
    IPA_FPA_MODAL_SEARCH_PLACEHOLDER,
    TOPIC_MODAL_HEADING,
  },
  METADATA: {
    ADMINISTRATIVE_TITLE,
  },
  KNOWLEDGE_PRODUCT_NAME: {
    TPKB
  },
  KB: {
    SECTION_SEARCH_HEADING,
    SEARCH_TAGS_HEADING,
    TYPESEARCH_PLACEHOLDER
  },
} = LABELS;
const {
  MANDATORY_FIELDS: {
    IPA_FPA,
    IPA_FPA_CUSTODIAN,
    SUBJECT,
    TOPICPAGES
  },
} = CONFIG;
const {
  KNOWLEDGE_BUNDLE_LIMITS: {
    MULTIPLE_TOPIC_PAGES_MAXLIMIT,
    MULTIPLE_SECTOR_TOPICS_MAXLIMIT
  },
  KP_LIMITS: {
    SEARCH_TAGS_MAXLIMIT,
  },
} = GLOBAL_CONFIG;
const {
  KB_BST_MODAL,
  SELECT_TAGS,
  KB_SUBJECT_MODAL
} = SCREEN_NAMES;
const {
  BUTTON_IPA_FPA,
  BUTTON_PA_SUBJECT,
  TOPIC_SUBJECT_SELECTION,
  REMOVE_SELECTION,
  SUBMIT,
  TYPEAHEAD_SUBJECT_SELECTION
} = TRIGGERS;


/**
 * @desc return the add meta data with the multiple component
 * @returns html
 */
class KBStep3 extends React.PureComponent {
  componentDidMount = () => {
    const { kbId } = this.props;
    onPageLoadAnalytics(kbId);
  }

  openTaxModalAnalyticsObj = (modalTriggerName, modalScreenName, topicId, kbid, subId, typeAheadObj) => {
    subjectTaxonomyModalActionsAnalytics(modalTriggerName, modalScreenName, topicId, kbid, subId, typeAheadObj);
  }

  handlePillSelectionStep3 = (pillTopicId) => {
    const { handleChange, apidata } = this.props;

    const { industryPATags, functionalPATags, bcgInternals } = apidata;
    let ipaTags = [...industryPATags];
    let fpaTags = [...functionalPATags];
    let bcgInternalsTags = [...bcgInternals];

    ipaTags = setSectorOwner(ipaTags, pillTopicId);
    fpaTags = setSectorOwner(fpaTags, pillTopicId);
    bcgInternalsTags = setSectorOwner(bcgInternalsTags, pillTopicId);

    handleChange(fpaTags, "functionalPATags");
    handleChange(ipaTags, "industryPATags");
    handleChange(bcgInternalsTags, "bcgInternals");
  }
  render() {
    const {
      isKnowledgeBundle,
      topicId,
      errors,
      expertTagsIds,
      subjectTagsIds,
      handleChange,
      apidata: {
        kbId,
        industryPATags,
        functionalPATags,
        bcgInternals,
        subjectTags,
        topicPages
      },
      queryParamTopicPage
    } = this.props;
    const finalTopicId = topicPages[0]?.topicId ?? topicId;

    return (
      <>
        <div className="KBStep3">
          <Form widths="equal">
            <div className="KBStep3__inner layout__fix--inner">
              <Form.Group>
                <Form.Field>
                  <label>{ADMINISTRATIVE_TITLE}</label>
                  <div className="KBStep3__ipafpa">
                    <div className="KBStep3__ipafpa-line">
                      <Taxonomy
                        label={<Tooltip id="ipa_fpa" labelText={IPA_FPA_LABEL}>{IPA_FPA_TIPTEXT}</Tooltip>}
                        placeholder={IPA_FPA_PLACEHOLDER}
                        modalHeader={IPA_FPA_MODAL_HEADING}
                        maxLimit={MULTIPLE_SECTOR_TOPICS_MAXLIMIT}
                        hideTagsIds={[...subjectTagsIds]}
                        isApplicableForPolyHerarchi
                        taxonomyName={[
                          { label: "Industry", key: "IPA" },
                          { label: "Functional", key: "FPA" },
                          { label: "BCG Internal", key: "BCG_INTERNAL" }
                        ]}
                        kbid={kbId}
                        isShowNoResultInTypeAhead
                        product={TPKB}
                        onChange={(data) => {
                          handleChange(data, "paTags");
                        }}
                        topicId={finalTopicId}
                        preFillData={[
                          industryPATags,
                          functionalPATags,
                          bcgInternals
                        ]}
                        className={
                          errors.includes(IPA_FPA)
                            ? "txbase__error"
                            : ""
                        }
                        openModalCallback={() => this.openTaxModalAnalyticsObj(BUTTON_IPA_FPA, SELECT_TAGS, finalTopicId, kbId)}
                        clickHandlerCallback={(subId) => this.openTaxModalAnalyticsObj(TOPIC_SUBJECT_SELECTION, KB_BST_MODAL, finalTopicId, kbId, subId)}
                        deleteSelectedCallBack={(subId) => this.openTaxModalAnalyticsObj(REMOVE_SELECTION, KB_BST_MODAL, finalTopicId, kbId, subId)}
                        submitHandlerCallback={() => this.openTaxModalAnalyticsObj(SUBMIT, KB_BST_MODAL, finalTopicId, kbId)}
                        responseTypeSearchKeyName={{
                          id: "id",
                          name: "name",
                        }}
                        isShowParentTagsTooltip
                        typeSearchplaceholder={IPA_FPA_MODAL_SEARCH_PLACEHOLDER}
                        characterCount={2}
                        taxonomyTypeSearchName={[
                          { searchKey: SEARCH_FPA_IPA_INTERNAL }
                        ]}
                        showCustodian={true}
                        custodianClass={
                          errors.includes(IPA_FPA_CUSTODIAN)
                            ? "pa-custodian--error"
                            : "pa-custodian"
                        }
                        onCustodianClick={this.handlePillSelectionStep3}
                        showTaxonomyTagCompletePath={true}
                      />
                    </div>
                  </div>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>{SECTION_SEARCH_HEADING}</label>
                  <div className="KBStep3__ipafpa">
                    <Taxonomy
                      kbid={kbId}
                      topicId={finalTopicId}
                      label={SEARCH_TAGS_HEADING}
                      placeholder={SEARCH_TAGS_HEADING}
                      modalHeader={SECTION_SEARCH_HEADING}
                      product={TPKB}
                      hideTagsIds={[...expertTagsIds]}
                      maxLimit={SEARCH_TAGS_MAXLIMIT}
                      taxonomyName={[
                        { label: "Subject", key: "SUBJECT" }
                      ]}
                      onChange={(data) => handleChange(data[0], "subjectTags")}
                      preFillData={[subjectTags]}
                      className={
                        errors.includes(SUBJECT)
                          ? "txbase__error"
                          : ""
                      }
                      openModalCallback={() => this.openTaxModalAnalyticsObj(BUTTON_PA_SUBJECT, SELECT_TAGS, finalTopicId, kbId)}
                      clickHandlerCallback={(subId) => this.openTaxModalAnalyticsObj(TOPIC_SUBJECT_SELECTION, KB_SUBJECT_MODAL, finalTopicId, kbId, subId)}
                      deleteSelectedCallBack={(subId) => this.openTaxModalAnalyticsObj(REMOVE_SELECTION, KB_SUBJECT_MODAL, finalTopicId, kbId, subId)}
                      submitHandlerCallback={() => this.openTaxModalAnalyticsObj(SUBMIT, KB_SUBJECT_MODAL, finalTopicId, kbId)}
                      typeAheadHandlerCallback={(subId, searchTerm, selectedOptionValue, selectedOptionRank) => this.openTaxModalAnalyticsObj(TYPEAHEAD_SUBJECT_SELECTION, KB_SUBJECT_MODAL, finalTopicId, kbId, subId, { searchTerm, selectedOptionValue, selectedOptionRank })}
                      responseTypeSearchKeyName={{
                        id: "id",
                        name: "name",
                      }}
                      isShowParentTagsTooltip
                      typeSearchplaceholder={TYPESEARCH_PLACEHOLDER}
                      characterCount={2}
                      taxonomyTypeSearchName={[
                        { searchKey: SEARCH_SUBJECT }
                      ]}
                    />
                  </div>
                </Form.Field>
              </Form.Group>
              {!isKnowledgeBundle && (<Form.Group>
                <Form.Field>
                  <label>
                    {TOPIC_LABEL}
                  </label>
                  <Taxonomy
                    placeholder={TOPIC_PLACEHOLDER}
                    modalHeader={TOPIC_MODAL_HEADING}
                    maxLimit={MULTIPLE_TOPIC_PAGES_MAXLIMIT}
                    taxonomyName={[
                      { label: "Industry", key: "TOPICPAGE_IPA" },
                      { label: "Functional", key: "TOPICPAGE_FPA" }
                    ]}
                    preFillData={[
                      queryParamTopicPage?.topicPages
                        ? queryParamTopicPage?.topicPages
                        : topicPages,
                    ]}
                    kbid={kbId}
                    topicId={finalTopicId}
                    responseKeyName={{
                      id: "topicId",
                      name: "topicName",
                    }}

                    onChange={(data) => {
                      handleChange(data[0], "topicPages");
                    }}
                    className={
                      errors.includes(TOPICPAGES)
                        ? "txbase__error"
                        : ""
                    }
                    disabled
                  />

                </Form.Field>
              </Form.Group>
              )}
            </div>
          </Form>
        </div>
      </>
    );
  }
};
export { KBStep3 };
